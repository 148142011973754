import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import StorageIcon from "@mui/icons-material/Storage";
import AddIcon from "@mui/icons-material/Add";
import RestoreIcon from "@mui/icons-material/Restore";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import { useAuth } from "../../../../context";

const Backup = () => {
  const auth = useAuth();
  const token = auth?.user?.token;
  const [backupData, setBackupData] = useState([]);
  const [loading, setLoading] = useState({
    list: false,
    backup: false,
    restore: null,
  });

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const fetchBackupList = async () => {
    setLoading((prev) => ({ ...prev, list: true }));
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/database-backup/list/`
      );
      setBackupData(response.data.backups);
    } catch (error) {
      console.error("Error fetching backup data:", error);
    } finally {
      setLoading((prev) => ({ ...prev, list: false }));
    }
  };

  useEffect(() => {
    fetchBackupList();
  }, []);

  const handleCreateBackup = async () => {
    setLoading((prev) => ({ ...prev, backup: true }));
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/database-backup/backup/`
      );

      if (response?.data?.file_name) {
        const downloadResponse = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}/database-backup/download/${response.data.file_name}/`,
          {
            responseType: "blob",
          }
        );

        const file = new Blob([downloadResponse.data], {
          type: "application/octet-stream",
        });

        // Use File Picker API to save the file
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: response.data.file_name,
          types: [
            {
              description: "SQL Backup File",
              accept: { "application/octet-stream": [".sql"] },
            },
          ],
        });

        const writableStream = await fileHandle.createWritable();
        await writableStream.write(file);
        await writableStream.close();

        fetchBackupList();
      }
    } catch (error) {
      console.error("Error creating backup:", error);
    } finally {
      setLoading((prev) => ({ ...prev, backup: false }));
    }
  };

  const handleRestoreBackup = async (fileName) => {
    setLoading((prev) => ({ ...prev, restore: fileName }));
    try {
      const formData = new FormData();
      formData.append("file_name", fileName);

      await axiosInstance.post("/database-backup/restore/", formData);
      alert("Database successfully restored.");
    } catch (error) {
      console.error("Error restoring backup:", error);
    } finally {
      setLoading((prev) => ({ ...prev, restore: null }));
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        minHeight: "350px",
        borderTop: "4px solid #f4a531",
        boxShadow: 3,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid lightGray",
          pb: 1,
          mb: 2,
        }}
      >
        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
          <StorageIcon fontSize="large" color="primary" />
          <Typography variant="h5" fontWeight="bold">
            Backup Management
          </Typography>
        </Box>
        <Box>
          <IconButton
            onClick={fetchBackupList}
            disabled={loading.list || loading.backup}
            sx={{ mr: 2 }}
          >
            {loading.list ? (
              <CircularProgress size={24} />
            ) : (
              <RefreshIcon color="primary" />
            )}
          </IconButton>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateBackup}
            disabled={loading.backup}
            sx={{
              backgroundColor: loading.backup ? "gray" : "#a7e2f9",
              color: "black",
              ":hover": {
                backgroundColor: "#a7e2f9",
              },
            }}
          >
            {loading.backup ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Create Backup"
            )}
          </Button>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid lightGray",
            pb: 1,
            mb: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Backup Files
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            Action
          </Typography>
        </Box>
        <Box>
          {loading.list ? (
            <Typography sx={{ textAlign: "center", color: "gray", mt: 2 }}>
              Loading backups...
            </Typography>
          ) : backupData.length ? (
            backupData.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid lightGray",
                  p: 1,
                  mb: 1,
                  borderRadius: 1,
                  backgroundColor: "#f9f9f9",
                }}
              >
                <Typography variant="body2" sx={{ color: "#1e8cbe" }}>
                  {item.name}
                </Typography>
                <Button
                  size="small"
                  startIcon={
                    loading.restore === item.name ? (
                      <CircularProgress size={16} />
                    ) : (
                      <RestoreIcon />
                    )
                  }
                  disabled={loading.restore !== null}
                  sx={{
                    backgroundColor:
                      loading.restore === item.name ? "gray" : "#a7e2f9",
                    color: "black",
                    "&.Mui-disabled": {
                      backgroundColor: "lightGray",
                      color: "black",
                    },
                    ":hover": {
                      backgroundColor: "#a7e2f9",
                      color: "black",
                    },
                  }}
                  onClick={() => handleRestoreBackup(item.name)}
                >
                  Restore
                </Button>
              </Box>
            ))
          ) : (
            <Typography sx={{ textAlign: "center", color: "gray", mt: 2 }}>
              No backup files available
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default Backup;
