import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useAdd = (
  queryKey,
  token = null,
  customSuccessMessage = null,
  customErrorMessage = null
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [responseData, setResponseData] = useState("");

  // Default messages
  const defaultSuccessMessage = t("use_add.dataAddedSuccess");
  const defaultErrorMessage = t("use_add.addFailed");

  const successMessage = customSuccessMessage || defaultSuccessMessage;
  const errorMessage = customErrorMessage || defaultErrorMessage;

  const addMutation = useMutation({
    mutationFn: async (data) => {
      setLoading(true);

      // Initialize headers object
      const headers = {};

      // Add Authorization header only if the token is valid
      if (token && typeof token === "string" && token.trim().length > 0) {
        headers.Authorization = `Token ${token.trim()}`;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/${queryKey}/`,
        data,
        { headers }
      );
      setResponseData(response.data);
      return response.data;
    },
    onSuccess: () => {
      setLoading(false);
      toast.success(successMessage, {
        position: "top-right",
        autoClose: 3000,
      });
      setResetForm(true);

      // Reset the form after a short delay
      setTimeout(() => {
        setResetForm(false);
      }, 100);

      // Invalidate the query to refresh the data
      queryClient.invalidateQueries(queryKey);
    },
    onError: (error) => {
      setLoading(false);

      // Handle specific backend errors if available
      const backendErrors = error.response?.data;
      if (backendErrors && typeof backendErrors === "object") {
        Object.keys(backendErrors).forEach((key) => {
          const errorMsg = Array.isArray(backendErrors[key])
            ? backendErrors[key].join(", ")
            : backendErrors[key];
          toast.error(`${key}: ${errorMsg}`, {
            position: "top-right",
            autoClose: 3000,
          });
        });
      } else {
        // If no specific backend error, show generic error message
        toast.error(errorMessage || `Error: ${error.message}`, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    },
  });

  const handleAdd = (data) => {
    addMutation.mutate(data);
  };

  return {
    handleAdd,
    resetForm,
    loading,
    responseData,
  };
};

export default useAdd;
