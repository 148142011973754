import React from "react";
import { Grid, Paper, Skeleton } from "@mui/material";

const SkeletonLoading = () => {
  const skeletonStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#e0f7fa",
    padding: "25px 5px",
    borderRadius: "10px",
  };

  return (
    <Paper sx={{ boxShadow: "0px 0px 0px", width: "100%" }}>
      <Grid container padding={"20px"}>
        <Grid container gap={3} mt={2} justifyContent={"center"}>
          <Grid item xs={12} sm={2.5} lg={5} sx={skeletonStyle}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={60} height={30} />
            <Skeleton variant="text" width={80} height={20} />
          </Grid>

          <Grid item xs={12} sm={2.5} lg={5} sx={skeletonStyle}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={60} height={30} />
            <Skeleton variant="text" width={80} height={20} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SkeletonLoading;
