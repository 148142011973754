import {
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Snackbar,
  Alert,
  CircularProgress,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import ListUltrasoundResult from "./ListUltrasoundResult";
import { useTranslation } from "react-i18next";

const buttonStyle = {
  textTransform: "capitalize",
  backgroundColor: "#071952",
  fontWeight: "bold",
};

const TestResult = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data: ultrasoundData } = useFetchObjects(
    ["ultrasounds"],
    "ultrasounds/",
    token
  );

  const { handleAdd, resetForm, loading } = useAdd("ultrasound-results", token);

  const [formData, setFormData] = useState({
    ultrasound: "",
    image: null,
    findings: "",
    result_date: new Date().toISOString().split("T")[0],
  });

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (resetForm) {
      setFormData({
        ultrasound: "",
        image: null,
        findings: "",
        result_date: new Date().toISOString().split("T")[0],
      });
    }
  }, [resetForm]);

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleUltrasoundSelect = (event, selectedUltrasound) => {
    if (selectedUltrasound) {
      setFormData({
        ...formData,
        ultrasound: selectedUltrasound.id,
        patient_name: selectedUltrasound.patient_display,
        ultrasound_type: selectedUltrasound.ultrasound_type_display,
      });
    }
  };

  const handleFileChange = (event) => {
    setFormData({ ...formData, image: event.target.files[0] });
  };

  const validateFields = () => {
    const newErrors = {};

    if (!formData.ultrasound) {
      newErrors.ultrasound = t("ultrasound.selectUltrasound");
    }

    if (!formData.findings.trim()) {
      newErrors.findings = t("ultrasound.enterUltrasound");
    }

    if (formData.image && !formData.image.type.startsWith("image/")) {
      newErrors.image = t("ultrasound.uploadImage");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateFields()) {
      setOpen(true);
      return;
    }

    const formDataToSubmit = new FormData();

    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (key === "image" && !formData[key]) {
          continue;
        }
        formDataToSubmit.append(key, formData[key]);
      }
    }

    handleAdd(formDataToSubmit);
  };

  const handleClose = () => setOpen(false);

  return (
    <Grid container maxWidth={"xl"} mt={2} justifyContent={"center"} mb={10}>
      <Grid item xs={12} lg={8}>
        <Paper sx={{ boxShadow: "0px 0px 0px ", padding: "30px 40px" }}>
          <Typography variant="h5" gutterBottom>
            {t("ultrasound.ultrasoundResult")}
          </Typography>

          <Grid container spacing={4} mt={2}>
            <Grid item xs={12} md={6}>
              <FormLabel> {t("ultrasound.patientUltrasound")}</FormLabel>
              <Autocomplete
                options={ultrasoundData ?? []}
                getOptionLabel={(option) =>
                  `${option.patient_display} - ${option.ultrasound_type_display}`
                }
                onChange={handleUltrasoundSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="ultrasound"
                    value={formData.patient_name || ""}
                    fullWidth
                    size="small"
                    placeholder={t("ultrasound.selectPatientUlt")}
                    error={!!errors.ultrasound}
                    helperText={errors.ultrasound}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormLabel>{t("ultrasound.ultrasoundType")}</FormLabel>
              <TextField
                name="ultrasound_type"
                value={formData.ultrasound_type || ""}
                fullWidth
                size="small"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormLabel>{t("ultrasound.ultrasoundFindings")}</FormLabel>
              <TextField
                name="findings"
                value={formData.findings}
                onChange={handleInputChange}
                fullWidth
                size="small"
                multiline
                rows={4}
                placeholder={t("ultrasound.enterUltrasoundFindings")}
                error={!!errors.findings}
                helperText={errors.findings}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormLabel>{t("ultrasound.uploadUltrasoundFile")}</FormLabel>
              <TextField
                type="file"
                inputProps={{ accept: "image/*" }}
                onChange={handleFileChange}
                fullWidth
                size="small"
                error={!!errors.image}
                helperText={errors.image}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormLabel>{t("ultrasound.resultDate")}</FormLabel>
              <TextField
                type="date"
                name="result_date"
                onChange={handleInputChange}
                fullWidth
                size="small"
                defaultValue={new Date().toISOString().split("T")[0]}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent={"flex-end"} mt={4}>
            <Button
              variant="contained"
              style={{ ...buttonStyle, marginLeft: "20px" }}
              onClick={handleSubmit}
              disabled={loading}
              startIcon={loading && <CircularProgress size={24} />}
            >
              {loading ? t("ultrasound.loading") : t("ultrasound.save")}
            </Button>
          </Grid>

          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {t("ultrasound.correctHighlightedError")}
            </Alert>
          </Snackbar>

          <Divider sx={{ marginY: 4 }} />

          <ListUltrasoundResult />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TestResult;
