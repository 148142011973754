import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Tabs,
  Tab,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  Button,
  Divider,
} from "@mui/material";
import EventDetails from "./EventDetails";
import EventIcon from "./EventIcon";
import { useTranslation } from "react-i18next";

// Mock medical history data
const medicalHistory = [
  {
    id: 1,
    type: "visit",
    date: "2023-05-15",
    title: "General Checkup",
    details: "Annual health assessment",
    doctorNotes: "Patient reports feeling well. Recommended routine tests.",
    assignedTasks: [
      { department: "Lab", task: "Blood work" },
      { department: "Radiology", task: "Chest X-ray" },
    ],
    totalCost: 150,
  },
  {
    id: 2,
    type: "lab",
    date: "2023-05-16",
    title: "Blood Test",
    details: "Comprehensive metabolic panel",
    category: "Hematology",
    results: [
      {
        test: "Hemoglobin",
        result: "14.5 g/dL",
        normalRange: "13.5-17.5 g/dL",
      },
      {
        test: "White Blood Cell Count",
        result: "7,500 cells/mcL",
        normalRange: "4,500-11,000 cells/mcL",
      },
      {
        test: "Platelet Count",
        result: "250,000 platelets/mcL",
        normalRange: "150,000-450,000 platelets/mcL",
      },
    ],
    comments: "All results within normal range.",
    cost: 75,
  },
  // More mock data...
];

const TabPanel = ({ children, value, index }) => {
  return value === index && <Box sx={{ p: 2 }}>{children}</Box>;
};

const MedicalHistory = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const totalCost = medicalHistory.reduce((sum, event) => sum + event.cost, 0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: "auto", p: 2 }}>
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <Tab label={t("profile.timeLine")} />
        <Tab label={t("profile.labResult")} />
        <Tab label={t("profile.imaging")} />
        <Tab label={t("profile.costs")} />
      </Tabs>

      {/* Timeline Tab */}
      <TabPanel value={selectedTab} index={0}>
        <Card>
          <CardHeader
            title={
              <Typography variant="h6">
                {t("profile.medicalHistory")}
              </Typography>
            }
          />
          <CardContent>
            <Input placeholder={t("profile.searchEvent")} fullWidth />
            <Box sx={{ maxHeight: 400, overflowY: "auto", mt: 2 }}>
              {medicalHistory.map((event) => (
                <Box key={event.id} sx={{ my: 2 }}>
                  <Button
                    onClick={() => setSelectedEvent(event)}
                    sx={{ textAlign: "left", width: "100%" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <EventIcon type={event.type} />
                      <Box sx={{ ml: 2 }}>
                        <Typography variant="body1">
                          {event.date} - {event.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {event.details}
                        </Typography>
                      </Box>
                    </Box>
                  </Button>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </TabPanel>

      {/* Lab Results Tab */}
      <TabPanel value={selectedTab} index={1}>
        <Card>
          <CardHeader
            title={<Typography variant="h6">{t("profile.labResults")}</Typography>}
          />
          <CardContent>
            <Box sx={{ maxHeight: 400, overflowY: "auto" }}>
              {medicalHistory
                .filter((event) => event.type === "lab")
                .map((event) => (
                  <EventDetails key={event.id} event={event} />
                ))}
            </Box>
          </CardContent>
        </Card>
      </TabPanel>

      {/* Imaging Results Tab */}
      <TabPanel value={selectedTab} index={2}>
        <Card>
          <CardHeader
            title={<Typography variant="h6">{t("profile.imagingResults")}</Typography>}
          />
          <CardContent>
            <Box sx={{ maxHeight: 400, overflowY: "auto" }}>
              {medicalHistory
                .filter((event) =>
                  ["xray", "ultrasound", "echo"].includes(event.type)
                )
                .map((event) => (
                  <EventDetails key={event.id} event={event} />
                ))}
            </Box>
          </CardContent>
        </Card>
      </TabPanel>

      {/* Costs Tab */}
      <TabPanel value={selectedTab} index={3}>
        <Card>
          <CardHeader
            title={<Typography variant="h6">{t("profile.costSummary")}</Typography>}
          />
          <CardContent>
            <Box sx={{ maxHeight: 400, overflowY: "auto" }}>
              {medicalHistory.map((event) => (
                <Box key={event.id} sx={{ my: 2 }}>
                  <Typography variant="body2">
                    {event.date} - {event.title}: ${event.cost}
                  </Typography>
                </Box>
              ))}
              <Divider />
              <Typography variant="h6" sx={{ mt: 2 }}>
                {t("profile.totalCost")}: ${totalCost}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </TabPanel>

      {/* Dialog for Event Details */}
      {selectedEvent && (
        <Dialog
          open={Boolean(selectedEvent)}
          onClose={() => setSelectedEvent(null)}
        >
          <DialogTitle>{selectedEvent.title}</DialogTitle>
          <DialogContent>
            <EventDetails event={selectedEvent} />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default MedicalHistory;
