import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Print = ({ appointment }) => {
  const { t } = useTranslation();
  const direction = localStorage.getItem("direction");

  useEffect(() => {
    const printStyles = `
      @media print {
        @page {
          size: 80mm auto;
          margin: 0;
        }
        html, body {
          margin: 0;
          padding: 0;
          height: 100%;
          overflow: hidden;
        }
        body * {
          visibility: hidden;
        }
        #printable, #printable * {
          visibility: visible;
        }
        #printable {
          position: absolute;
          left: 0;
          top: 0;
          width: 80mm;
          margin: 0;
          padding: 0;
        }
      }
    `;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = printStyles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <div
      id="printable"
      style={{
        width: "80mm",
        fontFamily: "Arial, sans-serif",
        padding: "15px",
        boxSizing: "border-box",
        direction: direction,
      }}
    >
      {/* Header Section */}
      <div style={{ textAlign: "center", marginBottom: "15px" }}>
        <h2 style={{ margin: "0", fontSize: "20px", fontWeight: "bold" }}>
          {t("room.hafizBalkh")}
        </h2>
        <p style={{ margin: "5px 0", fontSize: "12px", fontWeight: "500" }}>
          {t("room.tel")}: 0796176000
        </p>
        <p style={{ fontSize: "11px", margin: "5px 0", color: "#555" }}>
          {t("room.address")}
        </p>
        <hr style={{ border: "1px dashed #333", margin: "15px 0" }} />
      </div>

      {/* Patient & Doctor Details */}
      <div style={{ fontSize: "14px", lineHeight: "1.6", color: "#333" }}>
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong>{t("reception.patientName")}:</strong>
          <span>
            {appointment.patient_name_display}{" "}
            {appointment.patient_lastname_display}
          </span>
        </div>
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong>{t("reception.doctor")}:</strong>
          <span>{appointment.doctor_name_display}</span>
        </div>
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong>{t("reception.inLine")}:</strong>
          <span>{appointment.appointments_before}</span>
        </div>
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong>{t("reception.fees")}:</strong>
          <span>{appointment.fees}؋</span>
        </div>
      </div>

      {/* Footer Section */}
      <hr style={{ border: "1px dashed #333", margin: "15px 0" }} />
      <div style={{ textAlign: "center", color: "#555" }}>
        <p style={{ fontSize: "12px", margin: "8px 0" }}>
          {t("room.thankYou")}
        </p>
        <p style={{ fontSize: "12px", margin: "8px 0" }}>
          {t("room.getWellSoon")}
        </p>
      </div>
    </div>
  );
};

export default Print;
