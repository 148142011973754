import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import BedIcon from "@mui/icons-material/Bed";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WarningIcon from "@mui/icons-material/Warning";
import SkeletonLoading from "./loading/SkeletonLoading";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { red } from "@mui/material/colors"; // Import MUI color

const gridItemCenterStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#007174",
  padding: "25px 5px",
  borderRadius: "10px",
  color: "white",
};

// Additional styles for the warning section
const warningStyle = {
  ...gridItemCenterStyle,
  cursor: "pointer",
  backgroundColor: red[500], // Warning color background
  border: "2px solid #d32f2f", // Red border
  boxShadow: "0px 4px 10px rgba(211, 47, 47, 0.5)", // Shadow for emphasis
  "&:hover": {
    backgroundColor: red[700], // Darker shade on hover
  },
};

const PharmacyActivityOverView = () => {
  const { t } = useTranslation();
  const [option, setOption] = useState("daily");
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacy = auth?.user?.user?.pharmacy_type;

  const navigate = useNavigate();

  const { data, isLoading, isError, error } = useFetchObjects(
    ["pharmacy-reports", option],
    `pharmacy-reports/?type=${option}`,
    token
  );

  const {
    data: expiringMedicinesData,
    isError: isErrorExpiring,
    error: errorExpiring,
  } = useFetchObjects(
    ["expiring-medicines"],
    `medicine-left-sex-month/?pharmacy=${pharmacy}`,
    token
  );

  const handleChangeOption = (event) => {
    setOption(event.target.value || "daily");
  };

  return (
    <Paper sx={{ boxShadow: "0px 0px 0px", width: "100%" }}>
      <Grid container padding={"20px"}>
        <Grid
          item
          xs={11}
          md={11}
          display={"flex"}
          margin={"0px auto"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Typography>{t("overview.pharmacyOverview")}</Typography>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="report-type-select-label">
                {t("overview.option")}
              </InputLabel>
              <Select
                labelId="report-type-select-label"
                id="report-type-select"
                value={option}
                onChange={handleChangeOption}
                label={t("overview.option")}
              >
                <MenuItem value="daily">{t("overview.daily")}</MenuItem>
                <MenuItem value="weekly">{t("overview.weekly")}</MenuItem>
                <MenuItem value="monthly">{t("overview.monthly")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {isLoading ? (
          <SkeletonLoading /> // Show skeleton loading while data is being fetched
        ) : (
          <Grid container gap={3} mt={2} justifyContent={"center"}>
            {/* Total Prescriptions */}
            <Grid item xs={12} sm={3} lg={5} sx={gridItemCenterStyle}>
              <LocalPharmacyIcon fontSize="large" />
              {isError ? (
                <Typography>
                  {t("overview.error")}: {error.message}
                </Typography>
              ) : (
                <>
                  <Typography
                    textAlign={"center"}
                    sx={{ fontWeight: "bold", margin: "5px 0px" }}
                  >
                    {data?.total_prescriptions ?? 0}
                  </Typography>
                  <Typography textAlign={"center"}>
                    {t("overview.totalPrescriptions")}
                  </Typography>
                </>
              )}
            </Grid>

            {/* Available Beds */}
            <Grid item xs={12} sm={3} lg={5} sx={gridItemCenterStyle}>
              <BedIcon fontSize="large" />
              {isError ? (
                <Typography>
                  {t("overview.error")}: {error.message}
                </Typography>
              ) : (
                <>
                  <Typography
                    textAlign={"center"}
                    sx={{ fontWeight: "bold", margin: "5px 0px" }}
                  >
                    {data?.available_beds ?? 0}
                  </Typography>
                  <Typography textAlign={"center"}>
                    {t("overview.availableBeds")}
                  </Typography>
                </>
              )}
            </Grid>

            {/* Total Prescription Amount */}
            <Grid item xs={12} sm={3} lg={5} sx={gridItemCenterStyle}>
              <MonetizationOnIcon fontSize="large" />
              {isError ? (
                <Typography>
                  {t("overview.error")}: {error.message}
                </Typography>
              ) : (
                <>
                  <Typography
                    textAlign={"center"}
                    sx={{ fontWeight: "bold", margin: "5px 0px" }}
                  >
                    {data?.total_prescription_amount ?? 0}
                  </Typography>
                  <Typography textAlign={"center"}>
                    {t("overview.prescriptionAmount")}
                  </Typography>
                </>
              )}
            </Grid>

            {/* Total Medicine Order Amount */}
            <Grid item xs={12} sm={3} lg={5} sx={gridItemCenterStyle}>
              <ShoppingCartIcon fontSize="large" />
              {isError ? (
                <Typography>
                  {t("overview.error")}: {error.message}
                </Typography>
              ) : (
                <>
                  <Typography
                    textAlign={"center"}
                    sx={{ fontWeight: "bold", margin: "5px 0px" }}
                  >
                    {data?.total_medicine_order_amount ?? 0}
                  </Typography>
                  <Typography textAlign={"center"}>
                    {t("overview.medicineOrderAmount")}
                  </Typography>
                </>
              )}
            </Grid>

            {/* Medicines Expiring in 6 Months */}
            <Grid
              item
              xs={12}
              sm={3}
              lg={5}
              sx={warningStyle}
              onClick={() => navigate("/pharmacy/medicine-expiry-six-months")}
            >
              <WarningIcon fontSize="large" />
              {isErrorExpiring ? (
                <Typography>
                  {t("overview.error")}: {errorExpiring.message}
                </Typography>
              ) : (
                <>
                  <Typography
                    textAlign={"center"}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      margin: "5px 0px",
                    }}
                  >
                    {expiringMedicinesData?.length ?? 0}
                  </Typography>
                  <Typography textAlign={"center"} sx={{ fontWeight: "bold" }}>
                    {t("overview.expiringMedicines")}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default PharmacyActivityOverView;
