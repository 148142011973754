import React, { useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Select,
} from "@mui/material";
import SummarizeIcon from "@mui/icons-material/Summarize";
import MedicationIcon from "@mui/icons-material/Medication";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import HotelIcon from "@mui/icons-material/Hotel";
import HealingIcon from "@mui/icons-material/Healing";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useNavigate } from "react-router-dom";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";
import { useNewRecords } from "../../../../context/NewRecordsProvider";
import SkeletonLoading from "./loading/SkeletonLoading";

const cardStyle = (highlight) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderRadius: "12px",
  color: highlight ? "#D32F2F" : "white",
  boxShadow: highlight ? "none" : "0px 4px 15px rgba(0, 0, 0, 0.1)",
  backgroundColor: highlight ? "#FFEBEE" : "#007174",
  height: "100%",
  position: "relative",
  transition: "transform 0.3s ease",
  transform: highlight ? "scale(1.05)" : "scale(1)",
  cursor: highlight ? "pointer" : "default",
});

const warningStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  backgroundColor: "#FFCDD2",
  color: "#D32F2F",
  padding: "5px 10px",
  borderRadius: "8px",
  fontWeight: "bold",
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)",
  fontSize: "12px",
};

const ActivityOverView = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const { newRecords } = useNewRecords();
  const navigate = useNavigate();

  const [option, setOption] = useState("daily");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [fetchTrigger, setFetchTrigger] = useState(0);

  const fetchParams = `?type=${option}${
    startDateTime ? `&start_datetime=${startDateTime}:00` : ""
  }${endDateTime ? `&end_datetime=${endDateTime}:00` : ""}`;

  const { data: appointments } = useFetchObjects(
    ["appointments", option, fetchTrigger],
    `appointments/${fetchParams}`,
    token
  );

  const { data: collect_medicine_order } = useFetchObjects(
    ["medicine-orders", option, fetchTrigger],
    `medicine-orders/${fetchParams}`,
    token
  );

  const { data: collect_prescription_fee } = useFetchObjects(
    ["prescriptions", option, fetchTrigger],
    `prescriptions/${fetchParams}`,
    token
  );

  const { data: collect_xray_fee } = useFetchObjects(
    ["patient-xray", option, fetchTrigger],
    `patient-xray/${fetchParams}`,
    token
  );

  const { data: collect_echo_fee } = useFetchObjects(
    ["patient-echo", option, fetchTrigger],
    `patient-echo/${fetchParams}`,
    token
  );

  const { data: collect_ultrasound_fee } = useFetchObjects(
    ["ultrasounds", option, fetchTrigger],
    `ultrasounds/${fetchParams}`,
    token
  );

  const { data: collect_laboratory_fee } = useFetchObjects(
    ["laboratory-test-orders", option, fetchTrigger],
    `laboratory-test-orders/${fetchParams}`,
    token
  );

  const { data: room_revenue } = useFetchObjects(
    ["room-assignment", option, fetchTrigger],
    `room-assignment/${fetchParams}`,
    token
  );

  const { data: surgery_revenue } = useFetchObjects(
    ["surgeries", option, fetchTrigger],
    `surgeries/${fetchParams}`,
    token
  );

  const { data: return_medicines } = useFetchObjects(
    ["medicine-returns", option, fetchTrigger],
    `medicine-returns/${fetchParams}`,
    token
  );

  const handleChangeOption = (event) => {
    setOption(event.target.value);
    setStartDateTime("");
    setEndDateTime("");
  };

  const handleApply = () => {
    if (startDateTime && endDateTime) {
      setOption("");
    }
    setFetchTrigger((prev) => prev + 1);
  };

  const appointmentCount = appointments?.results?.length || 0;
  const appointmentTotalAmount = appointments?.results?.reduce(
    (acc, item) => acc + (item.fees ? parseFloat(item.fees) : 0),
    0
  );

  const ultrasoundTotal = collect_ultrasound_fee?.reduce(
    (acc, item) => acc + (item.is_paid ? parseFloat(item.total_amount) : 0),
    0
  );

  const xrayTotal = collect_xray_fee?.reduce(
    (acc, item) => acc + (item.is_paid ? parseFloat(item.total_amount) : 0),
    0
  );

  const echoTotal = collect_echo_fee?.reduce(
    (acc, item) => acc + (item.is_paid ? parseFloat(item.total_amount) : 0),
    0
  );

  const laboratoryTotal = collect_laboratory_fee?.reduce(
    (acc, item) => acc + (item.is_paid ? parseFloat(item.total_amount) : 0),
    0
  );

  const medicineOrderTotal = collect_medicine_order?.reduce(
    (acc, item) =>
      acc + (item.payment_status === 2 ? parseFloat(item.total_price) : 0),
    0
  );

  const prescriptionTotal = collect_prescription_fee?.reduce(
    (acc, item) =>
      acc + (item.payment_status === 2 ? parseFloat(item.total_price) : 0),
    0
  );

  const roomTotalRevenue = room_revenue?.reduce(
    (acc, item) => acc + (item.is_paid ? parseFloat(item.total_amount) : 0),
    0
  );

  const surgeryTotalRevenue = surgery_revenue?.reduce(
    (acc, item) => acc + parseFloat(item.net_amount || 0),
    0
  );

  const totalReturnedMedicineAmount = return_medicines?.reduce(
    (acc, item) =>
      acc +
      (item.quantity && item.stock_medicine_info?.sell_price
        ? item.quantity * item.stock_medicine_info.sell_price
        : 0),
    0
  );

  const totalRevenue =
    (appointmentTotalAmount || 0) +
    (ultrasoundTotal || 0) +
    (xrayTotal || 0) +
    (echoTotal || 0) +
    (laboratoryTotal || 0) +
    (roomTotalRevenue || 0) +
    (surgeryTotalRevenue || 0) +
    (medicineOrderTotal || 0) +
    (prescriptionTotal || 0) -
    (totalReturnedMedicineAmount || 0);

  const cards = [
    {
      icon: <SummarizeIcon fontSize="large" />,
      label: "appointments",
      value: appointmentCount,
      highlight: false,
    },
    {
      icon: <MonetizationOnIcon fontSize="large" />,
      label: "appointmentsAmount",
      value: appointmentTotalAmount || 0,
      highlight: false,
    },
    {
      icon: <MedicationIcon fontSize="large" />,
      label: "prescriptionSold",
      value: prescriptionTotal || 0,
      highlight: newRecords.prescriptionFees,
      route: "/reception/collect-prescription-fees",
    },
    {
      icon: <MedicationIcon fontSize="large" />,
      label: "medicineSold",
      value: medicineOrderTotal || 0,
      highlight: newRecords.medicineOrderFees,
      route: "/reception/collect-medicine-order-fees",
    },
    {
      icon: <MedicalServicesIcon fontSize="large" />,
      label: "ultrasoundPaid",
      value: ultrasoundTotal || 0,
      highlight: newRecords.ultrasoundFees,
      route: "/reception/collect-ultrasound-fees",
    },
    {
      icon: <MedicalServicesIcon fontSize="large" />,
      label: "xrayPaid",
      value: xrayTotal || 0,
      highlight: newRecords.xrayFees,
      route: "/reception/collect-xray-fees",
    },
    {
      icon: <MedicalServicesIcon fontSize="large" />,
      label: "echoPaid",
      value: echoTotal || 0,
      highlight: newRecords.echoFees,
      route: "/reception/collect-echo-fees",
    },
    {
      icon: <MedicalServicesIcon fontSize="large" />,
      label: "laboratoryPaid",
      value: laboratoryTotal || 0,
      highlight: newRecords.laboratoryFees,
      route: "/reception/collect-laboratory-fees",
    },
    {
      icon: <HotelIcon fontSize="large" />,
      label: "roomRevenue",
      value: roomTotalRevenue || 0,
      highlight: false,
    },
    {
      icon: <HealingIcon fontSize="large" />,
      label: "surgeryRevenue",
      value: surgeryTotalRevenue || 0,
      highlight: false,
    },
    {
      icon: <ArrowDownwardIcon fontSize="large" />,
      label: "returnMedicine",
      value: totalReturnedMedicineAmount || 0,
      highlight: false,
    },
    {
      icon: <MonetizationOnIcon fontSize="large" />,
      label: "totalRevenue",
      value: totalRevenue || 0,
      highlight: false,
    },
  ];

  const handleCardClick = (route) => {
    if (route) {
      navigate(route);
    }
  };

  return (
    <Paper sx={{ width: "100%", borderRadius: "15px", p: 2 }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
        flexWrap="wrap"
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
          {t("overview.receptionOverview")}
        </Typography>
        <FormControl sx={{ minWidth: 150, mr: 2 }} size="small">
          <InputLabel id="report-type-select-label">
            {t("overview.option")}
          </InputLabel>
          <Select
            labelId="report-type-select-label"
            id="report-type-select"
            value={option}
            onChange={handleChangeOption}
            label={t("overview.option")}
          >
            <MenuItem value="daily">{t("overview.daily")}</MenuItem>
            <MenuItem value="weekly">{t("overview.weekly")}</MenuItem>
            <MenuItem value="monthly">{t("overview.monthly")}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <TextField
          type="datetime-local"
          label={t("overview.startDateTime")}
          value={startDateTime}
          onChange={(e) => setStartDateTime(e.target.value)}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          type="datetime-local"
          label={t("overview.endDateTime")}
          value={endDateTime}
          onChange={(e) => setEndDateTime(e.target.value)}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleApply}
          disabled={!startDateTime || !endDateTime}
        >
          {t("overview.apply")}
        </Button>
      </Box>

      <Grid container spacing={3}>
        {cards.map((item, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <Box
              sx={cardStyle(item.highlight)}
              onClick={() => handleCardClick(item.route)}
            >
              {item.highlight && (
                <Box sx={warningStyle}>
                  <WarningAmberIcon fontSize="small" />
                  Reminder
                </Box>
              )}
              {item.icon}
              <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
                {item.value ?? 0}
              </Typography>
              <Typography>{t(`overview.${item.label}`)}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default ActivityOverView;
