import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const MedicineOrderPrint = ({ data }) => {
  const { t } = useTranslation();
  const direction = localStorage.getItem("direction");

  useEffect(() => {
    const printStyles = `
      @media print {
        @page {
          size: 80mm auto;
          margin: 0;
        }
        html, body {
          margin: 0;
          padding: 0;
          height: 100%;
          overflow: hidden;
        }
        body * {
          visibility: hidden;
        }
        #printable, #printable * {
          visibility: visible;
        }
        #printable {
          position: absolute;
          left: 0;
          top: 0;
          width: 80mm;
          margin: 0;
          padding: 0;
        }
      }
    `;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = printStyles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <div
      id="printable"
      style={{
        width: "80mm",
        fontFamily: "Arial, sans-serif",
        padding: "15px",
        boxSizing: "border-box",
        direction: direction,
      }}
    >
      {/* Header Section */}
      <div style={{ textAlign: "center", marginBottom: "15px" }}>
        <h2 style={{ margin: 0, fontSize: "20px", fontWeight: "bold" }}>
          {t("room.hafizBalkh")}
        </h2>
        <p style={{ margin: "5px 0", fontSize: "12px", fontWeight: "500" }}>
          {t("room.tel")}: 0796176000
        </p>
        <p style={{ fontSize: "11px", margin: "5px 0", color: "#555" }}>
          123 {t("room.address")}
        </p>
        <hr style={{ border: "1px dashed #333", margin: "15px 0" }} />
      </div>

      {/* Order Details */}
      <div style={{ fontSize: "14px", lineHeight: "1.6", color: "#333" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <strong>{t("pharmacy.order")}:</strong>
          <span>
            #{data.id} - {data.date}
          </span>
        </div>
      </div>

      {/* Medicine Items Table */}
      <table
        style={{
          width: "100%",
          fontSize: "12px",
          marginTop: "10px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th style={tableHeaderStyle}>{t("pharmacy.medicine")}</th>
            <th style={tableHeaderStyle}>{t("pharmacy.pricePerQuantity")}</th>
            <th style={tableHeaderStyle}>{t("pharmacy.quantity")}</th>
            <th style={tableHeaderStyle}>{t("pharmacy.subtotal")}</th>
          </tr>
        </thead>
        <tbody>
          {data?.items?.map((item, idx) => (
            <tr key={idx} style={{ borderBottom: "1px solid #ccc" }}>
              <td style={tableCellStyle}>{item.stock_medicine_display}</td>
              <td style={{ ...tableCellStyle, textAlign: "center" }}>
                {item.sell_price_display}
              </td>
              <td style={{ ...tableCellStyle, textAlign: "center" }}>
                {item.quantity}
              </td>
              <td style={{ ...tableCellStyle, textAlign: "right" }}>
                {item.quantity * item.sell_price_display} ؋
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Total Amount */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid gray",
          paddingTop: "10px",
          fontSize: "14px",
          fontWeight: "bold",
          color: "#333",
          marginTop: "10px",
        }}
      >
        <span>{t("pharmacy.totalAmount")}:</span>
        <span>{data.total_price} ؋</span>
      </div>

      {/* Footer Section */}
      <hr style={{ border: "1px dashed #333", margin: "15px 0" }} />
      <div style={{ textAlign: "center", color: "#555" }}>
        <p style={{ fontSize: "12px", margin: "8px 0" }}>
          {t("room.thankYou")}
        </p>
        <p style={{ fontSize: "12px", margin: "8px 0" }}>
          {t("room.getWellSoon")}
        </p>
      </div>
    </div>
  );
};

const tableHeaderStyle = {
  borderBottom: "1px dashed #000",
  paddingBottom: "5px",
  fontWeight: "bold",
  textAlign: "center",
  color: "#333",
};

const tableCellStyle = {
  padding: "8px 0",
  textAlign: "left",
  color: "#333",
};

export default MedicineOrderPrint;
