import {
  Avatar,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../context";
import Loading from "./skeletonLoading/Loading";
import useFetchObjects from "../../../../api/useFetchObjects";
import useUpdate from "../../../../api/useUpdate";
import NoRecordMessage from "../../../../Components/GeneralComponents/noRecordMessage/NoRecordMessage";
import EditPatientDialog from "./EditPatientDialog";
import { useTranslation } from "react-i18next";

const searchStyle = {
  padding: "14px",
  borderRadius: "17px",
  backgroundColor: "#cdf6f7",
  border: "0px",
  ":focus": { outline: "0px" },
};
const tableStyle = {
  textAlign: "center",
};

const PatientList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["patients"],
    `patients/?search=${search}`,
    token
  );

  const { handleUpdate } = useUpdate("patients", token);

  useEffect(() => {
    refetch();
  }, [search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleEditClick = (patient) => {
    setSelectedPatient(patient);
    setEditOpen(true);
  };

  const handleSaveEdit = (updatedPatient) => {
    handleUpdate(updatedPatient.id, updatedPatient);
    setEditOpen(false);
    refetch();
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Typography color="error">{t("reception.errorLoading")}</Typography>;
  } else {
    return (
      <Grid container justifyContent={"center"} mb={4}>
        <Grid
          item
          xs={12}
          md={10}
          mt={2}
          sx={{
            backgroundColor: "white",
            overflowX: "auto",
            maxHeight: "620px",
          }}
        >
          <Grid
            padding={"0px 25px"}
            position="sticky"
            top={0}
            backgroundColor="white"
            zIndex={1}
            pt={2}
          >
            <Typography variant="h5">{t("reception.patientList")}</Typography>
            <Typography color={"text.secondary"} mt={1}>
              {data.results.length} {t("reception.patients")}
            </Typography>
            <Grid
              item
              xs={12}
              md={4}
              xl={4}
              component={"input"}
              sx={searchStyle}
              placeholder={t("reception.search")}
              value={search}
              onChange={handleSearchChange}
              autoFocus
              mt={2}
            />
          </Grid>
          <TableContainer
            sx={{ padding: "25px", boxShadow: "0px 0px 0px" }}
            component={Paper}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                <TableRow style={{ fontWeight: "bold" }}>
                  <TableCell style={tableStyle}>{t("reception.id")}</TableCell>
                  <TableCell style={tableStyle}>
                    {t("reception.firstname")}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {t("reception.lastname")}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {t("reception.gender")}
                  </TableCell>
                  <TableCell style={tableStyle}>{t("reception.age")}</TableCell>
                  <TableCell style={tableStyle}>
                    {t("reception.bloodType")}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {t("reception.action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.results.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }}>
                      <NoRecordMessage message={t("reception.noRecords")} />
                    </TableCell>
                  </TableRow>
                ) : (
                  data.results.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell style={tableStyle}>{item.id}</TableCell>
                      <TableCell style={tableStyle}>{item.firstname}</TableCell>
                      <TableCell style={tableStyle}>{item.lastname}</TableCell>
                      <TableCell style={tableStyle}>
                        {item.gender_display}
                      </TableCell>
                      <TableCell style={tableStyle}>{item.age}</TableCell>
                      <TableCell style={tableStyle}>
                        {item.blood_type_display}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        <IconButton
                          color="primary"
                          onClick={() => handleEditClick(item)}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={data.count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
        {selectedPatient && (
          <EditPatientDialog
            open={editOpen}
            onClose={() => setEditOpen(false)}
            patient={selectedPatient}
            onSave={handleSaveEdit}
            token={token}
          />
        )}
      </Grid>
    );
  }

  return null;
};

export default PatientList;
