import React from "react";
import { Grid, Paper, Skeleton, Box } from "@mui/material";

const SkeletonLoading = () => {
  return (
    <Grid container spacing={3} padding="20px" mt={3}>
      {[1, 2, 3, 4, 5, 6].map((index) => (
        <Grid item xs={12} md={6} key={index}>
          <Paper
            elevation={3}
            sx={{
              mb: 3,
              padding: "24px",
              borderRadius: "12px",
              width: "100%",
              boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Skeleton variant="text" width={200} height={30} />
              <Skeleton variant="rectangular" width={80} height={25} />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Skeleton variant="text" width={150} height={20} />
              <Skeleton variant="text" width={150} height={20} />
              <Skeleton variant="text" width={100} height={20} />
            </Box>

            <Box mt={2}>
              <Skeleton variant="rectangular" width="100%" height={30} />
            </Box>

            <Box mt={2}>
              <Skeleton variant="rectangular" width="100%" height={150} />
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default SkeletonLoading;
