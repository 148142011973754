import React from "react";
import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const SkeletonLoading = () => {
  return (
    <Grid item xs={12}>
      <Paper sx={{ boxShadow: "0px 0px 0px" }}>
        <TableContainer>
          <Table sx={{ minWidth: "100%" }} aria-label="skeleton table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" width={100} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={150} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={150} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={150} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(5)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" width={80} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" width={120} height={20} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" width={120} height={20} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" width={120} height={20} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" width={100} height={20} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="circular" width={40} height={40} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default SkeletonLoading;
