import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Box,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Alert,
  Chip,
  TextField,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import ReturnMedicineDetailsEdit from "./ReturnMedicineDetailsEdit";
import useDelete from "../../../../api/useDelete";

const ReturnMedicineList = () => {
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacy = auth?.user?.user?.pharmacy_type;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, isLoading, isError, error } = useFetchObjects(
    ["medicine-returns"],
    `medicine-returns/?stock_medicine__pharmacy=${pharmacy}`,
    token
  );

  const { handleDelete, ConfirmDialog } = useDelete("medicine-returns", token);

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  const calculateReturnAmount = (quantity, sellPrice) => {
    return quantity * sellPrice;
  };

  const handleFilterChange = () => {
    if (startDate && endDate) {
      return data.filter(
        (item) => item.date >= startDate && item.date <= endDate
      );
    }
    return data;
  };

  const filteredData = handleFilterChange();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <Alert severity="error">{error.message}</Alert>
      </Box>
    );
  }

  return (
    <Box>
      {/* Date Interval Filter Section */}
      <Box
        mb={3}
        display="flex"
        alignItems="center"
        gap={2}
        flexWrap="wrap"
        sx={{
          backgroundColor: "#f0f4f8",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ flexGrow: 1, fontSize: isSmallScreen ? "1rem" : "1.2rem" }}
        >
          Filter Returns by Date
        </Typography>
        <TextField
          label="Start Date"
          type="date"
          variant="outlined"
          size="small"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "4px",
          }}
        />
        <TextField
          label="End Date"
          type="date"
          variant="outlined"
          size="small"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "4px",
          }}
        />
        <Button
          onClick={() => {
            setStartDate("");
            setEndDate("");
          }}
          variant="contained"
          color="primary"
          size="small"
          sx={{
            height: "40px",
            fontWeight: "bold",
            backgroundColor: "#1976d2",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
        >
          Clear Filter
        </Button>
      </Box>

      {/* Medicine Return List */}
      <Grid container spacing={3} padding={2} mt={2}>
        {filteredData.length > 0 ? (
          filteredData.map((returnMedicine, index) => {
            const returnAmount = calculateReturnAmount(
              returnMedicine.quantity,
              returnMedicine.stock_medicine_info.sell_price
            );

            return (
              <Grid item xs={12} md={6} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: "16px",
                    borderRadius: "12px",
                    backgroundColor: "#fdfdfd",
                    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "translateY(-3px)",
                      boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.15)",
                    },
                  }}
                >
                  {/* Header Section */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      color="primary"
                      sx={{ fontSize: isSmallScreen ? "0.95rem" : "1.1rem" }}
                    >
                      Return #{returnMedicine.id}
                    </Typography>
                    <Chip
                      label={`Date: ${returnMedicine.date}`}
                      color="secondary"
                      variant="outlined"
                      sx={{
                        fontSize: "0.8rem",
                        borderColor: "rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  </Box>

                  {/* Return Medicine Info */}
                  <Stack spacing={1} mt={1}>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Medicine Name
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="500"
                        color="text.primary"
                      >
                        {returnMedicine.stock_medicine_info.medicine_name}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Expiry Date
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="500"
                        color="text.primary"
                      >
                        {returnMedicine.stock_medicine_info.expiry_date}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Quantity Returned
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="500"
                        color="text.primary"
                      >
                        {returnMedicine.quantity}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Sell Price Per Unit
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="500"
                        color="text.primary"
                      >
                        {returnMedicine.stock_medicine_info.sell_price} ؋
                      </Typography>
                    </Box>

                    <Divider sx={{ my: 1 }} />

                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Total Return Amount
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="success.main"
                        textAlign="right"
                      >
                        {returnAmount} ؋
                      </Typography>
                    </Box>
                  </Stack>

                  <Divider sx={{ mt: 2, mb: 2 }} />

                  {/* Accordion for Detailed Information */}
                  <Accordion
                    elevation={0}
                    sx={{ border: "1px solid #e0e0e0", borderRadius: "8px" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-${index}-content`}
                      id={`panel-${index}-header`}
                      sx={{ backgroundColor: "#f5f5f5" }}
                    >
                      <Typography variant="subtitle2" fontWeight="bold">
                        Return Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table sx={{ minWidth: 500 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Dosage</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Notation</TableCell>
                            <TableCell>Is Before Meal</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {returnMedicine.prescription_detail_info ? (
                            <TableRow>
                              <TableCell>
                                {returnMedicine.prescription_detail_info.dosage}
                              </TableCell>
                              <TableCell>
                                {
                                  returnMedicine.prescription_detail_info
                                    .quantity
                                }
                              </TableCell>
                              <TableCell>
                                {
                                  returnMedicine.prescription_detail_info
                                    .notation
                                }
                              </TableCell>
                              <TableCell>
                                {returnMedicine.prescription_detail_info
                                  .is_before
                                  ? "Before"
                                  : "After"}
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell colSpan={4} align="center">
                                No prescription details available.
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>

                  {/* Action Buttons */}
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={1}
                    mt={2}
                  >
                    <Tooltip title="Edit Return Medicine">
                      <IconButton
                        color="primary"
                        onClick={() => handleOpen(returnMedicine)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Return Medicine">
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(returnMedicine.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Paper>
                <ConfirmDialog />
              </Grid>
            );
          })
        ) : (
          <Box sx={{ width: "100%", m: 1 }}>
            <Alert severity="info">No return medicines found.</Alert>
          </Box>
        )}
      </Grid>

      {/* Edit Dialog */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography fontWeight="bold" color="primary">
            Edit Return Medicine
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: "20px" }}>
          {selectedItem && (
            <ReturnMedicineDetailsEdit
              returnMedicineDetail={selectedItem}
              onClose={handleClose}
              token={token}
              pharmacy={pharmacy}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ReturnMedicineList;
