import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Tabs,
  Tab,
  Badge,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NavLink, useNavigate, useLocation, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useFetchObjects from "../../../api/useFetchObjects";
import { useAuth } from "../../../context";
import { useHighlightData } from "../../../context/useHighlightData";
import { useNewRecords } from "../../../context/NewRecordsProvider";

const tabStyle = {
  textTransform: "capitalize",
  fontWeight: "bold",
  borderRadius: "10px",
  transition: "all 0.3s ease",
};

const highlightTabStyle = {
  backgroundColor: "#FFEBEE",
  color: "#D32F2F",
  fontWeight: "bold",
  borderRadius: "10px",
};

const ReceptionNav = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const { newRecords } = useNewRecords();
  const [value, setValue] = useState("/reception");
  const navigate = useNavigate();
  const location = useLocation();

  const { data: collect_medicine_order } = useFetchObjects(
    ["medicine-orders"],
    `medicine-orders/`,
    token
  );

  const { data: collect_prescription_fee } = useFetchObjects(
    ["collect-prescription"],
    `prescriptions/`,
    token
  );

  const { data: collect_xray_fee } = useFetchObjects(
    ["collect-xray-fees"],
    `patient-xray/`,
    token
  );

  const { data: collect_echo_fee } = useFetchObjects(
    ["collect-echo-fees"],
    `patient-echo/`,
    token
  );

  const { data: collect_ultrasound_fee } = useFetchObjects(
    ["collect-ultrasound-fees"],
    `ultrasounds/`,
    token
  );

  const { data: collect_laboratory_fee } = useFetchObjects(
    ["collect-laboratory-fees"],
    `laboratory-test-orders/`,
    token
  );

  // Use the useHighlightData hook for highlighting
  useHighlightData(collect_medicine_order, "medicineOrderFees", (data) =>
    data.some((order) => order.payment_status === 1)
  );

  useHighlightData(collect_prescription_fee, "prescriptionFees", (data) =>
    data.some((prescription) => prescription.payment_status === 1)
  );

  useHighlightData(collect_xray_fee, "xrayFees", (data) =>
    data.some((xray) => xray.is_paid === false)
  );

  useHighlightData(collect_echo_fee, "echoFees", (data) =>
    data.some((echo) => echo.is_paid === false)
  );

  useHighlightData(collect_ultrasound_fee, "ultrasoundFees", (data) =>
    data.some((ultrasound) => ultrasound.is_paid === false)
  );

  useHighlightData(collect_laboratory_fee, "laboratoryFees", (data) =>
    data.some((labTest) => labTest.is_paid === false)
  );

  useEffect(() => {
    const path = location.pathname;
    setValue(path);
  }, [location.pathname]);

  const tabs = [
    { path: "/reception", label: t("reception.dashboard") },
    { path: "/reception/new-patient", label: t("reception.newPatient") },
    { path: "/reception/patient-list", label: t("reception.patientList") },
    { path: "/reception/appointments", label: t("reception.OPD") },
    {
      path: "/reception/collect-prescription-fees",
      label: t("reception.prescriptionFees"),
      highlight: newRecords.prescriptionFees,
    },
    {
      path: "/reception/collect-medicine-order-fees",
      label: t("reception.medicineFees"),
      highlight: newRecords.medicineOrderFees,
    },
    {
      path: "/reception/collect-ultrasound-fees",
      label: t("reception.ultrasoundFees"),
      highlight: newRecords.ultrasoundFees,
    },
    {
      path: "/reception/collect-xray-fees",
      label: t("reception.xrayFees"),
      highlight: newRecords.xrayFees,
    },
    {
      path: "/reception/collect-laboratory-fees",
      label: t("reception.laboratoryFees"),
      highlight: newRecords.laboratoryFees,
    },
    {
      path: "/reception/collect-echo-fees",
      label: t("reception.echoFees"),
      highlight: newRecords.echoFees,
    },
  ];

  return (
    <Grid container sx={{ width: "100vw" }}>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Paper sx={{ padding: "10px 0px", boxShadow: "0px 0px 0px" }}>
          <Tabs
            value={value}
            onChange={(e, newValue) => {
              setValue(newValue);
              navigate(newValue);
            }}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{ ml: "5px", "& .MuiTabs-indicator": { display: "none" } }}
          >
            {tabs.map(({ path, label, highlight }) => (
              <Tab
                key={path}
                sx={{ mr: 2 }}
                style={
                  highlight
                    ? highlightTabStyle
                    : {
                        ...tabStyle,
                        backgroundColor: value === path ? "#004d4d" : "white",
                        color: value === path ? "white" : "black",
                      }
                }
                label={
                  <Badge color="error" invisible={!highlight}>
                    {label}
                  </Badge>
                }
                value={path}
                component={NavLink}
                to={path}
              />
            ))}
          </Tabs>
        </Paper>
      </Box>
      <Outlet />
    </Grid>
  );
};

export default ReceptionNav;
