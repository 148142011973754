// src/routes/roomRoutes.js
import RoomNav from "../../pages/Dashboard/Rooms/RoomNav";
import RoomIndex from "../../pages/Dashboard/Rooms/RoomIndex";
import AssignedRoomSummary from "../../pages/Dashboard/Rooms/assignedRoomSummary/AssignedRoomSummary";

const roomRoutes = {
  path: "/rooms",
  element: <RoomNav />,
  children: [
    { path: "/rooms", element: <RoomIndex /> },
    { path: "/rooms/assigned-rooms-summary", element: <AssignedRoomSummary /> },
  ],
};

export default roomRoutes;
