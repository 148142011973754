import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import useFetchObjects from "../../../../api/useFetchObjects";

const EditSampleDialog = ({ open, onClose, handleEdit, sampleData, auth }) => {
  const [formData, setFormData] = useState({
    patient_test: sampleData?.test?.id || "",
    sample_type: sampleData?.sample_type || "",
    sample_number: sampleData?.sample_number || "",
    collection_date: sampleData?.collection_date || "",
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (sampleData) {
      setFormData({
        patient_test: sampleData.test?.id,
        sample_type: sampleData.sample_type,
        sample_number: sampleData.sample_number,
        collection_date: sampleData.collection_date,
      });
    }
  }, [sampleData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};
    if (!formData.sample_type) errors.sample_type = "Sample type is required.";
    if (!formData.sample_number)
      errors.sample_number = "Sample number is required.";
    if (!formData.collection_date) {
      errors.collection_date = "Collection date is required.";
    } else if (new Date(formData.collection_date) > new Date()) {
      errors.collection_date = "Collection date cannot be in the future.";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    handleEdit(sampleData.id, {
      ...formData,
      technician: auth?.user?.user?.id,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Sample</DialogTitle>
      <DialogContent>
        <TextField
          select
          label="Sample Type"
          name="sample_type"
          value={formData.sample_type}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          error={!!formErrors.sample_type}
          helperText={formErrors.sample_type}
        >
          <MenuItem value={1}>Blood</MenuItem>
          <MenuItem value={2}>Urine</MenuItem>
          <MenuItem value={3}>Tissue</MenuItem>
          <MenuItem value={4}>Stool</MenuItem>
          <MenuItem value={5}>Other</MenuItem>
        </TextField>

        <TextField
          label="Sample Number"
          name="sample_number"
          value={formData.sample_number}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          error={!!formErrors.sample_number}
          helperText={formErrors.sample_number}
        />

        <TextField
          label="Collection Date"
          type="date"
          name="collection_date"
          value={formData.collection_date}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          InputLabelProps={{ shrink: true }}
          error={!!formErrors.collection_date}
          helperText={formErrors.collection_date}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSampleDialog;
