import React, { useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { RemoveCircleOutline, Search } from "@mui/icons-material";
import { useAuth } from "../../../../context";
import useAdd from "../../../../api/useAdd";
import useFetchObjects from "../../../../api/useFetchObjects";
import PurchaseDrugList from "./PurchaseDrugList";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const PurchaseDrug = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const token = auth?.user?.token;
  const pharmacy = auth?.user?.user?.pharmacy_type || "";
  const [medicineCompanyData, setMedicineCompanyData] = useState([]);

  const { data: medicines, refetch: refetchMedicines } = useFetchObjects(
    ["medicine"],
    "medicines",
    token
  );

  const { data: getMedicineCompanyData, isSuccess } = useFetchObjects(
    ["medicine-companies"],
    `medicine-companies/`,
    token
  );

  useEffect(() => {
    if (isSuccess) {
      setMedicineCompanyData(getMedicineCompanyData);
    }
  }, [getMedicineCompanyData, isSuccess]);

  // Retrieve data from localStorage or set defaults
  const [markupPercentage, setMarkupPercentage] = useState(
    () => JSON.parse(localStorage.getItem("markup_percentage")) || 15
  );
  const [input, setInput] = useState(() => {
    const savedItems = localStorage.getItem("purchase_items");
    const savedCompany = localStorage.getItem("selected_company");
    return {
      date: getCurrentDate(),
      medicine_company: savedCompany ? JSON.parse(savedCompany) : null,
      items: savedItems ? JSON.parse(savedItems) : [],
    };
  });
  const [formErrors, setFormErrors] = useState({});
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

  const {
    handleAdd: handleAddPurchase,
    resetForm,
    loading: purchaseLoading,
  } = useAdd(
    "purchases",
    token,
    t("pharmacy.purchaseSuccess"),
    t("pharmacy.operationFailed")
  );

  const autocompleteRef = useRef(null);
  const quantityRefs = useRef([]);
  const purchasePriceRefs = useRef([]);
  const sellPriceRefs = useRef([]);
  const expiryDateRefs = useRef([]);

  useEffect(() => {
    localStorage.setItem("purchase_items", JSON.stringify(input.items));
    localStorage.setItem(
      "selected_company",
      JSON.stringify(input.medicine_company)
    );
    localStorage.setItem("markup_percentage", JSON.stringify(markupPercentage));
  }, [input.items, input.medicine_company, markupPercentage]);

  useEffect(() => {
    if (resetForm) {
      setInput({
        date: getCurrentDate(),
        medicine_company: null,
        items: [],
      });
      setFormErrors({});
      localStorage.removeItem("purchase_items");
      localStorage.removeItem("selected_company");
      localStorage.removeItem("markup_percentage");
    }
  }, [resetForm]);

  const handleChangePurchaseField = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleChangeMarkup = (e) => {
    const newMarkupPercentage = parseFloat(e.target.value) || 0;
    setMarkupPercentage(newMarkupPercentage);

    setInput((prevInput) => ({
      ...prevInput,
      items: prevInput.items.map((item) => {
        const purchasePrice = parseFloat(item.purchase_price) || 0;
        return {
          ...item,
          sell_price: (
            purchasePrice +
            (purchasePrice * newMarkupPercentage) / 100
          ).toFixed(2),
        };
      }),
    }));
  };

  const handleAddNewItem = (medicine) => {
    if (medicine) {
      const newItem = {
        medicine: medicine.id,
        name: medicine.name,
        quantity: "",
        purchase_price: "",
        sell_price: "",
        expiry_date: "",
        subtotal: 0,
        pharmacy: pharmacy,
      };

      setInput((prevInput) => {
        const newItems = [...prevInput.items, newItem];
        return {
          ...prevInput,
          items: newItems,
        };
      });

      setAutocompleteInputValue("");
      if (autocompleteRef.current) {
        autocompleteRef.current.focus();
      }
    }
  };

  const handleChangeItemField = (index, e) => {
    const { name, value } = e.target;
    const items = [...input.items];
    items[index][name] = value;

    if (name === "purchase_price") {
      const purchasePrice = parseFloat(value) || 0;
      items[index].sell_price = (
        purchasePrice +
        (purchasePrice * markupPercentage) / 100
      ).toFixed(2);
    }

    if (name === "quantity" || name === "purchase_price") {
      const quantity = parseFloat(items[index].quantity) || 0;
      const purchasePrice = parseFloat(items[index].purchase_price) || 0;
      items[index].subtotal = (quantity * purchasePrice).toFixed(2);
    }

    setInput({ ...input, items });
  };

  const handleRemoveItem = (index) => {
    const items = [...input.items];
    items.splice(index, 1);
    setInput({ ...input, items });
  };

  const handleSave = (e) => {
    e.preventDefault();
    let isValid = true;
    let errors = {};

    if (!input.date) {
      errors.date = t("pharmacy.purchaseRequired");
      isValid = false;
    }

    input.items.forEach((item, index) => {
      if (!item.medicine) {
        errors[`medicine_${index}`] = t("pharmacy.medicineRequired");
        isValid = false;
      }
      if (!item.quantity) {
        errors[`quantity_${index}`] = t("pharmacy.quantityRequired");
        isValid = false;
      }
      if (!item.purchase_price) {
        errors[`purchase_price_${index}`] = t("pharmacy.purchasePriceRequired");
        isValid = false;
      }
      if (!item.sell_price) {
        errors[`sell_price_${index}`] = t("pharmacy.sellPriceRequired");
        isValid = false;
      }
      if (!item.expiry_date) {
        errors[`expiry_date_${index}`] = t("pharmacy.expiryDateRequired");
        isValid = false;
      }
    });

    setFormErrors(errors);

    if (isValid) {
      const payload = {
        date: input.date,
        medicine_company: input.medicine_company,
        items: input.items,
      };

      handleAddPurchase(payload);
    }
  };

  const calculateTotalPrice = () => {
    return input.items
      .reduce((total, item) => total + parseFloat(item.subtotal || 0), 0)
      .toFixed(2);
  };

  const handleKeyPress = (e, index, field) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const refsArray = {
        quantity: quantityRefs,
        purchase_price: purchasePriceRefs,
        sell_price: sellPriceRefs,
        expiry_date: expiryDateRefs,
      };

      const refArray = refsArray[field];
      const nextIndex = index + 1;

      if (field === "quantity") {
        purchasePriceRefs.current[index]?.focus();
      } else if (field === "purchase_price") {
        sellPriceRefs.current[index]?.focus();
      } else if (field === "sell_price") {
        expiryDateRefs.current[index]?.focus();
      } else if (field === "expiry_date" && nextIndex < input.items.length) {
        quantityRefs.current[nextIndex]?.focus();
      }
    }
  };

  const handleShortcut = (e) => {
    if (e.ctrlKey && e.key === "y") {
      e.preventDefault();
      navigate("/pharmacy/new-medicine");
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleShortcut);
    return () => {
      window.removeEventListener("keydown", handleShortcut);
    };
  }, []);

  useEffect(() => {
    refetchMedicines();
  }, []);

  return (
    <Paper
      sx={{
        boxShadow: "0px 0px 16px rgba(0,0,0,0.1)",
        borderRadius: "12px",
        marginTop: "2rem",
        marginBottom: "2rem",
        padding: { xs: "1.5rem", md: "2.5rem" },
        width: "100%",
      }}
    >
      <Typography fontWeight={600} variant="h4" color="primary" gutterBottom>
        {t("pharmacy.addPurchaseDrugs")}
      </Typography>

      <Divider sx={{ mt: 2, mb: 3, width: "100%" }} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t("pharmacy.purchaseDate")}
            name="date"
            type="date"
            size="medium"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={input.date}
            onChange={handleChangePurchaseField}
            error={!!formErrors.date}
            helperText={formErrors.date}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            label={t("pharmacy.medicineCompany")}
            name="medicine_company"
            size="medium"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={input.medicine_company}
            onChange={handleChangePurchaseField}
          >
            {medicineCompanyData.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {"Company Name:" +
                  item.name +
                  " Phone:" +
                  item.phone +
                  " Address:" +
                  item.address}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t("pharmacy.markupPercentage")}
            type="number"
            size="medium"
            variant="outlined"
            value={markupPercentage}
            onChange={handleChangeMarkup}
            helperText={t("pharmacy.enterMarkupPercentage")}
          />
        </Grid>
      </Grid>

      <Box mb={4} mt={2}>
        <Typography variant="subtitle1" fontWeight={500} mb={2}>
          {t("pharmacy.addMedicine")}
        </Typography>
        <Autocomplete
          ref={autocompleteRef}
          value={null}
          inputValue={autocompleteInputValue}
          onInputChange={(event, newInputValue) => {
            setAutocompleteInputValue(newInputValue);
          }}
          onChange={(event, value) => {
            handleAddNewItem(value);
          }}
          options={medicines || []}
          getOptionLabel={(option) =>
            `${option.name} (${option.category_display})`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("pharmacy.searchMedicine")}
              variant="outlined"
              placeholder={t("pharmacy.searchMedicinePlaceholder")}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#fff",
                borderRadius: 2,
              }}
            />
          )}
        />
      </Box>

      <TableContainer component={Box} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              <TableCell align="center">
                <Typography fontWeight={600}>
                  {t("pharmacy.medicine")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={600}>
                  {t("pharmacy.quantity")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={600}>
                  {t("pharmacy.purchasePrice")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={600}>
                  {t("pharmacy.sellPrice")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={600}>
                  {t("pharmacy.expiryDate")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={600}>
                  {t("pharmacy.subtotal")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={600}>{t("pharmacy.action")}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {input.items.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="center">{item.name}</TableCell>
                <TableCell align="center">
                  <TextField
                    inputRef={(el) => (quantityRefs.current[index] = el)}
                    name="quantity"
                    type="number"
                    size="small"
                    variant="outlined"
                    value={item.quantity}
                    onChange={(e) => handleChangeItemField(index, e)}
                    onKeyDown={(e) => handleKeyPress(e, index, "quantity")}
                    error={!!formErrors[`quantity_${index}`]}
                    helperText={formErrors[`quantity_${index}`]}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    inputRef={(el) => (purchasePriceRefs.current[index] = el)}
                    name="purchase_price"
                    type="number"
                    size="small"
                    variant="outlined"
                    value={item.purchase_price}
                    onChange={(e) => handleChangeItemField(index, e)}
                    onKeyDown={(e) =>
                      handleKeyPress(e, index, "purchase_price")
                    }
                    error={!!formErrors[`purchase_price_${index}`]}
                    helperText={formErrors[`purchase_price_${index}`]}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    inputRef={(el) => (sellPriceRefs.current[index] = el)}
                    name="sell_price"
                    type="number"
                    size="small"
                    variant="outlined"
                    value={item.sell_price}
                    onChange={(e) => handleChangeItemField(index, e)}
                    onKeyDown={(e) => handleKeyPress(e, index, "sell_price")}
                    error={!!formErrors[`sell_price_${index}`]}
                    helperText={formErrors[`sell_price_${index}`]}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    inputRef={(el) => (expiryDateRefs.current[index] = el)}
                    fullWidth
                    name="expiry_date"
                    type="date"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    value={item.expiry_date}
                    onChange={(e) => handleChangeItemField(index, e)}
                    onKeyDown={(e) => handleKeyPress(e, index, "expiry_date")}
                    error={!!formErrors[`expiry_date_${index}`]}
                    helperText={formErrors[`expiry_date_${index}`]}
                  />
                </TableCell>
                <TableCell align="center">
                  <Typography>{item.subtotal} ؋</Typography>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveItem(index)}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={2} mb={4}>
        <Typography variant="h6" align="right" fontWeight={600}>
          {t("pharmacy.totalPrice")}: {calculateTotalPrice()} ؋
        </Typography>
      </Box>

      <Stack direction="row" justifyContent="flex-end" mt={4}>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={purchaseLoading}
          sx={{
            padding: "12px 30px",
            fontSize: "1rem",
            fontWeight: "bold",
            backgroundColor: purchaseLoading ? "#bdbdbd" : "#1976d2",
            color: "white",
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: purchaseLoading ? "#bdbdbd" : "#115293",
            },
          }}
        >
          {purchaseLoading ? t("pharmacy.saving") : t("pharmacy.savePurchase")}
        </Button>
      </Stack>

      <Divider sx={{ mt: 4, width: "100%" }} />
      <PurchaseDrugList />
    </Paper>
  );
};

export default PurchaseDrug;
