import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";

const AssignPatientDialog = ({
  open,
  selectedRoom,
  patientId,
  setPatientId,
  handleCloseDialog,
  handleAssign,
  token,
}) => {
  const { t, i18n } = useTranslation();
  const { data } = useFetchObjects(["patients"], "patients/", token);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);

  const textDirection = i18n.language === "fa" ? "rtl" : "ltr";

  useEffect(() => {
    if (data?.results) {
      setFilteredPatients(
        data.results.filter(
          (patient) =>
            patient.firstname
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            patient.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            patient.id.toString().includes(searchTerm)
        )
      );
    }
  }, [data, searchTerm]);

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle>
        {t("room.assignPatientRoom")} {selectedRoom?.room_number}
      </DialogTitle>
      <DialogContent dir={textDirection}>
        <Box component="form" sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label={t("room.searchByNameId")}
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <List>
            {filteredPatients.map((patient) => (
              <ListItem
                button
                key={patient.id}
                onClick={() => setPatientId(patient.id)}
                selected={patientId === patient.id}
              >
                <ListItemText
                  primary={`${patient.firstname} ${patient.lastname}`}
                  secondary={`${t("room.id")}: ${patient.id}, ${t(
                    "room.phone"
                  )}: ${patient.phone}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="error">
          {t("room.cancel")}
        </Button>
        <Button
          onClick={handleAssign}
          variant="contained"
          color="primary"
          disabled={!patientId}
          sx={{margin:"0px 10px"}}
        >
          {t("room.assign")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignPatientDialog;
