import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  FormControl,
  FormLabel,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import useUpdate from "../../../../api/useUpdate";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";

const EditXrayResult = ({ editData, openDialog, handleOpenDialog }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { handleUpdate, loading } = useUpdate("patient-xray-results", token);

  const { data: xrayData } = useFetchObjects(
    ["patient-xray"],
    "patient-xray/",
    token
  );

  const [formData, setFormData] = useState({
    patient_xray: editData?.patient_xray || "",
    findings: editData?.findings || "",
    result_date:
      editData?.result_date || new Date().toISOString().split("T")[0],
    image: null,
    existingImageUrl: editData?.image || "", // Display existing image if available
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        patient_xray: editData.patient_xray,
        findings: editData.findings,
        result_date: editData.result_date,
        image: null,
        existingImageUrl: editData.image || "",
      });
    }
  }, [editData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePatientSelect = (event, selectedXray) => {
    if (selectedXray) {
      setFormData({
        ...formData,
        patient_xray: selectedXray.id,
      });
    }
  };

  const handleFileChange = (event) => {
    setFormData({ ...formData, image: event.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSubmit = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null && key !== "existingImageUrl") {
        formDataToSubmit.append(key, formData[key]);
      }
    });
    handleUpdate(editData.id, formDataToSubmit);
    handleOpenDialog();
  };

  return (
    <Dialog open={openDialog} onClose={handleOpenDialog}>
      <DialogTitle>Edit Exray Result</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            p: 3,
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: "#ffffff",
            maxWidth: 600,
            margin: "auto",
          }}
        >
          <form onSubmit={handleSubmit}>
            <FormLabel>{t("x_ray.selectPatientXray")}</FormLabel>
            <Autocomplete
              options={xrayData ?? []}
              getOptionLabel={(option) =>
                `${option.patient_display} - ${option.xray_type_display}`
              }
              value={
                xrayData?.find((x) => x.id === formData.patient_xray) || null
              }
              onChange={handlePatientSelect}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="patient_xray"
                  fullWidth
                  size="small"
                  required
                  placeholder={t("x_ray.selectPatientXray")}
                />
              )}
              sx={{ mb: 2 }}
            />

            <TextField
              fullWidth
              label={t("x_ray.findings")}
              name="findings"
              value={formData.findings}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={4}
              sx={{ mb: 2 }}
              required
            />

            <TextField
              fullWidth
              label={t("x_ray.resultDate")}
              type="date"
              name="result_date"
              value={formData.result_date}
              onChange={handleChange}
              variant="outlined"
              sx={{ mb: 2 }}
              required
            />

            <FormLabel>{t("x_ray.uploadFile")}</FormLabel>
            {formData.existingImageUrl && (
              <Box
                sx={{
                  mb: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={formData.existingImageUrl}
                  alt="Existing X-ray"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "8px",
                    objectFit: "cover",
                    marginBottom: "8px",
                  }}
                />
              </Box>
            )}
            <TextField
              type="file"
              fullWidth
              onChange={handleFileChange}
              sx={{ mb: 2 }}
              inputProps={{ accept: "image/*" }}
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : t("x_ray.update")}
            </Button>
          </form>
        </Box>
        <DialogActions>
          <Button onClick={handleOpenDialog}>Close</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EditXrayResult;
