import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";

const BedPatient = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [searchTerm, setSearchTerm] = useState("");
  const [isActiveFilter, setIsActiveFilter] = useState(true); // Filter state for active rooms

  const { data, isLoading, isError, error, refetch } = useFetchObjects(
    ["room-assignment"],
    `room-assignment/?search=${searchTerm}&is_active=${isActiveFilter}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [searchTerm, isActiveFilter]);

  return (
    <Grid
      container
      spacing={4}
      maxWidth={"xl"}
      mt={2}
      justifyContent={"center"}
      mb={10}
    >
      <Grid item xs={12} lg={10}>
        <Paper elevation={3} sx={{ padding: "20px", borderRadius: 3 }}>
          {/* Search Bar Section */}
          <Box mb={4}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#007174" }}
            >
              {t("room.assignRoom")}
            </Typography>
            <TextField
              label={t("room.searchRoomOrPatient")}
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: "100%", mt: 2 }}
            />

            {/* Active Filter */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isActiveFilter}
                  onChange={(e) => setIsActiveFilter(e.target.checked)}
                  color="primary"
                />
              }
              label={t("room.showActiveRoomsOnly")}
              sx={{ mt: 2 }}
            />
          </Box>

          <Divider sx={{ marginY: 3 }} />

          {/* Loading State */}
          {isLoading && (
            <Box display="flex" justifyContent="center" mt={4}>
              <CircularProgress />
            </Box>
          )}

          {/* Error State */}
          {isError && (
            <Box mt={4}>
              <Alert severity="error">Error: {error.message}</Alert>
            </Box>
          )}

          {/* Room List Section */}
          {data && data.length > 0 && (
            <TableContainer
              component={Paper}
              sx={{ marginTop: 3, boxShadow: 4, borderRadius: 2 }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#e0f7fa" }}>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", color: "#004d40" }}
                    >
                      {t("room.roomNumber")}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", color: "#004d40" }}
                    >
                      {t("room.floor")}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", color: "#004d40" }}
                    >
                      {t("room.patientName")}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", color: "#004d40" }}
                    >
                      {t("room.assignedDate")}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", color: "#004d40" }}
                    >
                      {t("room.dischargeDate")}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", color: "#004d40" }}
                    >
                      {t("room.status")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((assignment) => (
                    <TableRow
                      key={assignment.id}
                      hover
                      sx={{
                        transition: "0.3s",
                        "&:hover": { backgroundColor: "#f0f0f0" },
                      }}
                    >
                      <TableCell align="center">
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "medium" }}
                        >
                          {assignment.room_display}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "medium" }}
                        >
                          {assignment.floor_display}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "medium" }}
                        >
                          {assignment.patient_display}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "medium" }}
                        >
                          {new Date(
                            assignment.assigned_date
                          ).toLocaleDateString()}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "medium" }}
                        >
                          {assignment.discharge_date
                            ? new Date(
                                assignment.discharge_date
                              ).toLocaleDateString()
                            : t("room.notDischarged")}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {assignment.is_active ? (
                          <IconButton color="success">
                            <CheckCircleIcon />
                          </IconButton>
                        ) : (
                          <IconButton color="error">
                            <CancelIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Empty Data State */}
          {!isLoading && data?.length === 0 && (
            <Box mt={4}>
              <Alert severity="info">{t("room.noRoomAssignments")}</Alert>
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BedPatient;
