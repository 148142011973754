import React from "react";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
  Avatar,
} from "@mui/material";
import { WarningAmber } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import { StockMedicineSkeleton } from "./loading/StockMedicineSkeleton";

const MedicineExpirySixMonthLater = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacy = auth?.user?.user?.pharmacy_type;

  const {
    data: expiringMedicinesData,
    isError: isErrorExpiring,
    error: errorExpiring,
  } = useFetchObjects(
    ["expiring-medicines"],
    `medicine-left-sex-month/?pharmacy=${pharmacy}`,
    token
  );

  if (!expiringMedicinesData) {
    return <StockMedicineSkeleton />;
  }

  if (isErrorExpiring) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">{errorExpiring.message}</Typography>
      </Box>
    );
  }

  return (
    <Paper
      sx={{
        boxShadow: "0px 8px 24px rgba(0,0,0,0.1)",
        borderRadius: "16px",
        marginTop: "2rem",
        padding: { xs: "1.5rem", md: "2.5rem" },
        width: "100%",
      }}
    >
      {/* Header Section */}
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Avatar sx={{ bgcolor: "#d32f2f", width: 56, height: 56 }}>
            <WarningAmber fontSize="large" />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography fontWeight={700} variant="h4" color="error">
            {t("overview.expiringMedicines")}
          </Typography>
        </Grid>
      </Grid>

      {/* Table Section */}
      <TableContainer component={Box} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              <TableCell align="center">
                <Typography fontWeight={700} color="primary">
                  {t("pharmacy.category")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={700} color="primary">
                  {t("pharmacy.medicineName")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={700} color="primary">
                  {t("pharmacy.expiryDate")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={700} color="primary">
                  {t("pharmacy.quantity")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={700} color="primary">
                  {t("pharmacy.sellPrice")} (؋)
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expiringMedicinesData.map((stock, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:hover": { backgroundColor: "#f9f9f9" },
                  "&:nth-of-type(even)": { backgroundColor: "#fdfdfd" },
                }}
              >
                <TableCell align="center">
                  <Typography>{stock.category_display}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{stock.medicine_display}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{stock.expiry_date}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{stock.quantity}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{stock.sell_price} ؋</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MedicineExpirySixMonthLater;
