// OperationsNav.js
import { Box, Grid, Paper, Tabs, Tab } from "@mui/material";
import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const tabStyle = {
  textTransform: "capitalize",
  fontWeight: "bold",
  borderRadius: "10px",
};

const OperationsNav = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("/operations");
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    const path = location.pathname;
    const validPaths = ["/operations", "/operations/add", "/operations/list"];

    if (validPaths.includes(path)) {
      setValue(path);
    } else {
      setValue("/operations");
    }
  }, [location.pathname]);

  return (
    <Grid container maxWidth="xl">
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Paper sx={{ padding: "10px 0px", boxShadow: "0px 0px 0px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              ml: "5px",
              "& .MuiTabs-indicator": { display: "none" },
              "& .MuiTab-root": { minWidth: "120px" },
            }}
          >
            <Tab
              style={{
                ...tabStyle,
                backgroundColor: value === "/operations" ? "#007174" : "white",
                color: value === "/operations" ? "white" : "black",
              }}
              label={t("operations.dashboard")}
              value="/operations"
              component={NavLink}
              to="/operations"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/operations/add" ? "#007174" : "white",
                color: value === "/operations/add" ? "white" : "black",
              }}
              label={t("operations.addOperations")}
              value="/operations/add"
              component={NavLink}
              to="/operations/add"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/operations/list" ? "#007174" : "white",
                color: value === "/operations/list" ? "white" : "black",
              }}
              label={t("operations.listOperations")}
              value="/operations/list"
              component={NavLink}
              to="/operations/list"
            />
          </Tabs>
        </Paper>
      </Box>
      <Outlet />
    </Grid>
  );
};

export default OperationsNav;
