// src/routes/AppRoutes.js
import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "../App";
import receptionRoutes from "./ReceptionRoutes/ReceptionRoutes";
import pharmacyRoutes from "./PharmacyRoutes/PharmacyRoutes";
import doctorRoutes from "./DoctorRoutes/DoctorRoutes";
import nurseRoutes from "./NurseRoutes/NurseRoutes";
import xrayRoutes from "./XrayRoutes/XrayRoutes";
import roomRoutes from "./RoomRoutes/RooomRoutes";
import ultrasoundRoutes from "./UltraSoundRoutes/UltraSoundRoutes";
import laboratoryRoutes from "./LaboratoryRoutes/LaboratoryRoutes";
import echocardiographyRoutes from "./EchocardiographyRoutes/EchocardiographyRoutes";
import operationsRoutes from "./OperationsRoutes/OperationsRoutes";
import patientProfileRoutes from "./PatientProfile/PatientProfile";
import financeRoutes from "./FinanceRoutes/FinanceRoutes";
import humanResourceRoutes from "./HRRoutes/HRRoutes";
import SignInView from "../pages/Authentication/SignIn/SignIn";

export const createAppRouter = (permissions) => {
  // Conditionally add routes based on permissions
  const childrenRoutes = [
    permissions.reception && receptionRoutes,
    permissions.pharmacy && pharmacyRoutes,
    permissions.doctor && doctorRoutes,
    permissions.nurse && nurseRoutes,
    permissions.x_ray && xrayRoutes,
    permissions.rooms && roomRoutes,
    permissions.ultrasounds && ultrasoundRoutes,
    permissions.laboratory && laboratoryRoutes,
    permissions.echo && echocardiographyRoutes,
    permissions.operations && operationsRoutes,
    permissions.finance && financeRoutes,
    permissions.hr && humanResourceRoutes,
  ].filter(Boolean);

  return createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: [...childrenRoutes, patientProfileRoutes],
    },
    { path: "user/sign-in/", element: <SignInView /> },
    { path: "*", element: <Navigate to={"/"} /> },
  ]);
};
