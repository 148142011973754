import React from "react";
import { useTranslation } from "react-i18next";

const AssignedRoomPrint = ({ data }) => {
  const direction = localStorage.getItem("direction");
  const { t } = useTranslation();

  return (
    <div
      id="printable"
      style={{
        width: "80mm",
        fontFamily: "Arial, sans-serif",
        padding: "15px",
        boxSizing: "border-box",
        direction: direction,
      }}
    >
      {/* Header Section */}
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <h2
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "#007174",
            margin: 0,
          }}
        >
          {t("room.hafizBalkh")}
        </h2>
        <p style={{ fontSize: "12px", color: "#333", margin: "5px 0" }}>
          {t("room.tel")}: 0796176000
        </p>
        <p style={{ fontSize: "11px", color: "#555", margin: "5px 0" }}>
          {t("room.address")}
        </p>
        <hr style={{ border: "1px dashed #007174", margin: "10px 0" }} />
      </div>

      {/* Room Assignment Details */}
      <div style={{ fontSize: "12px", lineHeight: "1.6", color: "#333" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <strong>{t("room.roomNumber")}:</strong>
          <span>{data.room_display}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <strong>{t("room.patientName")}:</strong>
          <span>{data.patient_display}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <strong>{t("room.paymentStatus")}:</strong>
          <span>{data.is_paid ? t("room.paid") : t("room.pending")}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <strong>{t("room.totalAmount")}:</strong>
          <span>{data.total_amount ? `؋${data.total_amount}` : "N/A"}</span>
        </div>
      </div>

      {/* Footer Section */}
      <hr style={{ border: "1px dashed #007174", margin: "10px 0" }} />
      <div style={{ textAlign: "center", color: "#333" }}>
        <p style={{ fontSize: "12px", margin: "5px 0" }}>
          {t("room.thankYou")}
        </p>
        <p style={{ fontSize: "12px", margin: "5px 0" }}>
          {t("room.getWellSoon")}
        </p>
      </div>
    </div>
  );
};

export default AssignedRoomPrint;

const printStyles = `
 @media print {
  @page {
    size: 80mm auto;
    margin: 0;
  }
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }
  body * {
    visibility: hidden;
  }
  #printable, #printable * {
    visibility: visible;
  }
  #printable {
    position: absolute;
    left: 0;
    top: 0;
    width: 80mm;
    margin: 0;
    padding: 0;
  }
}
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = printStyles;
document.head.appendChild(styleSheet);
