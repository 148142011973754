// src/routes/echocardiographyRoutes.js
import EchocardiographyNav from "../../pages/Dashboard/Echocardiography/EchocardiographyNav";
import EchocardiographyIndex from "../../pages/Dashboard/Echocardiography/EchocardiographyIndex";
import EchoResultEntry from "../../pages/Dashboard/Echocardiography/testResult/EchoResultEntry";
import EchoType from "../../pages/Dashboard/Echocardiography/EchoType/EchoType";

const echocardiographyRoutes = {
  path: "/echocardiography",
  element: <EchocardiographyNav />,
  children: [
    { path: "/echocardiography", element: <EchocardiographyIndex /> },
    { path: "/echocardiography/test-result", element: <EchoResultEntry /> },
    { path: "/echocardiography/echo-type", element: <EchoType /> },
  ],
};

export default echocardiographyRoutes;
