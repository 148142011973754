import {
  Divider,
  Grid,
  Pagination,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";

const Tasks = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [newStatus, setNewStatus] = useState("");

  const auth = useAuth();
  const token = auth?.user?.token;
  const nurse = auth?.user?.user?.id;
  const [status, setStatus] = useState("");

  // Destructure loading and error states from useFetchObjects
  const { data, refetch, isLoading, isError } = useFetchObjects(
    ["assign-task-to-nurse"],
    `assign-task-to-nurse/?status=${status}&nurse=${nurse}`,
    token
  );

  const { handleUpdate } = useUpdate("assign-task-to-nurse", token);

  useEffect(() => {
    refetch();
  }, [status, refetch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenDialog = (description) => {
    setSelectedDescription(description);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDescription("");
  };

  const handleChipClick = (item) => {
    setSelectedItem(item);
    setNewStatus(item.status);
    setOpenStatusDialog(true);
  };

  const handleCloseStatusDialog = () => {
    setOpenStatusDialog(false);
    setSelectedItem(null);
    setNewStatus("");
  };

  const handleStatusChange = () => {
    if (selectedItem) {
      handleUpdate(selectedItem.id, { status: newStatus });
      setOpenStatusDialog(false);
      refetch();
    }
  };

  const getChipColor = (status) => {
    return status === 1
      ? "orange"
      : status === 2
      ? "blue"
      : status === 3
      ? "green"
      : "grey";
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ boxShadow: "0px 0px 0px" }}>
        <Grid container padding={"10px"}>
          <Grid item>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="scrollable force tabs example"
            >
              <Tab
                onClick={() => setStatus("")}
                sx={{ textTransform: "capitalize" }}
                label={t("nurse.allTask")}
              />
              <Tab
                onClick={() => setStatus(1)}
                sx={{ textTransform: "capitalize" }}
                label={t("nurse.pending")}
              />
              <Tab
                onClick={() => setStatus(2)}
                sx={{ textTransform: "capitalize" }}
                label={t("nurse.inProgress")}
              />
              <Tab
                onClick={() => setStatus(3)}
                sx={{ textTransform: "capitalize" }}
                label={t("nurse.done")}
              />
            </Tabs>
          </Grid>
          <Divider sx={{ mt: 2, width: "100%" }} />

          {/* Loading and Error States */}
          {isLoading ? (
            <Box>{t("nurse.loading")}</Box>
          ) : isError ? (
            <Box color="red">{t("nurse.errorLoading")}</Box>
          ) : (
            <TableContainer sx={{ mt: 2 }}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                  <TableRow>
                    <TableCell>{t("nurse.time")}</TableCell>
                    <TableCell>{t("nurse.date")}</TableCell>
                    <TableCell>{t("nurse.patientName")}</TableCell>
                    <TableCell>{t("nurse.doctor")}</TableCell>
                    <TableCell>{t("nurse.priority")}</TableCell>
                    <TableCell>{t("nurse.room_number")}</TableCell>
                    <TableCell>{t("nurse.details")}</TableCell>
                    <TableCell>{t("nurse.status")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((item, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {item.time}
                      </TableCell>
                      <TableCell>{item.assigned_date}</TableCell>
                      <TableCell>{item.patient_display}</TableCell>
                      <TableCell>{item.doctor_display}</TableCell>
                      <TableCell>{item.priority_display}</TableCell>
                      <TableCell>{item.room_number_display}</TableCell>
                      <TableCell>
                        <IconButton
                          sx={{ color: "green" }}
                          onClick={() => handleOpenDialog(item.description)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={item.status_display}
                          onClick={() => handleChipClick(item)}
                          sx={{
                            backgroundColor: getChipColor(item.status),
                            color: "white",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Grid container justifyContent={"center"} margin={"18px 0px"}>
                <Pagination
                  count={5}
                  page={1}
                  variant="outlined"
                  shape="rounded"
                />
              </Grid>
            </TableContainer>
          )}
        </Grid>

        {/* Task Description Dialog */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2,
              padding: 2,
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" fontWeight="bold">
                {t("nurse.taskDescription")}
              </Typography>
              <IconButton onClick={handleCloseDialog}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant="body1" sx={{ color: "#333" }}>
              {selectedDescription}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              variant="outlined"
              color="primary"
              sx={{ textTransform: "none" }}
            >
              {t("nurse.close")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openStatusDialog}
          onClose={handleCloseStatusDialog}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2,
              padding: 2,
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          <DialogTitle>
            <Typography variant="h6" fontWeight="bold">
              {t("nurse.changeStatus")}
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant="body1" sx={{ color: "#333" }}>
              {t("nurse.areYouSureChangeStatus")}
            </Typography>
            <Select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              displayEmpty
              sx={{ mt: 2, width: "100%" }}
            >
              <MenuItem value={1}> {t("nurse.pending")}</MenuItem>
              <MenuItem value={2}> {t("nurse.inProgress")}</MenuItem>
              <MenuItem value={3}> {t("nurse.done")}</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseStatusDialog}
              variant="outlined"
              color="primary"
              sx={{ textTransform: "none" }}
            >
              {t("nurse.cancel")}
            </Button>
            <Button
              onClick={handleStatusChange}
              variant="contained"
              color="primary"
              sx={{ textTransform: "none", margin: "0px 4px" }}
            >
              {t("nurse.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Grid>
  );
};

export default Tasks;
