import React, { forwardRef } from "react";

const PrescriptionPrint = forwardRef(({ data }, ref) => {
  const direction = localStorage.getItem("direction");

  return (
    <div
      ref={ref}
      style={{
        width: "210mm",
        height: "280mm",
        fontFamily: "Arial, sans-serif",
        fontSize: "12px",
        direction: direction,
        padding: "30px",
        boxSizing: "border-box",
        backgroundColor: "#ffffff",
        color: "#333",
        position: "relative",
      }}
    >
      <div
        style={{
          height: "40mm",
          width: "100%",
        }}
      ></div>

      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        <div style={{ width: "50%" }}>
          <p style={{ margin: "6px 0", fontWeight: "bold", fontSize: "13px" }}>
            Doctor:{" "}
            <span style={{ fontWeight: "normal", fontSize: "12px" }}>
              {data.doctor_display}
            </span>
          </p>
          <p style={{ margin: "6px 0", fontWeight: "bold", fontSize: "13px" }}>
            Patient:{" "}
            <span style={{ fontWeight: "normal", fontSize: "12px" }}>
              {data.patient_display}
            </span>
          </p>
        </div>
        <div style={{ width: "50%", textAlign: "right" }}>
          <p style={{ margin: "6px 0", fontWeight: "bold", fontSize: "13px" }}>
            Date:{" "}
            <span style={{ fontWeight: "normal", fontSize: "12px" }}>
              {new Date().toLocaleDateString()}
            </span>
          </p>
          <p style={{ margin: "6px 0", fontWeight: "bold", fontSize: "13px" }}>
            Prescription No:{" "}
            <span style={{ fontWeight: "normal", fontSize: "12px" }}>
              #{data.id}
            </span>
          </p>
        </div>
      </section>

      <section style={{ marginBottom: "20px" }}>
        <h3 style={{ color: "#007174", fontSize: "16px", margin: "10px 0" }}>
          Prescribed Medicines
        </h3>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            fontSize: "10px",
          }}
        >
          <thead>
            <tr>
              {[
                "#",
                "Category",
                "Medicine",
                "Quantity",
                "Unit Per Meal",
                "Dosage",
                "Before Meal",
                "Notes",
              ].map((header) => (
                <th
                  key={header}
                  style={{
                    padding: "8px",
                    backgroundColor: "#f0f0f0",
                    color: "#007174",
                    fontWeight: "bold",
                    borderBottom: "2px solid #007174",
                    textAlign: "center",
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.drugs?.map((item, index) => (
              <tr
                key={item.id}
                style={{
                  backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                }}
              >
                <td style={cellStyle}>{index + 1}</td>
                <td style={cellStyle}>
                  {item?.stock_medicine_category_display}
                </td>
                <td style={cellStyle}>{item?.stock_medicine_display}</td>
                <td style={cellStyle}>{item?.quantity}</td>
                <td style={cellStyle}>{item?.units_per_meal}</td>
                <td style={cellStyle}>{item?.dosage}</td>
                <td style={cellStyle}>{item?.is_before}</td>
                <td style={cellStyle}>{item?.notation}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      <div
        style={{
          position: "absolute",
          bottom: "30px",
          width: "calc(100% - 60px)",
          textAlign: "center",
          pageBreakInside: "avoid",
        }}
      >
        <p
          style={{
            fontSize: "11px",
            color: "#333",
            marginBottom: "8px",
            borderTop: "1px solid #000",
            width: "50%",
            margin: "0 auto",
            paddingTop: "10px",
          }}
        >
          Signature: ____________________
        </p>
      </div>
    </div>
  );
});

const cellStyle = {
  padding: "8px", // Reduced padding for a smaller, cleaner table
  textAlign: "center",
  borderBottom: "1px solid #ccc",
  fontSize: "10px", // Consistent smaller font size for cells
};

export default PrescriptionPrint;
