import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#a4de6c"];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        sx={{
          padding: "8px",
          borderRadius: "8px",
          backgroundColor: "#f5f5f5",
          boxShadow: 3,
          minWidth: "150px",
        }}
      >
        <Typography variant="subtitle2" color="textSecondary">
          {label}
        </Typography>
        {payload.map((entry, index) => (
          <Typography key={index} variant="body2" sx={{ color: entry.color }}>
            {`${entry.name}: ${entry.value.toLocaleString()}؋`}
          </Typography>
        ))}
      </Paper>
    );
  }
  return null;
};

const PharmacyReport = ({
  medicineSalesData = {},
  prescriptionData = {},
  echoData = {},
  purchaseData = {},
}) => {
  const { t } = useTranslation();
  const data = [
    {
      name: t("finance.medicineSales"),
      Total: medicineSalesData.total_medicine_sales || 0,
      Unpaid: medicineSalesData.unpaid_medicine_sales || 0,
    },
    {
      name: t("finance.prescriptionSales"),
      Total: prescriptionData.total_prescription_sales || 0,
      Unpaid: prescriptionData.unpaid_prescription_sales || 0,
    },
    {
      name: t("finance.echoRevenue"),
      Total: echoData.total_echo_revenue || 0,
      Unpaid: echoData.unpaid_echo_amount || 0,
    },
    {
      name: t("finance.purchaseExpenses"),
      Total: purchaseData.total_purchase_expenses || 0,
      Unapproved: purchaseData.unapproved_purchase_expenses || 0,
    },
  ];

  return (
    <Grid container padding={"0px 5px"}>
      <Paper
        sx={{ width: "100%", padding: 3, borderRadius: "8px", boxShadow: 4 }}
      >
        <Box textAlign={"center"} mt={1} mb={2}>
          <Typography variant="h5" fontWeight="bold" color="primary">
            {t("finance.pharmacyPurchases")}
          </Typography>
        </Box>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={data}
            layout="horizontal"
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
            <XAxis dataKey="name" tick={{ fill: "#555", fontWeight: "bold" }} />
            <YAxis tick={{ fill: "#555", fontWeight: "bold" }} />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" align="right" iconType="circle" />

            {/* Bars for Total and Unpaid/Unapproved */}
            <Bar
              dataKey="Total"
              stackId="a"
              fill="#1976d2"
              barSize={20}
              radius={[4, 4, 0, 0]}
              name={t("finance.total")}
            />
            <Bar
              dataKey="Unpaid"
              stackId="a"
              fill="#ff8a65"
              barSize={20}
              radius={[4, 4, 0, 0]}
              name={t("finance.unpaid")}
            />
            <Bar
              dataKey="Unapproved"
              stackId="a"
              fill="#d32f2f"
              barSize={20}
              radius={[4, 4, 0, 0]}
              name={t("finance.unapproved")}
            />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    </Grid>
  );
};

export default PharmacyReport;
