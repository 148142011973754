import React, { useState, useRef } from "react";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import PrintIcon from "@mui/icons-material/Print";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useUpdate from "../../../../api/useUpdate";
import SkeletonLoading from "./loading/SkeletonLoading";
import { useTranslation } from "react-i18next";
import ViewTestsDialog from "./ViewTestsDialog";
import Print from "./Print";

const getButtonColor = (status) => {
  switch (status) {
    case 1:
      return "#FFC107";
    case 3:
      return "#4CAF50";
    case 4:
      return "#F44336";
    default:
      return "#a7e2f9";
  }
};

const formatTime = (datetime) => {
  const date = new Date(datetime);
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

const formatDate = (datetime) => {
  const date = new Date(datetime);

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const TestsToBeDoneLaboratory = () => {
  const { t } = useTranslation();
  const LabTestStatus = {
    1: t("laboratory.pending"),
    3: t("laboratory.completed"),
    4: t("laboratory.cancelled"),
  };
  const [value, setValue] = useState(0);
  const auth = useAuth();
  const token = auth?.user?.token;

  const status = value === 0 ? 1 : value === 1 ? 3 : 4;

  const { data, isLoading, isError, error } = useFetchObjects(
    ["laboratory-test-orders", status],
    `laboratory-test-orders/?status=${status}`,
    token
  );

  const { handleUpdate } = useUpdate("laboratory-test-orders", token);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, test) => {
    setAnchorEl(event.currentTarget);
    setSelectedTest(test);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedTest(null);
  };

  const handleStatusChange = (newStatus) => {
    const formData = new FormData();
    formData.append("status", newStatus);
    handleUpdate(selectedTest.id, formData);
    handleClose();
  };

  // State for the "View Tests" modal
  const [isTestsDialogOpen, setTestsDialogOpen] = useState(false);
  const [selectedOrderTests, setSelectedOrderTests] = useState([]);
  const printRef = useRef();

  const handleOpenTestsDialog = (tests, test) => {
    setSelectedOrderTests(tests);
    setSelectedTest(test);
    setTestsDialogOpen(true);
  };

  const handleCloseTestsDialog = () => {
    setTestsDialogOpen(false);
    setSelectedOrderTests([]);
  };

  const handlePrint = () => {
    setTimeout(() => {
      if (printRef.current) {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }, 500);
  };

  const buttonStyle = {
    border: "2px solid",
    borderRadius: "0.5em",
    color: "#a7e2f9",
    margin: "1em",
  };

  return (
    <Grid item xs={12}>
      {isLoading ? (
        <SkeletonLoading />
      ) : (
        <Paper sx={{ boxShadow: "0px 0px 0px" }}>
          <Grid container padding={"10px"}>
            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable force tabs example"
              >
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={t("laboratory.testToBeDone")}
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={t("laboratory.done")}
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={t("laboratory.canceled")}
                />
              </Tabs>
            </Grid>
            <Divider sx={{ mt: 2, width: "100%" }} />

            {isError && (
              <Typography>
                {t("laboratory.error")}: {error.message}
              </Typography>
            )}
            {data && (
              <TableContainer sx={{ mt: 2, overflowX: "auto" }}>
                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                    <TableRow>
                      <TableCell align="center">
                        {t("laboratory.time")}
                      </TableCell>
                      <TableCell align="center">
                        {t("laboratory.date")}
                      </TableCell>
                      <TableCell align="center">
                        {t("laboratory.patientName")}
                      </TableCell>
                      <TableCell align="center">
                        {t("laboratory.doctor")}
                      </TableCell>
                      <TableCell align="center">
                        {t("laboratory.testType")}
                      </TableCell>
                      <TableCell align="center">
                        {t("laboratory.amount")}
                      </TableCell>
                      <TableCell align="center">
                        {t("laboratory.paymentStatus")}
                      </TableCell>
                      <TableCell align="center">
                        {t("laboratory.status")}
                      </TableCell>
                      <TableCell align="center">
                        {t("laboratory.action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" component="th" scope="row">
                          {formatTime(item.order_date)}
                        </TableCell>
                        <TableCell align="center">
                          {formatDate(item.order_date)}
                        </TableCell>
                        <TableCell align="center">
                          {item.patient_display?.name}
                        </TableCell>
                        <TableCell align="center">
                          {item.doctor_display?.username}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="view tests"
                            onClick={() =>
                              handleOpenTestsDialog(item.tests_display, item)
                            }
                            sx={{ color: "#007174" }}
                          >
                            <ListAltIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          {item.total_amount} ؋
                        </TableCell>
                        <TableCell align="center">
                          {item.is_paid ? (
                            <Typography color="green">
                              {t("laboratory.paid")}
                            </Typography>
                          ) : (
                            <Typography color="red">
                              {t("laboratory.unpaid")}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: getButtonColor(item.status),
                            }}
                          >
                            {LabTestStatus[item.status]}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {item.status === 1 ? (
                            <IconButton
                              id="demo-positioned-button"
                              aria-controls={
                                open ? "demo-positioned-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={(event) => handleClick(event, item)}
                              sx={{ color: "#007174" }}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          ) : (
                            <Typography>
                              {t("laboratory.noFurtherActions")}
                            </Typography>
                          )}
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            PaperProps={{
                              sx: {
                                boxShadow: "0px 20px 10px rgba(0, 0, 0, 0.02)",
                                border: "1px solid lightgray",
                                borderRadius: "1em",
                                padding: "10px",
                              },
                            }}
                          >
                            <Grid>
                              <IconButton
                                variant="none"
                                onClick={handleClose}
                                sx={{
                                  position: "absolute",
                                  top: "0.01em",
                                  right: "0.01em",
                                  color: "#a7e2f9",
                                }}
                              >
                                <CancelTwoToneIcon />
                              </IconButton>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: "#081c54",
                                  marginTop: "1em",
                                  marginLeft: "0.5em",
                                  fontSize: "27px",
                                }}
                              >
                                {t("laboratory.task")}
                              </Typography>
                              <Typography style={{ margin: "1em" }}>
                                {t("laboratory.status")}:{" "}
                                {LabTestStatus[item.status]}
                              </Typography>
                              {item.status === 1 && (
                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <MenuItem
                                    style={{
                                      ...buttonStyle,
                                      border: `2px solid ${getButtonColor(
                                        item.status
                                      )}`,
                                      color: getButtonColor(item.status),
                                    }}
                                    onClick={() => handleStatusChange(3)}
                                  >
                                    {t("laboratory.markAsCompleted")}
                                  </MenuItem>
                                  <MenuItem
                                    style={{
                                      ...buttonStyle,
                                      border: `2px solid ${getButtonColor(
                                        item.status
                                      )}`,
                                      color: getButtonColor(item.status),
                                    }}
                                    onClick={() => handleStatusChange(4)}
                                  >
                                    {t("laboratory.markAsCancelled")}
                                  </MenuItem>
                                </Box>
                              )}
                            </Grid>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Paper>
      )}

      {/* View Tests Dialog as a separate component */}
      <ViewTestsDialog
        isOpen={isTestsDialogOpen}
        onClose={handleCloseTestsDialog}
        tests={selectedOrderTests}
        doctorName={selectedTest?.doctor_display?.username}
        patientName={selectedTest?.patient_display?.name}
        patientId={selectedTest?.patient_display?.id}
        requestedDate={selectedTest?.requested_date}
        total_amount={selectedTest?.total_amount}
        handlePrint={handlePrint}
      />

      {/* Print Component */}
      {selectedOrderTests && (
        <div ref={printRef} style={{ display: "none" }}>
          <Print labTest={selectedTest} tests={selectedOrderTests} />
        </div>
      )}
    </Grid>
  );
};

export default TestsToBeDoneLaboratory;
