import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Chip,
  Menu,
  MenuItem,
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Card,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useUpdate from "../../../../api/useUpdate";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PrintIcon from "@mui/icons-material/Print";
import Print from "./Print";
import { useTranslation } from "react-i18next";

const EchoCollectFees = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEcho, setSelectedEcho] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPaidFilter, setIsPaidFilter] = useState("");
  const open = Boolean(anchorEl);
  const printRef = useRef();
  const [printData, setPrintData] = useState("");

  const { data, refetch, isLoading, isError } = useFetchObjects(
    ["EchoPaymentStatus", searchQuery, isPaidFilter],
    `patient-echo/?search=${searchQuery}&is_paid=${isPaidFilter}`,
    token
  );

  const { handleUpdate } = useUpdate("patient-echo", token);

  useEffect(() => {
    refetch();
  }, [searchQuery, isPaidFilter, refetch]);

  const handleMenuClick = (event, echo) => {
    setAnchorEl(event.currentTarget);
    setSelectedEcho(echo);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedEcho(null);
  };

  const handleMarkAsPaid = () => {
    const formData = new FormData();
    formData.append("is_paid", true);
    handleUpdate(selectedEcho.id, formData);
    handleClose();
    refetch();
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setIsPaidFilter("");
    } else if (newValue === 1) {
      setIsPaidFilter("false");
    } else if (newValue === 2) {
      setIsPaidFilter("true");
    }
  };

  const handlePrintClick = (echo) => {
    setPrintData(echo);
    setTimeout(() => {
      if (printRef.current) {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }, 500);
  };

  return (
    <Grid container mt={4} px={2}>
      <Grid item xs={12} md={12}>
        <Card
          variant="outlined"
          sx={{
            padding: "20px",
            boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
            borderRadius: "12px",
            mb: 4,
          }}
        >
          <Typography
            variant="h5"
            textAlign="center"
            fontWeight="bold"
            mb={2}
            color="primary"
          >
            {t("reception.echoPaymentStatus")}
          </Typography>

          {/* Search and Filter Area */}
          <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
            <TextField
              size="small"
              variant="outlined"
              placeholder={t("reception.searchByPatientEchoType")}
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                maxWidth: "400px",
                mb: 2,
                boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
                borderRadius: "8px",
              }}
            />

            <Tabs
              value={isPaidFilter === "" ? 0 : isPaidFilter === "false" ? 1 : 2}
              onChange={handleTabChange}
              aria-label="payment status tabs"
              centered
              sx={{
                maxWidth: "500px",
                width: "100%",
                "& .MuiTabs-flexContainer": {
                  justifyContent: "space-evenly",
                },
              }}
            >
              <Tab
                label={t("reception.all")}
                sx={{
                  fontWeight: "bold",
                  textTransform: "none",
                  fontSize: "1rem",
                  color: "text.primary",
                }}
              />
              <Tab
                label={t("reception.unpaid")}
                sx={{
                  fontWeight: "bold",
                  textTransform: "none",
                  fontSize: "1rem",
                  color: "text.primary",
                }}
              />
              <Tab
                label={t("reception.paid")}
                sx={{
                  fontWeight: "bold",
                  textTransform: "none",
                  fontSize: "1rem",
                  color: "text.primary",
                }}
              />
            </Tabs>
          </Box>
        </Card>

        {/* Table Section */}
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Box color="red" textAlign="center">
            {t("reception.errorF")}
          </Box>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
              borderRadius: "12px",
            }}
          >
            <Table aria-label="echo payment status table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", backgroundColor: "grey.200" }}
                  >
                    {t("reception.date")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", backgroundColor: "grey.200" }}
                  >
                    {t("reception.patientName")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", backgroundColor: "grey.200" }}
                  >
                    {t("reception.echoType")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", backgroundColor: "grey.200" }}
                  >
                    {t("reception.totalAmount")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", backgroundColor: "grey.200" }}
                  >
                    {t("reception.status")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", backgroundColor: "grey.200" }}
                  >
                    {t("reception.action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((echo) => (
                  <TableRow
                    key={echo.id}
                    sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                  >
                    <TableCell align="center">{echo.date}</TableCell>
                    <TableCell align="center">{echo.patient_display}</TableCell>
                    <TableCell align="center">
                      {echo.echo_type_display}
                    </TableCell>
                    <TableCell align="center">{echo.total_amount}</TableCell>
                    <TableCell align="center">
                      <Chip
                        label={
                          echo.is_paid
                            ? t("reception.paid")
                            : t("reception.pending")
                        }
                        color={echo.is_paid ? "success" : "warning"}
                        sx={{ fontWeight: "bold" }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {!echo.is_paid && (
                        <>
                          <Button
                            aria-controls={
                              open ? "payment-action-menu" : undefined
                            }
                            aria-haspopup="true"
                            onClick={(event) => handleMenuClick(event, echo)}
                            sx={{ minWidth: "auto", color: "#1976d2" }}
                          >
                            <MoreHorizIcon />
                          </Button>
                          <Menu
                            id="payment-action-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem onClick={handleMarkAsPaid}>
                              {t("reception.markPaid")}
                            </MenuItem>
                          </Menu>
                        </>
                      )}

                      <IconButton
                        color="primary"
                        onClick={() => handlePrintClick(echo)}
                      >
                        <PrintIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {printData && (
          <div ref={printRef} style={{ display: "none" }}>
            <Print echo={printData} />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default EchoCollectFees;
