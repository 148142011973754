import React from "react";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";

const Loading = () => {
  const renderSkeleton = () => {
    return (
      <TableBody>
        {[...Array(5)].map((_, index) => (
          <TableRow key={index}>
            <TableCell>
              <Skeleton variant="text" width={120} />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" width={80} />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" width={100} />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" width={150} height={36} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ boxShadow: "0px 0px 0px" }}>
        <Grid container padding={"10px"}>
          <TableContainer sx={{ mt: 2 }}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                <TableRow>
                  <TableCell>Position</TableCell>
                  <TableCell>Number of Employees</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {renderSkeleton()}
            </Table>
            <Grid container justifyContent={"center"} margin={"18px 0px"}>
              <Skeleton variant="rectangular" width={200} height={40} />
            </Grid>
          </TableContainer>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Loading;
