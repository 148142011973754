import { Grid } from "@mui/material";
import React from "react";
import PositionsList from "./PositionsList/PositionsList";
import AddPosition from "./AddPosition/AddPosition";

const PositionsIndex = () => {
  return (
    <Grid container maxWidth="xl" mb={3}>
      <Grid container gap={1} mt={2} justifyContent={"center"}>
        <Grid item xs={12} lg={8}>
          <Grid container>
            <PositionsList />
          </Grid>
        </Grid>
        <Grid item  xs={12} md={12} lg={3.7} bgcolor={"white"}>
          <AddPosition />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PositionsIndex;