import React from "react";
import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#a4de6c"];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        sx={{
          padding: "8px",
          borderRadius: "8px",
          backgroundColor: "#f5f5f5",
          boxShadow: 3,
          minWidth: "120px",
        }}
      >
        <Typography variant="subtitle2" color="textSecondary">
          {payload[0].name}
        </Typography>
        <Typography
          variant="body1"
          color="primary"
        >{`${payload[0].value.toLocaleString()}؋`}</Typography>
      </Paper>
    );
  }
  return null;
};

const OverallIncome = ({
  appointmentData = {},
  labData = {},
  radiologyData = {},
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const data = [
    {
      name: t("finance.appointments"),
      value: appointmentData.total_revenue_from_appointments || 0,
    },
    { name: t("finance.labRevenue"), value: labData.total_lab_revenue || 0 },
    { name: t("finance.xrayRevenue"), value: radiologyData.xray_revenue || 0 },
    {
      name: t("finance.ultRevenue"),
      value: radiologyData.ultrasound_revenue || 0,
    },
  ];

  return (
    <Grid container padding={"0px 5px"}>
      <Paper
        sx={{
          width: "100%",
          padding: 3,
          borderRadius: "8px",
          boxShadow: 4,
        }}
      >
        <Box textAlign={"center"} mt={1} mb={2}>
          <Typography variant="h5" fontWeight="bold" color="primary">
            {t("finance.incomeOverview")}
          </Typography>
        </Box>
        <ResponsiveContainer width="100%" height={isSmallScreen ? 250 : 300}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={isSmallScreen ? 50 : 70}
              outerRadius={isSmallScreen ? 90 : 120}
              paddingAngle={5}
              dataKey="value"
              label={({ name, percent }) =>
                `${name}: ${(percent * 100).toFixed(0)}%`
              }
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend
              iconSize={10}
              layout={isSmallScreen ? "horizontal" : "vertical"}
              verticalAlign={isSmallScreen ? "bottom" : "middle"}
              align="center"
              formatter={(value) => (
                <span style={{ color: "#555", fontWeight: "bold" }}>
                  {value}
                </span>
              )}
            />
          </PieChart>
        </ResponsiveContainer>
      </Paper>
    </Grid>
  );
};

export default OverallIncome;
