import React, { useState } from "react";
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Visibility, Delete, Edit } from "@mui/icons-material";
import useFetchObjects from "../../../../../api/useFetchObjects";
import useDelete from "../../../../../api/useDelete";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../context";
import EditCompanyMedicine from "../editCompanyMedicine/EditCompanyMedicine";

const ListCompanyMedicine = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data, refetch, isLoading, isError } = useFetchObjects(
    ["medicine-companies"],
    "medicine-companies",
    token
  );
  const { handleDelete, ConfirmDialog } = useDelete(
    "medicine-companies",
    token
  );

  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const [description, setDescription] = useState("");
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleClickOpenDescriptionDialog = (description) => {
    setDescription(description);
    setOpenDescriptionDialog(true);
  };

  const handleCloseDescriptionDialog = () => {
    setOpenDescriptionDialog(false);
    setDescription("");
  };

  const handleOpenEditDialog = (company) => {
    setSelectedCompany(company);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedCompany(null);
    refetch(); // Refresh the list after editing
  };

  return (
    <Grid container mt={3}>
      <Grid item xs={12} md={12}>
        <Paper elevation={3} sx={{ p: 3, borderRadius: 3 }}>
          <Typography variant="h5" fontWeight="bold" mb={2} color="primary">
            {t("pharmacy.companyList")}
          </Typography>
          <Divider sx={{ mb: 3 }} />

          {isLoading ? (
            <Grid container justifyContent="center" sx={{ mt: 4 }}>
              <CircularProgress />
            </Grid>
          ) : isError ? (
            <Typography color="error" textAlign="center">
              {t("pharmacy.failedToLoad")}
            </Typography>
          ) : (
            <TableContainer>
              <Table>
                <TableHead style={{ backgroundColor: "#f4f6f8" }}>
                  <TableRow>
                    <TableCell>{t("pharmacy.companyName")}</TableCell>
                    <TableCell>{t("pharmacy.phoneNumber")}</TableCell>
                    <TableCell>{t("pharmacy.address")}</TableCell>
                    <TableCell>{t("pharmacy.actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((company) => (
                    <TableRow key={company.id}>
                      <TableCell>{company.name}</TableCell>
                      <TableCell>{company.phone}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            handleClickOpenDescriptionDialog(company.address)
                          }
                        >
                          <Visibility />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenEditDialog(company)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(company.id)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <ConfirmDialog />

          {/* Description Dialog */}
          <Dialog
            open={openDescriptionDialog}
            onClose={handleCloseDescriptionDialog}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>{t("pharmacy.companyAddress")}</DialogTitle>
            <DialogContent>
              <Typography variant="body1">{description}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDescriptionDialog} color="primary">
                {t("pharmacy.close")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Edit Dialog */}
          {selectedCompany && (
            <EditCompanyMedicine
              open={openEditDialog}
              onClose={handleCloseEditDialog}
              company={selectedCompany}
              token={token}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ListCompanyMedicine;
