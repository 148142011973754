import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useDelete from "../../../../api/useDelete";
import SkeletonLoading from "./loading/SkeletonLoading";
import UpdateEchoTypeDialog from "./UpdateEchoTypeDialog";
import { useTranslation } from "react-i18next";

const ListEchoTypes = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const { data, isLoading, isError, error, refetch } = useFetchObjects(
    ["echo-types"],
    `echo-types/?search=${searchTerm}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [searchTerm]);

  const { handleDelete, ConfirmDialog } = useDelete("echo-types", token);

  const handleEdit = (type) => {
    setSelectedType(type);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedType(null);
  };
  const tableCellStyle = {
    fontWeight: "bold",
    textAlign: "center",
  };
  const tableStyle = {
    textAlign: "center",
  };

  return (
    <Paper
      sx={{
        padding: "30px 40px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <Typography variant="h5" gutterBottom>
        {t("echo.listEchoType")}
      </Typography>

      <TextField
        label={t("echo.search")}
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 4 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder={t("echo.searchByTypeNameOrDes")}
      />

      {isLoading && <SkeletonLoading />}
      {isError && <Typography color="error">Error: {error.message}</Typography>}

      {data && (
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead style={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                <TableCell style={tableCellStyle}>
                  {t("echo.typeName")}
                </TableCell>
                <TableCell style={tableCellStyle}>
                  {t("echo.description")}
                </TableCell>
                <TableCell style={tableCellStyle}>{t("echo.cost")}</TableCell>
                <TableCell style={tableCellStyle}>
                  {t("echo.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((type) => (
                <TableRow
                  key={type.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": { backgroundColor: "#f9f9f9" },
                    transition: "0.3s",
                  }}
                >
                  <TableCell style={tableStyle}>{type.name}</TableCell>
                  <TableCell style={tableStyle}>
                    {type.description || t("echo.noDescription")}
                  </TableCell>
                  <TableCell style={tableStyle}>{type.cost || "N/A"}</TableCell>
                  <TableCell style={tableStyle}>
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(type)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(type.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <ConfirmDialog />

      {!isLoading && data?.length === 0 && (
        <Typography variant="body1" color="textSecondary" mt={2}>
          {t("echo.noEchoTypeFound")}
        </Typography>
      )}

      {selectedType && (
        <UpdateEchoTypeDialog
          open={openDialog}
          onClose={handleCloseDialog}
          type={selectedType}
          token={token}
          refetch={refetch}
        />
      )}
    </Paper>
  );
};

export default ListEchoTypes;
