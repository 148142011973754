import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EchoIcon from "@mui/icons-material/RadioButtonUnchecked"; // Representing Echo icon
import PendingIcon from "@mui/icons-material/HourglassEmpty"; // Representing Pending icon
import SkeletonLoading from "./loading/SkeletonLoading";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";

const gridItemCenterStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#007174",
  padding: "25px 5px",
  borderRadius: "10px",
  color: "white",
};

const EchoActivityOverview = () => {
  const { t } = useTranslation();
  const [option, setOption] = useState("daily");
  const auth = useAuth();
  const token = auth?.user?.token;

  // Fetch data based on the selected option
  const { data, isLoading, isError, error } = useFetchObjects(
    ["echo-reports", option],
    `echo-reports/?report_type=${option}`,
    token
  );

  const handleChangeOption = (event) => {
    setOption(event.target.value || "daily");
  };

  return (
    <Paper sx={{ boxShadow: "0px 0px 0px", width: "100%" }}>
      <Grid container padding={"20px"}>
        <Grid
          item
          xs={11}
          md={11}
          display={"flex"}
          margin={"0px auto"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Typography>{t("overview.echoOverview")}</Typography>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="report-type-select-label">
                {t("overview.option")}
              </InputLabel>
              <Select
                labelId="report-type-select-label"
                id="report-type-select"
                value={option}
                onChange={handleChangeOption}
                label={t("overview.option")}
              >
                <MenuItem value="daily">{t("overview.daily")}</MenuItem>
                <MenuItem value="weekly">{t("overview.weekly")}</MenuItem>
                <MenuItem value="monthly">{t("overview.monthly")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {isLoading ? (
          <SkeletonLoading /> // Show skeleton loading while data is being fetched
        ) : (
          <Grid container gap={3} mt={2} justifyContent={"center"}>
            {/* Total Echo Tests */}
            <Grid item xs={12} sm={5} lg={5} sx={gridItemCenterStyle}>
              <EchoIcon fontSize="large" />
              {isError ? (
                <Typography>
                  {t("overview.error")}: {error.message}
                </Typography>
              ) : (
                <>
                  <Typography
                    textAlign={"center"}
                    sx={{ fontWeight: "bold", margin: "5px 0px" }}
                  >
                    {data?.total_tests ?? 0}
                  </Typography>
                  <Typography textAlign={"center"}>
                    {t("overview.totalTests")}
                  </Typography>
                </>
              )}
            </Grid>

            {/* Pending Echo Tests */}
            <Grid item xs={12} sm={5} lg={5} sx={gridItemCenterStyle}>
              <PendingIcon fontSize="large" />
              {isError ? (
                <Typography>
                  {t("overview.error")}: {error.message}
                </Typography>
              ) : (
                <>
                  <Typography
                    textAlign={"center"}
                    sx={{ fontWeight: "bold", margin: "5px 0px" }}
                  >
                    {data?.pending_tests ?? 0}
                  </Typography>
                  <Typography textAlign={"center"}>
                    {t("overview.pendingTests")}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default EchoActivityOverview;
