import {
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card,
  TextField,
  IconButton,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DatePicker from "react-datepicker";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import "react-datepicker/dist/react-datepicker.css";
import useDelete from "../../../../api/useDelete";
import EditExpenseDialog from "./EditExpenseDialog"; // Import the dialog
import { useTranslation } from "react-i18next";

const ExpensesList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [selectedExpenseType, setSelectedExpenseType] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);

  // Fetch expense types for filtering
  const { data: expenseTypes } = useFetchObjects(
    ["expense-types"],
    "expense-types/",
    token
  );
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["expenses"],
    `expenses/?${
      selectedExpenseType ? `expenseType=${selectedExpenseType}&` : ""
    }${startDate ? `date_from=${startDate.toISOString().split("T")[0]}&` : ""}${
      endDate ? `date_to=${endDate.toISOString().split("T")[0]}` : ""
    }`,
    token
  );

  const { handleDelete, ConfirmDialog } = useDelete("expenses", token);

  useEffect(() => {
    if (data?.results) {
      setFilteredExpenses(data.results);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [selectedExpenseType, startDate, endDate, refetch]);

  const handleExpenseTypeChange = (event) => {
    setSelectedExpenseType(event.target.value);
  };

  const totalExpenseAmount = filteredExpenses.reduce(
    (acc, expense) => acc + expense.amount,
    0
  );

  const openEditDialog = (expense) => {
    setSelectedExpense(expense);
    setEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setSelectedExpense(null);
  };

  return (
    <Grid container justifyContent={"center"} mb={4} pb={5}>
      <Grid item xs={12} md={10}>
        {/* Filter Section */}
        <Card
          sx={{
            padding: "20px",
            marginBottom: "20px",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            mb={2}
            display="flex"
            alignItems="center"
          >
            <FilterAltIcon sx={{ mr: 1 }} /> {t("finance.filterExpenses")}
          </Typography>

          <Grid container spacing={2} alignItems="center">
            {/* Expense Type Filter */}
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>{t("finance.expenseType")}</InputLabel>
                <Select
                  value={selectedExpenseType}
                  onChange={handleExpenseTypeChange}
                  label="Expense Type"
                >
                  <MenuItem value="">{t("finance.all")}</MenuItem>
                  {expenseTypes?.results?.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Date Pickers */}
            <Grid item xs={6} md={4}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText={t("finance.startDate")}
                dateFormat="yyyy-MM-dd"
                customInput={
                  <TextField
                    variant="outlined"
                    fullWidth
                    sx={{ height: "50px", fontSize: "1rem" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText={t("finance.endDate")}
                dateFormat="yyyy-MM-dd"
                customInput={
                  <TextField
                    variant="outlined"
                    fullWidth
                    sx={{ height: "50px", fontSize: "1rem" }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Card>

        {/* Expense Table */}
        {isLoading ? (
          <Box>{t("finance.loading")}</Box>
        ) : isError ? (
          <Box color="red">{t("finance.errorFetchingData")}</Box>
        ) : (
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)" }}
          >
            <Table aria-label="expenses table">
              <TableHead sx={{ backgroundColor: "#f2f2f2" }}>
                <TableRow>
                  <TableCell align="center">{t("finance.time")}</TableCell>
                  <TableCell align="center">{t("finance.date")}</TableCell>
                  <TableCell align="center">{t("finance.expenseType")} </TableCell>
                  <TableCell align="center">{t("finance.amount")}</TableCell>
                  <TableCell align="center">{t("finance.description")}</TableCell>
                  <TableCell align="center">{t("finance.actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredExpenses.map((expense) => (
                  <TableRow
                    key={expense.id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
                      "&:hover": { backgroundColor: "#eaeaea" },
                    }}
                  >
                    <TableCell align="center">{expense.time}</TableCell>
                    <TableCell align="center">{expense.date}</TableCell>
                    <TableCell align="center">
                      {expense.expenseType_display}
                    </TableCell>
                    <TableCell align="center">{expense.amount}</TableCell>
                    <TableCell align="center">{expense.description}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => openEditDialog(expense)}
                        color="primary"
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(expense.id)}
                        color="secondary"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {/* Total Expense Row */}
                <TableRow>
                  <TableCell colSpan={4} />
                  <TableCell align="right" colSpan={2}>
                    <Typography variant="subtitle1" fontWeight="bold">
                    {t("finance.total")}: {totalExpenseAmount.toFixed(2)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>

      <ConfirmDialog />

      {/* Edit Expense Dialog */}
      {selectedExpense && (
        <EditExpenseDialog
          open={editDialogOpen}
          onClose={closeEditDialog}
          expense={selectedExpense}
        />
      )}
    </Grid>
  );
};

export default ExpensesList;
