import React from "react";
import {
  Container,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Box,
} from "@mui/material";

const StockMedicineSkeleton = () => (
  <Container>
    <Paper
      sx={{
        boxShadow: "0px 0px 16px rgba(0,0,0,0.1)",
        borderRadius: "12px",
        marginTop: "2rem",
        padding: { xs: "1.5rem", md: "2.5rem" },
      }}
    >
      <Typography variant="h4" color="primary" gutterBottom>
        <Skeleton width={200} />
      </Typography>
      <TableContainer component={Box} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              {[...Array(4)].map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant="text" width={100} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(5)].map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {[...Array(4)].map((_, cellIndex) => (
                  <TableCell key={cellIndex}>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  </Container>
);

export { StockMedicineSkeleton };
