import { useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../context";
import { getObjects } from "./Api";

export const useFetchData = () => {
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const [doctorData, setDoctorData] = useState([]);
  const [bloodTypeData, setBloodTypeData] = useState([]);
  const [expenseTypeData, setExpenseTypeData] = useState([]);

  const { data: getDoctorData, isSuccess: doctorSuccess } = useQuery({
    queryKey: ["doctor-data"],
    queryFn: () => getObjects("users/", token),
  });

  const { data: getBloodTypeData, isSuccess: bloodTypeSuccess } = useQuery({
    queryKey: ["blood-types"],
    queryFn: () => getObjects("blood-types/", token),
  });

  const { data: getExpenseTypeData, isSuccess: expenseTypeSuccess } = useQuery({
    queryKey: ["expense-types"],
    queryFn: () => getObjects("expense-types/", token),
  });

  useEffect(() => {
    if (doctorSuccess) {
      setDoctorData(getDoctorData);
    }
  }, [getDoctorData, doctorSuccess]);

  useEffect(() => {
    if (bloodTypeSuccess) {
      setBloodTypeData(getBloodTypeData.results);
    }
  }, [getBloodTypeData, bloodTypeSuccess]);

  useEffect(() => {
    if (expenseTypeSuccess) {
      setExpenseTypeData(getExpenseTypeData.results);
    }
  }, [getExpenseTypeData, expenseTypeSuccess]);

  return { doctorData, bloodTypeData, expenseTypeData };
};
