import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";

const PatientSearch = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const auth = useAuth();
  const token = auth?.user?.token;

  // Use the custom hook to fetch the patient data
  const { data, isLoading, isSuccess, error, isError } = useFetchObjects(
    searchQuery ? ["search-patient", searchQuery] : null,
    searchQuery ? `patients/?search=${searchQuery}` : "",
    token
  );

  const handleSearch = () => {
    if (searchQuery && isSuccess && data) {
      navigate(`/patient-profile/profile`, { state: data });
    }
  };

  return (
    <Container>
      <Box
        sx={{
          p: 3,
          backgroundColor: "#fff",
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: "600px",
          margin: "auto",
          mt: 5, // Add margin-top for spacing
        }}
      >
        <Typography variant="h6" gutterBottom align="center">
          {t("profile.searchForPatient")}
        </Typography>

        {/* Loading Spinner */}
        {isLoading && (
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <CircularProgress />
          </Box>
        )}

        {/* Error Handling */}
        {isError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error?.message || t("profile.anError")}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("profile.enterPatientNameId")}
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSearch}
              disabled={!searchQuery || isLoading} // Disable button during loading or if search query is empty
            >
              {isLoading ? t("profile.Searching") : t("profile.searchPatient")}
            </Button>
          </Grid>
        </Grid>

        {/* Success - No Data */}
        {isSuccess && !data?.results?.length && (
          <Typography
            variant="body1"
            color="textSecondary"
            align="center"
            sx={{ mt: 2 }}
          >
            {t("profile.noPatient")}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default PatientSearch;
