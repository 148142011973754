import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";
const UpdateEchoTypeDialog = ({ open, onClose, type, token, refetch }) => {
  const {t}=useTranslation();
  const [formData, setFormData] = useState({
    name: type.name,
    description: type.description,
    cost: type.cost,
  });

  const { handleUpdate, loading } = useUpdate("echo-types", token);

  const handleSubmit = () => {
    handleUpdate(type.id, formData);
    refetch();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("echo.updateEchoType")}</DialogTitle>
      <DialogContent>
        <TextField
          label={t("echo.echoTypeName")}
          fullWidth
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          sx={{ mb: 3, mt: 2 }}
        />
        <TextField
          label={t("echo.description")}
          fullWidth
          multiline
          rows={4}
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          sx={{ mb: 3 }}
        />
        <TextField
          label={t("echo.cost")}
          fullWidth
          type="number"
          value={formData.cost}
          onChange={(e) => setFormData({ ...formData, cost: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
        {t("echo.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading}
          sx={{ textTransform: "none", fontWeight: "bold",margin:"0px 10px" }}
        >
          {loading ? t("echo.updating") : t("echo.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateEchoTypeDialog;
