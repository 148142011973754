// src/components/NewPatient/NewPatient.js
import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Paper,
  FormControl,
  Select,
  MenuItem,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Gender } from "../../../../utils";
import { useFetchData } from "../../../../api/api/FetchData";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";
import AddBloodTypeDialog from "./AddBloodTypeDialog";
import useAdd from "../../../../api/useAdd";

const buttonStyle = {
  textTransform: "capitalize",
  backgroundColor: "#071952",
};

const NewPatient = () => {
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const { bloodTypeData } = useFetchData();
  const [openBloodTypeDialog, setOpenBloodTypeDialog] = useState(false);

  const handleOpenBloodTypeDialog = () => setOpenBloodTypeDialog(true);
  const handleCloseBloodTypeDialog = () => setOpenBloodTypeDialog(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    age: "",
    blood_type: "",
    phone: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    if (!formData.firstName) {
      newErrors.firstName = t("reception.firstNameRequired");
      isValid = false;
    }
    if (!formData.age || formData.age <= 0) {
      newErrors.age = t("reception.ageRequired");
      isValid = false;
    }
    if (!formData.gender) {
      newErrors.gender = t("reception.genderRequired");
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const patientData = {
        firstname: formData.firstName,
        lastname: formData.lastName || null,
        age: formData.age,
        gender: formData.gender,
        blood_type: formData.blood_type || null,
        phone: formData.phone,
      };

      handleAdd(patientData);
    }
  };

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const { handleAdd, resetForm } = useAdd("patients", token);

  useEffect(() => {
    if (resetForm) {
      setFormData({
        firstName: "",
        lastName: "",
        gender: "",
        age: "",
        blood_type: "",
        phone: "",
      });
    }
  }, [resetForm]);

  const direction = i18n.language === "fa" ? "rtl" : "ltr";

  return (
    <Grid container maxWidth={"xl"} mt={3} mb={5}>
      <Grid item xs={12} md={10} margin={"0px auto"}>
        <Paper sx={{ boxShadow: "0px 0px 0px", width: "100%" }}>
          <Box component="form" onSubmit={handleSubmit} noValidate padding="20px">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <FormLabel>{t("reception.firstName")}</FormLabel>
                <TextField
                  id="outlined-required"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  size="small"
                  fullWidth
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormLabel>{t("reception.lastName")}</FormLabel>
                <TextField
                  id="outlined-required"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormLabel>{t("reception.phone")}</FormLabel>
                <TextField
                  type="text"
                  id="outlined-required"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormLabel>{t("reception.age")}</FormLabel>
                <TextField
                  type="number"
                  id="outlined-required"
                  name="age"
                  value={formData.age}
                  onChange={handleInputChange}
                  size="small"
                  fullWidth
                  error={!!errors.age}
                  helperText={errors.age}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl
                  size="small"
                  fullWidth
                  error={!!errors.gender}
                  required
                >
                  <FormLabel>{t("reception.gender")}</FormLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                  >
                    {Gender.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.gender && (
                    <FormHelperText>{errors.gender}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl size="small" fullWidth>
                  <FormLabel>{t("reception.blood_type")}</FormLabel>
                  <Select
                    labelId="blood-type-select-label"
                    id="blood-type-select"
                    name="blood_type"
                    value={formData.blood_type}
                    onChange={handleInputChange}
                  >
                    <MenuItem onClick={handleOpenBloodTypeDialog}>
                      {t("reception.addNew")}
                    </MenuItem>
                    {bloodTypeData.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    style={buttonStyle}
                  >
                    {t("reception.submit")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <AddBloodTypeDialog
            open={openBloodTypeDialog}
            onClose={handleCloseBloodTypeDialog}
            token={token}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default NewPatient;
