import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PrintIcon from "@mui/icons-material/Print";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useUpdate from "../../../../api/useUpdate";
import Print from "./Print";

const UltrasoundCollectFees = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUltrasound, setSelectedUltrasound] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPaidFilter, setIsPaidFilter] = useState("");
  const open = Boolean(anchorEl);
  const printRef = useRef();
  const [printData, setPrintData] = useState("");

  const { data, refetch, isLoading, isError } = useFetchObjects(
    ["UltrasoundPaymentStatus", searchQuery, isPaidFilter],
    `ultrasounds/?search=${searchQuery}&is_paid=${isPaidFilter}`,
    token
  );

  const { handleUpdate } = useUpdate("ultrasounds", token);

  useEffect(() => {
    refetch();
  }, [searchQuery, isPaidFilter, refetch]);

  const handleMenuClick = (event, ultrasound) => {
    setAnchorEl(event.currentTarget);
    setSelectedUltrasound(ultrasound);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUltrasound(null);
  };

  const handleMarkAsPaid = () => {
    const formData = new FormData();
    formData.append("is_paid", true);
    handleUpdate(selectedUltrasound.id, formData);
    handleClose();
    refetch();
  };

  const handlePrintClick = (ultrasound) => {
    setPrintData(ultrasound);
    setTimeout(() => {
      if (printRef.current) {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }, 500);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setIsPaidFilter(newValue === 0 ? "" : newValue === 1 ? "false" : "true");
  };

  return (
    <Grid container spacing={3} mt={4} px={2}>
      <Grid item xs={12}>
        <Card
          elevation={3}
          sx={{
            p: 3,
            borderRadius: "16px",
            boxShadow: "0px 6px 18px rgba(0,0,0,0.12)",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            fontWeight="bold"
            color="primary"
            mb={3}
          >
            {t("reception.ultPaymentStatus")}
          </Typography>

          {/* Search and Filters */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
            mb={3}
          >
            <TextField
              size="small"
              variant="outlined"
              placeholder={t("reception.searchPatientUltType")}
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                maxWidth: "400px",
                boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                borderRadius: "8px",
              }}
            />
            <Tabs
              value={isPaidFilter === "" ? 0 : isPaidFilter === "false" ? 1 : 2}
              onChange={handleTabChange}
              centered
              sx={{
                "& .MuiTab-root": {
                  fontWeight: "bold",
                  textTransform: "none",
                  fontSize: "1rem",
                },
              }}
            >
              <Tab label={t("reception.all")} />
              <Tab label={t("reception.unpaid")} />
              <Tab label={t("reception.paid")} />
            </Tabs>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={12}>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Box textAlign="center" color="error.main">
            {t("reception.errorF")}
          </Box>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "16px",
              boxShadow: "0px 6px 18px rgba(0,0,0,0.1)",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    t("reception.date"),
                    t("reception.patient"),
                    t("reception.ultType"),
                    t("reception.totalAmount"),
                    t("reception.status"),
                    t("reception.action"),
                  ].map((header) => (
                    <TableCell
                      key={header}
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        backgroundColor: "grey.200",
                        fontSize: "1rem",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((ultrasound) => (
                  <TableRow
                    key={ultrasound.id}
                    sx={{ "&:hover": { backgroundColor: "grey.100" } }}
                  >
                    <TableCell align="center">{ultrasound.date}</TableCell>
                    <TableCell align="center">
                      {ultrasound.patient_display}
                    </TableCell>
                    <TableCell align="center">
                      {ultrasound.ultrasound_type_display}
                    </TableCell>
                    <TableCell align="center">
                      {ultrasound.total_amount}
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                        label={
                          ultrasound.is_paid
                            ? t("reception.paid")
                            : t("reception.pending")
                        }
                        color={ultrasound.is_paid ? "success" : "warning"}
                        sx={{ fontWeight: "bold" }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {!ultrasound.is_paid && (
                        <>
                          <Button
                            aria-controls={
                              open ? "payment-action-menu" : undefined
                            }
                            aria-haspopup="true"
                            onClick={(event) =>
                              handleMenuClick(event, ultrasound)
                            }
                          >
                            <MoreHorizIcon />
                          </Button>
                          <Menu
                            id="payment-action-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={handleMarkAsPaid}>
                              {t("reception.markPaid")}
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                      <IconButton
                        color="primary"
                        onClick={() => handlePrintClick(ultrasound)}
                      >
                        <PrintIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>

      {printData && (
        <div ref={printRef} style={{ display: "none" }}>
          <Print ultrasound={printData} />
        </div>
      )}
    </Grid>
  );
};

export default UltrasoundCollectFees;
