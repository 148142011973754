import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Autocomplete,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
  Stack,
} from "@mui/material";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import { useTranslation } from "react-i18next";
import ReturnMedicineList from "./ReturnMedicineList";

const ReturnMedicine = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacy = auth?.user?.user?.pharmacy_type;

  const { data: prescriptions, isLoading: loadingPrescriptions } =
    useFetchObjects(
      ["prescriptions"],
      `prescriptions?drugs__stock_medicine__pharmacy=${pharmacy}`,
      token
    );

  const { data: orders, isLoading: loadingOrders } = useFetchObjects(
    ["medicine-orders"],
    `medicine-orders?items__stock_medicine__pharmacy=${pharmacy}`,
    token
  );

  const { handleAdd: handleAddReturn, loading: submitting } = useAdd(
    "medicine-returns",
    token,
    t("pharmacy.returnSuccess"),
    t("pharmacy.returnFail")
  );

  const [returnOption, setReturnOption] = useState("");
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState("");

  useEffect(() => {
    if (selectedPrescription) {
      setItems(selectedPrescription.drugs);
    } else if (selectedOrder) {
      setItems(selectedOrder.items);
    } else {
      setItems([]);
    }
  }, [selectedPrescription, selectedOrder]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedItem || !quantity || quantity <= 0) {
      return;
    }

    const payload = {
      stock_medicine: selectedItem.stock_medicine,
      quantity: parseInt(quantity, 10),
      prescription_detail:
        returnOption === "prescription" ? selectedItem.id : null,
      medicine_order_item: returnOption === "order" ? selectedItem.id : null,
    };

    handleAddReturn(payload);

    if (!submitting) {
      setSelectedPrescription(null);
      setSelectedOrder(null);
      setSelectedItem(null);
      setQuantity("");
    }
  };

  return (
    <Card
      sx={{
        boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
        backgroundColor: "#f4f6f8",
        width: "100%",
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Typography
          variant="h5"
          fontWeight={600}
          color="primary"
          textAlign="center"
          gutterBottom
        >
          {t("pharmacy.returnMedicineTitle")}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Box mb={4}>
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            {t("pharmacy.selectReturnOption")}
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="return-option-label">
              {t("pharmacy.returnOption")}
            </InputLabel>
            <Select
              labelId="return-option-label"
              value={returnOption}
              onChange={(e) => setReturnOption(e.target.value)}
              label={t("pharmacy.returnOption")}
            >
              <MenuItem value="prescription">
                {t("pharmacy.prescription")}
              </MenuItem>
              <MenuItem value="order">{t("pharmacy.directOrder")}</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {returnOption === "prescription" && !loadingPrescriptions && (
          <Box mb={4}>
            <Typography variant="subtitle1" fontWeight={500} mb={2}>
              {t("pharmacy.selectPrescription")}
            </Typography>
            <Autocomplete
              options={prescriptions || []}
              getOptionLabel={(option) =>
                `Prescription #${option.id} - ${option.patient_display} (${option.patient_age_display} years)`
              }
              value={selectedPrescription}
              onChange={(event, newValue) => setSelectedPrescription(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("pharmacy.prescription")}
                  variant="outlined"
                />
              )}
              sx={{ backgroundColor: "#fff", borderRadius: 2 }}
            />
          </Box>
        )}

        {returnOption === "order" && !loadingOrders && (
          <Box mb={4}>
            <Typography variant="subtitle1" fontWeight={500} mb={2}>
              {t("pharmacy.selectOrder")}
            </Typography>
            <Autocomplete
              options={orders || []}
              getOptionLabel={(option) =>
                `Order #${option.id} - Total: ${option.total_price}؋`
              }
              value={selectedOrder}
              onChange={(event, newValue) => setSelectedOrder(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("pharmacy.order")}
                  variant="outlined"
                />
              )}
              sx={{ backgroundColor: "#fff", borderRadius: 2 }}
            />
          </Box>
        )}

        {(selectedPrescription || selectedOrder) && (
          <Box mb={4}>
            <Typography variant="subtitle1" fontWeight={500} mb={2}>
              {t("pharmacy.selectItem")}
            </Typography>
            <Autocomplete
              options={items}
              getOptionLabel={(option) =>
                option.stock_medicine_display
                  ? `${option.stock_medicine_display} - Quantity: ${option.quantity}`
                  : `Item ID: ${option.id}`
              }
              value={selectedItem}
              onChange={(event, newValue) => setSelectedItem(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("pharmacy.item")}
                  variant="outlined"
                />
              )}
              sx={{ backgroundColor: "#fff", borderRadius: 2 }}
            />
          </Box>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label={t("pharmacy.quantity")}
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              fullWidth
              variant="outlined"
              required
              sx={{ backgroundColor: "#fff", borderRadius: 2 }}
            />
          </Grid>
        </Grid>

        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 4 }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={submitting}
            sx={{
              fontWeight: 700,
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
          >
            {submitting ? t("pharmacy.submitting") : t("pharmacy.submitReturn")}
          </Button>
        </Stack>

        {(loadingPrescriptions || loadingOrders) && (
          <CircularProgress sx={{ display: "block", mt: 4, mx: "auto" }} />
        )}
      </CardContent>
      <Box mt={3}>
        <Divider />
        <ReturnMedicineList />
      </Box>
    </Card>
  );
};

export default ReturnMedicine;
