// src/components/AddMedicine/AddCategoryDialog.js
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import useAdd from "../../../../../api/useAdd";
import { useTranslation } from "react-i18next";

const AddCategoryDialog = ({ open, onClose, token }) => {
  const {t}=useTranslation();
  const [categoryName, setCategoryName] = useState("");
  const { handleAdd, loading, resetForm } = useAdd(
    "medicine-categories",
    token
  );

  useEffect(() => {
    if (resetForm) {
      setCategoryName("");
      onClose();
    }
  }, [resetForm]);

  const handleAddCategory = () => {
    handleAdd({ name: categoryName });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("pharmacy.addNewCategory")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("pharmacy.categoryName")}
          type="text"
          fullWidth
          variant="outlined"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
        {t("pharmacy.cancel")}
        </Button>
        <Button
          onClick={handleAddCategory}
          color="primary"
          disabled={loading || !categoryName}
          startIcon={loading && <CircularProgress size={20} />}
          sx={{margin:"0px 10px"}}
        >
          {t("pharmacy.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCategoryDialog;
