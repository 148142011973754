import React from "react";
import { Box, Typography, Button, Container, Grid } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";

function ErrorPage({
  errorMessage = "The page you're looking for couldn't be found.",
  redirectPath = -1,
}) {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };
  const handleGoBack = () => {
    navigate(redirectPath); // Navigate back to the previous page
  };

  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={11} md={7} mt={2}>
        <Container maxWidth="sm">
          <Box
            sx={{
              textAlign: "center",
              backgroundColor: "#ffffff",
              borderRadius: 4,
              boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
              padding: 5,
              position: "relative",
            }}
          >
            <ErrorOutlineIcon
              sx={{
                fontSize: 100,
                color: "#d32f2f",
                marginBottom: 3,
                animation: "pulse 2s infinite",
                "@keyframes pulse": {
                  "0%": { transform: "scale(1.5)" },
                  "50%": { transform: "scale(1.07)" },
                  "100%": { transform: "scale(1.5)" },
                },
              }}
            />
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              color="#d32f2f"
            >
              Oops! Something went wrong
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              {errorMessage}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                marginTop: 3,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoHome}
                sx={{
                  textTransform: "none",
                  padding: "10px 20px",
                  borderRadius: 2,
                  fontSize: 16,
                }}
              >
                Go to Homepage
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
                sx={{
                  textTransform: "none",
                  padding: "10px 20px",
                  borderRadius: 2,
                  fontSize: 16,
                }}
              >
                Go Back
              </Button>
            </Box>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
}

export default ErrorPage;
