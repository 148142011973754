import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AdvanceList from "./AdvanceList";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import { Months, years } from "../../../../utils";
import { useTranslation } from "react-i18next";

const Advance = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const { data: employeeData } = useFetchObjects(["users"], "users", token);

  // Get the current date, month, and year
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // JavaScript months are zero-indexed
  const currentYear = currentDate.getFullYear();

  const [input, setInput] = useState({
    employee: "",
    amount: "",
    month: currentMonth,
    year: currentYear,
  });

  const [formErrors, setFormErrors] = useState({});

  const { handleAdd, resetForm, loading } = useAdd(
    "employee-advance",
    token,
    t("hr.advanceCreated"),
    t("hr.operationFailed")
  );

  useEffect(() => {
    if (resetForm) {
      setInput({
        employee: "",
        amount: "",
        month: "",
        year: "",
      });
      setFormErrors({});
    }
  }, [resetForm]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let isValid = true;
    let errors = {};

    if (!input.employee) {
      errors.employee = t("hr.employeeRequired");
      isValid = false;
    } else {
      errors.employee = "";
      isValid = true;
    }

    if (!input.amount) {
      errors.amount = t("hr.amountRequired");
      isValid = false;
    } else {
      errors.amount = "";
      isValid = true;
    }

    if (!input.month) {
      errors.month = t("hr.monthRequired");
      isValid = false;
    } else {
      errors.month = "";
      isValid = true;
    }

    if (!input.year) {
      errors.year = t("hr.yearRequired");
      isValid = false;
    } else {
      errors.year = "";
      isValid = true;
    }
    setFormErrors(errors);

    if (isValid) {
      const formData = new FormData();
      formData.append("employee", input.employee);
      formData.append("amount", input.amount);
      formData.append("month", input.month);
      formData.append("year", input.year);
      handleAdd(formData);
    }
  };

  return (
    <Container>
      <Paper
        component="form"
        onSubmit={handleSave}
        sx={{
          boxShadow: "0px 0px 0px",
          marginTop: "2rem",
          marginBottom: "2rem",
          padding: "1rem",
        }}
      >
        <Typography fontWeight={500} variant="h4" color="primary">
          {t("hr.advance1")}
        </Typography>

        <Divider sx={{ mt: 2, mb: 2, width: "100%" }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              options={employeeData}
              getOptionLabel={(option) =>
                `${option?.first_name} ${option?.last_name}`
              }
              onChange={(event, value) => {
                setInput({ ...input, employee: value ? value.id : "" });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  fullWidth
                  label={t("hr.selectEmployee")}
                  error={!!formErrors.employee}
                  helperText={formErrors.employee}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              select
              size="small"
              name="month"
              variant="outlined"
              label={t("hr.month")}
              value={input.month}
              onChange={handleChange}
              error={!!formErrors.month}
              helperText={formErrors.month}
            >
              {Months.map((monthOption) => (
                <MenuItem key={monthOption.id} value={monthOption.id}>
                  {monthOption.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              select
              fullWidth
              label={t("hr.year")}
              size="small"
              name="year"
              variant="outlined"
              value={input.year}
              onChange={handleChange}
              error={!!formErrors.year}
              helperText={formErrors.year}
            >
              {years.map((yearOption) => (
                <MenuItem key={yearOption} value={yearOption}>
                  {yearOption}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              size="small"
              label={t("hr.amount")}
              variant="outlined"
              name="amount"
              value={input.amount}
              onChange={handleChange}
              error={!!formErrors.amount}
              helperText={formErrors.amount}
              sx={{ backgroundColor: "#fff" }}
            />
          </Grid>
        </Grid>
        <br />
        <Stack direction="row" justifyContent="space-between">
          <Box></Box>
          <Button variant="contained" type="submit">
            {t("hr.apply")}
          </Button>
        </Stack>
        <Divider sx={{ mt: 2, width: "100%" }} />
        <AdvanceList />
      </Paper>
    </Container>
  );
};

export default Advance;
