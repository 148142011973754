import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
  Divider,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../context";
import useAdd from "../../../../api/useAdd";
import useFetchObjects from "../../../../api/useFetchObjects";
import EchoCollectFees from "./EchoCollectFees";

const FullWidthPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#ffffff",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  color: "#3a3a3a",
  textAlign: "left",
  marginBottom: theme.spacing(4),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.shape.borderRadius,
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: theme.palette.primary.main,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  fontWeight: 700,
  fontSize: "1rem",
  padding: "12px 16px",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const EchoReferral = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data: echoTypesData } = useFetchObjects(
    ["echo-types"],
    "echo-types/",
    token
  );
  const { data: patientsData } = useFetchObjects(
    ["patients"],
    "patients/",
    token
  );

  const { handleAdd, resetForm } = useAdd("patient-echo", token);

  const echoTypes = echoTypesData || [];
  const patients = patientsData?.results || [];

  const getCurrentDate = () => new Date().toISOString().split("T")[0];

  const [echoDetails, setEchoDetails] = useState({
    echoType: "",
    testDate: getCurrentDate(),
    isUrgent: false,
    totalAmount: "",
    patient: null,
    doctor: auth?.user?.user?.id,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (resetForm) {
      setEchoDetails({
        echoType: "",
        testDate: getCurrentDate(),
        isUrgent: false,
        totalAmount: "",
        patient: null,
        doctor: auth?.user?.user?.id,
      });
      setErrors({});
    }
  }, [resetForm]);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setEchoDetails((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "echoType") {
      const selectedType = echoTypes.find(
        (type) => type.id.toString() === value
      );
      setEchoDetails((prev) => ({
        ...prev,
        totalAmount: selectedType ? selectedType.cost.toString() : "",
      }));
    }

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!echoDetails.patient) {
      tempErrors.patient = t("doctor.patientRequired");
    }
    if (!echoDetails.echoType) {
      tempErrors.echoType = t("doctor.echoTypeRequired");
    }
    if (!echoDetails.testDate) {
      tempErrors.testDate = t("doctor.testDateRequired");
    }
    if (!echoDetails.totalAmount) {
      tempErrors.totalAmount = t("doctor.totalAmountRequired");
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const payload = {
        patient: echoDetails.patient.id,
        doctor: echoDetails.doctor,
        echo_type: echoDetails.echoType,
        date: echoDetails.testDate,
        is_urgent: echoDetails.isUrgent,
        total_amount: echoDetails.totalAmount,
      };
      handleAdd(payload);
    }
  };

  return (
    <FullWidthPaper>
      <SectionTitle>{t("doctor.echoReferralForm")}</SectionTitle>

      <Grid container spacing={3}>
        {/* Patient Autocomplete */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={patients}
            getOptionLabel={(option) =>
              option
                ? `${option.id} - ${option.firstname} ${option.lastname}`
                : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("doctor.selectPatient")}
                variant="outlined"
                fullWidth
                error={!!errors.patient}
                helperText={errors.patient}
              />
            )}
            value={echoDetails.patient}
            onChange={(event, newValue) => {
              setEchoDetails((prev) => ({ ...prev, patient: newValue }));
              setErrors((prev) => ({ ...prev, patient: "" }));
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                (option) =>
                  option.id.toString().includes(inputValue) ||
                  option.firstname
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()) ||
                  option.lastname
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
              )
            }
          />
        </Grid>

        {/* Echo Type Dropdown */}
        <Grid item xs={12} sm={6}>
          <StyledFormControl fullWidth error={!!errors.echoType}>
            <InputLabel>{t("doctor.echoType")}</InputLabel>
            <Select
              name="echoType"
              value={echoDetails.echoType}
              onChange={handleChange}
              displayEmpty
            >
              {echoTypes.map((type) => (
                <MenuItem key={type.id} value={type.id.toString()}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
            {errors.echoType && (
              <Typography color="error" variant="caption">
                {errors.echoType}
              </Typography>
            )}
          </StyledFormControl>
        </Grid>

        {/* Test Date */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="testDate"
            label={t("doctor.testDate")}
            type="date"
            fullWidth
            value={echoDetails.testDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            error={!!errors.testDate}
            helperText={errors.testDate}
          />
        </Grid>

        {/* Total Amount */}
        <Grid item xs={12} sm={6}>
          <TextField
            name="totalAmount"
            label={t("doctor.totalAmount")}
            type="number"
            fullWidth
            value={echoDetails.totalAmount}
            onChange={handleChange}
            error={!!errors.totalAmount}
            helperText={errors.totalAmount}
          />
        </Grid>

        {/* Urgent Switch */}
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={echoDetails.isUrgent}
                onChange={handleChange}
                name="isUrgent"
              />
            }
            label={t("doctor.urgent")}
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <StyledButton fullWidth onClick={handleSubmit}>
            {t("doctor.submit")}
          </StyledButton>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 4 }} />

      <Box sx={{ mt: 3 }}>
        <EchoCollectFees />
      </Box>
    </FullWidthPaper>
  );
};

export default EchoReferral;
