// EditPatientDialog.js
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useFetchObjects from "../../../../api/useFetchObjects";

const EditPatientDialog = ({ open, onClose, patient, onSave, token }) => {
  const { t } = useTranslation();

  const [firstname, setFirstname] = useState(patient?.firstname || "");
  const [lastname, setLastname] = useState(patient?.lastname || "");
  const [gender, setGender] = useState(patient?.gender || "");
  const [age, setAge] = useState(patient?.age || "");
  const [bloodType, setBloodType] = useState(patient?.blood_type || "");

  const { data, isSuccess } = useFetchObjects(
    ["blood-types"],
    "blood-types/",
    token
  );

  useEffect(() => {
    if (patient) {
      setFirstname(patient.firstname);
      setLastname(patient.lastname);
      setGender(patient.gender);
      setAge(patient.age);
      setBloodType(patient.blood_type);
    }
  }, [patient]);

  const handleSave = () => {
    onSave({
      id: patient.id,
      firstname,
      lastname,
      gender,
      age,
      blood_type: bloodType,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("reception.editPatient")}</DialogTitle>
      <DialogContent>
        <TextField
          label={t("reception.firstname")}
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t("reception.lastname")}
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("reception.gender")}</InputLabel>
          <Select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            label={t("reception.gender")}
          >
            <MenuItem value={1}>{t("reception.male")}</MenuItem>
            <MenuItem value={2}>{t("reception.female")}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label={t("reception.age")}
          type="number"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("reception.bloodType")}</InputLabel>
          <Select
            value={bloodType}
            onChange={(e) => setBloodType(e.target.value)}
            label={t("reception.bloodType")}
          >
            {isSuccess &&
              data?.results?.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="error"
          size="small"
        >
          {t("reception.cancel")}
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          size="small"
        >
          {t("reception.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPatientDialog;
