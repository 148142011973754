import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
} from "@mui/material";
import { Visibility, Delete, Edit } from "@mui/icons-material";
import useFetchObjects from "../../../../../api/useFetchObjects";
import { useAuth } from "../../../../../context";
import useDelete from "../../../../../api/useDelete";
import EditMedicine from "../editMedicine/EditMedicine";
import { useTranslation } from "react-i18next";

const ListMedicine = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data, refetch, isLoading, isError } = useFetchObjects(
    ["medicines"],
    "medicines",
    token
  );
  const { data: categoryData } = useFetchObjects(
    ["medicine-categories"],
    "medicine-categories",
    token
  );

  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const [description, setDescription] = useState("");

  const { handleDelete, ConfirmDialog } = useDelete("medicines", token);

  const handleUpdateSubmit = async (id, updateData) => {
    handleUpdate(id, updateData);
    setSnackbarMessage(t("pharmacy:medicineUpdateSuccess"));
    refetch();
  };

  const handleOpenUpdateDialog = (medicine) => {
    setSelectedMedicine(medicine);
    setOpenUpdateDialog(true);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
    setSelectedMedicine(null);
  };

  const handleClickOpenDescriptionDialog = (description) => {
    setDescription(description);
    setOpenDescriptionDialog(true);
  };

  const handleCloseDescriptionDialog = () => {
    setOpenDescriptionDialog(false);
    setDescription("");
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Grid item xs={12}>
      <Paper sx={{ boxShadow: "0px 0px 0px", m: "1em 3em" }}>
        <Grid container padding={"20px"}>
          <Grid container justifyContent={"space-between"}>
            <Typography variant="h5">{t("pharmacy.medicine_list")}</Typography>
          </Grid>
          <Divider sx={{ mt: 1, width: "100%" }} />

          {isLoading ? (
            <Grid container justifyContent="center" sx={{ mt: 4 }}>
              <CircularProgress />
            </Grid>
          ) : isError ? (
            <Grid container justifyContent="center" sx={{ mt: 4 }}>
              <Typography color="error">
                {t("pharmacy.failedToLoad")}
              </Typography>
            </Grid>
          ) : (
            <TableContainer sx={{ mt: 4 }}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                  <TableRow>
                    <TableCell align="center">
                      {t("pharmacy.medicineName")}
                    </TableCell>
                    <TableCell align="center">
                      {t("pharmacy.category")}
                    </TableCell>
                    <TableCell align="center">
                      {t("pharmacy.description")}
                    </TableCell>
                    <TableCell align="center">
                      {t("pharmacy.actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((medicine, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {medicine.name}
                      </TableCell>
                      <TableCell align="center">
                        {medicine.category_display}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() =>
                            handleClickOpenDescriptionDialog(
                              medicine.description
                            )
                          }
                        >
                          <Visibility />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenUpdateDialog(medicine)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(medicine.id)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Snackbar
            open={!!snackbarMessage}
            autoHideDuration={6000}
            onClose={() => setSnackbarMessage("")}
            message={snackbarMessage}
          />
          <ConfirmDialog />

          {selectedMedicine && (
            <EditMedicine
              open={openUpdateDialog}
              onClose={handleCloseUpdateDialog}
              medicine={selectedMedicine}
              categoryData={categoryData}
              token={token}
            />
          )}

          {/* Description Dialog */}
          <Dialog
            open={openDescriptionDialog}
            onClose={handleCloseDescriptionDialog}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>{t("pharmacy.medicineDescription")}</DialogTitle>
            <DialogContent dividers>
              <Typography
                variant="body1"
                sx={{ padding: "1em", fontSize: "1.1em", lineHeight: "1.6" }}
              >
                {description}
              </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: "0.5em 1.5em" }}>
              <Button
                onClick={handleCloseDescriptionDialog}
                color="primary"
                variant="contained"
              >
                {t("pharmacy.close")}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ListMedicine;
