import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";

const UpdateEchoResultDialog = ({ open, onClose, result, token, refetch }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    findings: result.findings || "",
    image: null,
  });

  const { handleUpdate, loading } = useUpdate("echo-results", token);

  useEffect(() => {
    if (result) {
      setFormData({
        findings: result.findings || "",
        image: null,
      });
    }
  }, [result]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    setFormData({ ...formData, image: event.target.files[0] });
  };

  const handleSubmit = () => {
    const updateData = new FormData();
    updateData.append("findings", formData.findings);
    if (formData.image) updateData.append("image", formData.image);

    handleUpdate(result.id, updateData);
    onClose();
    refetch(); // Refetch to get the latest data after update
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle mt={3}>
        {t("echo.updateEchoResult")}
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={2}>
          <Typography variant="body1" fontWeight="bold">
            {t("echo.patient")}: {result.patient_display}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t("echo.echoType")}: {result.echo_type_display}
          </Typography>
        </Box>

        {/* Findings Field */}
        <TextField
          label={t("echo.findings")}
          name="findings"
          value={formData.findings}
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={4}
          sx={{ mb: 3 }}
        />

        {/* Image Upload */}
        <TextField
          type="file"
          label={t("echo.replaceImage")}
          name="image"
          onChange={handleFileChange}
          fullWidth
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 3 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          {t("echo.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
          sx={{
            textTransform: "capitalize",
            fontWeight: "bold",
            margin: "0px 10px",
          }}
        >
          {loading ? t("echo.saving") : t("echo.saveChange")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateEchoResultDialog;
