import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import useAdd from "../../../../../api/useAdd";
import { useAuth } from "../../../../../context";
import useFetchObjects from "../../../../../api/useFetchObjects";
import AddCategoryDialog from "./AddCategoryDialog";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const buttonStyle = {
  backgroundColor: "#a7e2f9",
  color: "#071952",
  textTransform: "capitalize",
  margin: "0px 5px",
};

const AddMedicine = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const auth = useAuth();
  const token = auth?.user?.token;

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
  });

  const { data: categoriesData } = useFetchObjects(
    ["medicine-categories"],
    "medicine-categories",
    token
  );

  const { handleAdd, resetForm, loading } = useAdd(
    "medicines",
    token,
    t("pharmacy.addedSuccess"),
    t("pharmacy.addFailed")
  );

  const handleNavigateToAddMedicine = () => {
    navigate("/pharmacy/purchase-drug");
  };

  useEffect(() => {
    if (resetForm) {
      setFormData({
        name: "",
        description: "",
      });
      setFormErrors({});
    }
  }, [resetForm]);

  useEffect(() => {
    const handleShortcut = (e) => {
      if (e.ctrlKey && e.key === "y") {
        e.preventDefault();
        handleNavigateToAddMedicine();
      }
    };

    window.addEventListener("keydown", handleShortcut);
    return () => {
      window.removeEventListener("keydown", handleShortcut);
    };
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();
    let errors = {};

    if (!formData.name) {
      errors.name = t("pharmacy.nameRequired");
    }
    if (!formData.category) {
      errors.category = t("pharmacy.categoryRequired");
    }
    if (!formData.description) {
      errors.description = t("pharmacy.descriptionRequired");
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      const success = handleAdd(formData);
      if (success) {
        setFormData({
          name: "",
          description: "",
          category: "",
        });
        setFormErrors({});
      }
    }
  };

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleCategoryChange = (event, value) => {
    setFormData({ ...formData, category: value?.id || "" });
  };

  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
  };

  return (
    <Grid container maxWidth={"xl"} justifyContent={"center"} mt={2} mb={5}>
      <Grid item xs={11} md={7} sx={{ bgcolor: "white", borderRadius: "10px" }}>
        <Box component={"form"} padding={"30px"} onSubmit={handleSave}>
          <Typography variant="h4" fontWeight={"bold"} mb={1}>
            {t("pharmacy.new_medicine")}
          </Typography>

          <TextField
            fullWidth
            size="small"
            label={t("pharmacy.medicineName")}
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            error={!!formErrors.name}
            helperText={formErrors.name}
            sx={{ mt: "20px" }}
          />

          <TextField
            fullWidth
            size="small"
            label={t("pharmacy.description")}
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            error={!!formErrors.description}
            helperText={formErrors.description}
            sx={{ mt: "20px" }}
            multiline
            rows={4}
          />

          <Autocomplete
            options={categoriesData || []}
            getOptionLabel={(option) => option.name || ""}
            onChange={handleCategoryChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("pharmacy.category")}
                name="category"
                error={!!formErrors.category}
                helperText={formErrors.category}
                sx={{ mt: "20px" }}
              />
            )}
          />

          {/* Add New Category Button below the Autocomplete */}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenCategoryDialog}
            sx={{ display: "block", mt: 1 }}
          >
            {t("pharmacy.addNewCategory")}
          </Button>

          <Grid container justifyContent={"end"} mt={2}>
            <Button
              variant="contained"
              style={buttonStyle}
              onClick={() => resetForm()}
              disabled={loading}
            >
              {t("pharmacy.cancel")}
            </Button>

            <Button
              variant="contained"
              style={buttonStyle}
              type="submit"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : null}
            >
              {loading ? t("pharmacy.saving") : t("pharmacy.save")}
            </Button>
          </Grid>
        </Box>
      </Grid>

      {/* Add Category Dialog */}
      <AddCategoryDialog
        open={openCategoryDialog}
        onClose={handleCloseCategoryDialog}
        token={token}
      />
    </Grid>
  );
};

export default AddMedicine;
