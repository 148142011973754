import {
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useFetchObjects from "../../../../../api/useFetchObjects";
import useDelete from "../../../../../api/useDelete";
import ErrorPage from "../../../../../Components/GeneralComponents/errorPage/ErrorPage";
import NoRecordMessage from "../../../../../Components/GeneralComponents/noRecordMessage/NoRecordMessage";
import { useAuth } from "../../../../../context";
import Loading from "./Loading";
import { Delete, Edit } from "@mui/icons-material";
import EditPosition from "./EditPosition";
import { useTranslation } from "react-i18next";

const PositionsList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const { data, isLoading, isError, error } = useFetchObjects(
    ["position"],
    "position",
    token
  );
  const { handleDelete, ConfirmDialog } = useDelete("position");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const handleDialogOpen = (position) => {
    setSelectedPosition(position);
    setOpenDialog(!openDialog);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <Grid container>
        <ErrorPage />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Paper sx={{ boxShadow: "0px 0px 0px" }}>
        <Grid container padding={"10px"}>
          <Typography variant="h5" color={"blue"} fontWeight={"bold"}>
            {t("hr.positions")}
          </Typography>

          <Divider sx={{ mt: 2, width: "100%" }} />
          {data?.length === 0 && (
            <Grid container>
              <NoRecordMessage entityName="Position" />
            </Grid>
          )}
          <TableContainer sx={{ mt: 2 }}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                <TableRow>
                  <TableCell align="center"> {t("hr.position")}</TableCell>
                  <TableCell align="center">
                    {" "}
                    {t("hr.numberEmployee")}
                  </TableCell>
                  <TableCell align="center"> {t("hr.action")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {item.name}
                    </TableCell>
                    <TableCell align="center">{item.total_employee}</TableCell>
                    <TableCell align="center">
                      <Box>
                        <Button
                          color="primary"
                          onClick={() => handleDialogOpen(item)}
                        >
                          <Edit />
                        </Button>

                        <Button
                          color="error"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Delete />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Paper>

      {/* Dialog for updating position */}
      <Dialog open={openDialog} onClose={handleDialogOpen}>
        <DialogTitle>{t("hr.updatePosition")}</DialogTitle>
        <DialogContent>
          <EditPosition
            position={selectedPosition}
            
            setOpenDialog={setOpenDialog}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogOpen} color="primary">
            {t("hr.close")}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog />
    </Grid>
  );
};

export default PositionsList;
