// src/routes/doctorRoutes.js
import DoctorNav from "../../pages/Dashboard/Doctor/DoctorNav";
import DoctorIndex from "../../pages/Dashboard/Doctor/DoctorIndex";
import Patients from "../../pages/Dashboard/Doctor/Patients/Patients";
import AddTaskToNurse from "../../pages/Dashboard/Doctor/AddTaskToNurse/AddTaskToNurse";
import AddPrescription from "../../pages/Dashboard/Doctor/prescription/Prescription";

const doctorRoutes = {
  path: "/doctor",
  element: <DoctorNav />,
  children: [
    { path: "/doctor", element: <DoctorIndex /> },
    { path: "/doctor/patients", element: <Patients /> },
    { path: "/doctor/prescription", element: <AddPrescription /> },
    { path: "/doctor/add-task-to-nurse", element: <AddTaskToNurse /> },
  ],
};

export default doctorRoutes;
