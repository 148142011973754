import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PrintIcon from "@mui/icons-material/Print";

const ViewTestsDialog = ({
  isOpen,
  onClose,
  tests,
  doctorName,
  patientName,
  patientId,
  requestedDate,
  total_amount,
  handlePrint,
}) => {
  const { t } = useTranslation();
  const logoUrl = `${process.env.REACT_APP_MEDIA_URL}/images/logo/logo2.png`;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      {/* Header Section */}
      <Box
        sx={{
          backgroundColor: "#007174",
          color: "#ffffff",
          padding: "10px 20px",
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={2}>
            <Box
              sx={{
                height: "60px",
                width: "60px",
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={logoUrl}
                alt="Hospital Logo"
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </Box>
          </Grid>
          <Grid item xs={8} textAlign="center">
            <Typography variant="h4" fontWeight="bold">
              Hafez Balkh Specialty Surgical Hospital
            </Typography>
            <Typography variant="body1">
              شفاخانه اختصاصی جراحی حافظ بلخ
            </Typography>
            <Typography variant="subtitle2">
              Healthcare Road, Balkh - Afghanistan
            </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton onClick={handlePrint} sx={{ color: "#ffffff" }}>
              <PrintIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      {/* Patient and Test Information */}
      <DialogContent sx={{ padding: "20px", backgroundColor: "#ffffff" }}>
        <Box
          sx={{
            border: "2px solid #007174",
            borderRadius: "10px",
            padding: "15px",
            marginBottom: "20px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("laboratory.patientName")}:{" "}
                <Typography component="span" fontWeight="400">
                  {patientName || t("laboratory.noData")}
                </Typography>
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("laboratory.patientId")}:{" "}
                <Typography component="span" fontWeight="400">
                  {patientId || t("laboratory.noData")}
                </Typography>
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("laboratory.requestedDate")}:{" "}
                <Typography component="span" fontWeight="400">
                  {requestedDate || t("laboratory.noData")}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("laboratory.doctorName")}:{" "}
                <Typography component="span" fontWeight="400">
                  {doctorName || t("laboratory.noData")}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Tests Table */}
        {tests.length > 0 ? (
          <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#007174" }}>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#ffffff" }}>
                    {t("laboratory.testName")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#ffffff" }}>
                    {t("laboratory.resultValue")}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#ffffff" }}>
                    {t("laboratory.normalRange")} ({t("laboratory.units")})
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                  >
                    {t("laboratory.isWithinNormalRange")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      color: "#ffffff",
                      textAlign: "right",
                    }}
                  >
                    {t("laboratory.costInAfghani")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tests.map((test, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        padding: "16px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ padding: "16px", fontSize: "15px" }}>
                      {test.test_type_display.name}
                    </TableCell>
                    <TableCell sx={{ padding: "16px", fontSize: "15px" }}>
                      {test.test_type_display.result_value !== undefined
                        ? `${test.test_type_display.result_value} ${test.test_type_display.units}`
                        : t("laboratory.noResultAvailable")}
                    </TableCell>
                    <TableCell sx={{ padding: "16px", fontSize: "15px" }}>
                      {test.test_type_display.min_value} -{" "}
                      {test.test_type_display.max_value}{" "}
                      {test.test_type_display.units}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "16px",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: test.test_type_display
                          .is_within_normal_range_display
                          ? "#4CAF50"
                          : "#F44336",
                      }}
                    >
                      {test.test_type_display.is_within_normal_range_display
                        ? t("laboratory.withinNormalRange")
                        : t("laboratory.outsideNormalRange")}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "16px",
                        fontSize: "15px",
                        textAlign: "right",
                      }}
                    >
                      {test.test_type_display.cost} ؋
                    </TableCell>
                  </TableRow>
                ))}
                {/* Total Price Row */}
                <TableRow>
                  <TableCell colSpan={5} sx={{ fontWeight: "bold" }}>
                    {t("laboratory.totalAmount")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      textAlign: "right",
                    }}
                  >
                    {total_amount} ؋
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                color: "#757575",
                fontStyle: "italic",
              }}
            >
              {t("laboratory.noTestsAvailable")}
            </Typography>
          </Box>
        )}
      </DialogContent>

      {/* Footer Section */}
      <Box
        sx={{
          backgroundColor: "#007174",
          color: "#ffffff",
          padding: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle2">
          www.hafezbalkhhospital.com | hafezbalkh.hospital@gmail.com
        </Typography>
        <Typography variant="subtitle2">(+93) 07740-12357</Typography>
      </Box>
    </Dialog>
  );
};

export default ViewTestsDialog;
