import React, { useState } from "react";
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import useFetchObjects from "../../../../../api/useFetchObjects";
import useDelete from "../../../../../api/useDelete";
import { useTranslation } from "react-i18next";
import EditExpensesType from "../editExpensesType/EditExpensesType";
import { useAuth } from "../../../../../context";

const ListExpensesType = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data, refetch, isLoading, isError } = useFetchObjects(
    ["expense-types"],
    "expense-types/",
    token
  );

  const { handleDelete, ConfirmDialog } = useDelete("expense-types", token);

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedExpenseType, setSelectedExpenseType] = useState(null);

  const handleOpenEditDialog = (expenseType) => {
    setSelectedExpenseType(expenseType);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedExpenseType(null);
    refetch();
  };

  return (
    <Grid container mt={3}>
      <Grid item xs={12} md={12}>
        <Paper elevation={3} sx={{ p: 3, borderRadius: 3 }}>
          <Typography variant="h5" fontWeight="bold" mb={2} color="primary">
            {t("finance.expenseTypeList")}
          </Typography>
          <Divider sx={{ mb: 3 }} />

          {isLoading ? (
            <Grid container justifyContent="center" sx={{ mt: 4 }}>
              <CircularProgress />
            </Grid>
          ) : isError ? (
            <Typography color="error" textAlign="center">
              {t("finance.failedToLoad")}
            </Typography>
          ) : (
            <TableContainer>
              <Table>
                <TableHead style={{ backgroundColor: "#f4f6f8" }}>
                  <TableRow>
                    <TableCell>{t("finance.expenseTypeName")}</TableCell>
                    <TableCell>{t("finance.actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.results.map((expenseType) => (
                    <TableRow key={expenseType.id}>
                      <TableCell>{expenseType.name}</TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenEditDialog(expenseType)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(expenseType.id)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <ConfirmDialog />

          {/* Edit Dialog */}
          {selectedExpenseType && (
            <EditExpensesType
              open={openEditDialog}
              onClose={handleCloseEditDialog}
              expenseType={selectedExpenseType}
              token={token}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ListExpensesType;
