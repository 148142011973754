// src/routes/patientProfileRoutes.js
import PatientProfileNav from "../../pages/Dashboard/PatientProfile/PatientProfileNav";
import PatientProfileIndex from "../../pages/Dashboard/PatientProfile/PatientProfileIndex";
import Profile from "../../pages/Dashboard/PatientProfile/Profile/Profile";

const patientProfileRoutes = {
  path: "/patient-profile",
  element: <PatientProfileNav />,
  children: [
    { path: "/patient-profile/", element: <PatientProfileIndex /> },
    { path: "/patient-profile/profile", element: <Profile /> },
    { path: "/patient-profile/history", element: <div>Patient History</div> },
    {
      path: "/patient-profile/prescriptions",
      element: <div>Patient Prescriptions</div>,
    },
  ],
};

export default patientProfileRoutes;
