import React from "react";
import AddExpensesType from "./addExpensesType/AddExpensesType";
import ListExpensesType from "./ListExpensesType/ListExpensesType";

const ManageExpensesType = () => {
  return (
    <>
      <AddExpensesType />
      <ListExpensesType />
    </>
  );
};

export default ManageExpensesType;
