import React from "react";
import { Grid, Paper, Box } from "@mui/material";
import ActivityOverView from "./ActivityOverView/ActivityOverView";
import Appointments from "./Appointments/Appointments";

const Index = () => {
  return (
    <Box sx={{ width: "100%", backgroundColor: "#f5f5f5", p: 3 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper
            sx={{
              borderRadius: "15px",
              width: "100%",
            }}
          >
            <ActivityOverView />
          </Paper>
        </Grid>

        {/* Appointments Section */}
        <Grid item xs={12}>
          <Paper
            sx={{
              padding: "30px",
              borderRadius: "15px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Appointments />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Index;
