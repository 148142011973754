import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";
import Profile from "./Profile/Profile";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context";
import useFetchObjects from "../../../api/useFetchObjects";
import PatientSearch from "./PatientSearch/PatientSearch";

const PatientProfileIndex = () => {
  return (
    <Grid container maxWidth="xl" mb={3}>
      <Grid container gap={1} mt={2} justifyContent={"center"}>
        <Grid item xs={12} md={12} lg={12} bgcolor={"white"}>
          <Grid container>
            <PatientSearch />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientProfileIndex;
