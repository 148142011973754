import NurseNav from "../../pages/Dashboard/Nurse/NurseNav";
import NurseIndex from "../../pages/Dashboard/Nurse/NurseIndex";
import BedPatient from "../../pages/Dashboard/Nurse/BedPatient/BedPatient";

const nurseRoutes = {
  path: "/nurse",
  element: <NurseNav />,
  children: [
    { path: "/nurse", element: <NurseIndex /> },
    { path: "/nurse/bedPatient", element: <BedPatient /> },
  ],
};

export default nurseRoutes;
