import {
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAdd from "../../../../../api/useAdd";
import { useAuth } from "../../../../../context";

import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";

const buttonStyle = {
  textTransform: "capitalize",
  backgroundColor: "#071952",
  fontWeight: "bold",
};

const AddPosition = () => {
  const {t}=useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const [formErrors, setFormErrors] = useState({});

  const { handleAdd, loading, resetForm } = useAdd(
    "position",
    token,
    t("hr.positionAddedSuccess"),
    t("hr.positionAddFailed")
  );

  const [formData, setFormData] = useState({
    code: "",
    name: "",
  });

  useEffect(() => {
    // Reset the form when resetForm flag is true
    if (resetForm) {
      setFormData({
        code: "",
        name: "",
      });
      setFormErrors({}); // Clear errors on successful submission
    }
  }, [resetForm]);

  const validateForm = () => {
    const errors = {};
    if (!formData.code.trim()) {
      errors.code =t("hr.positionCodeRequired");
    }
    if (!formData.name.trim()) {
      errors.name = t("hr.positionNameRequired");
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const submitData = new FormData();
    Object.keys(formData).forEach((key) =>
      submitData.append(key, formData[key])
    );
    handleAdd(submitData);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" }); // Clear field errors when typing
  };

  return (
    <Paper sx={{ boxShadow: "0px 0px 0px", width: "100%" }}>
      <Grid container padding={"20px"}>
        <Typography color="primary">{t("hr.newPosition")}</Typography>
        <Grid
          container
          component={"form"}
          gap={2}
          justifyContent={"space-between"}
          mt={2}
        >
          <Grid item xs={12} md={12}>
            <FormLabel>{t("hr.positionCode")}</FormLabel>
            <TextField
              fullWidth
              name="code"
              value={formData.code}
              onChange={handleChange}
              error={!!formErrors.code}
              helperText={formErrors.code}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormLabel>{t("hr.positionName")}</FormLabel>
            <TextField
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!formErrors.name}
              helperText={formErrors.name}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid
          container
          gap={2}
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={4}
        >
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
            mt={2.8}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#a7e2f9",
                color: "#071952",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
              onClick={() => setFormData({ code: "", name: "" })} // Manually reset form on cancel
            >
            {t("hr.cancel")}
            </Button>

            <LoadingButton
              variant="contained"
              style={{ ...buttonStyle, margin: "0px 20px" }}
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon sx={{ color: "white" ,margin:"0px 5px" }} />} // White color for the icon
              onClick={handleSubmit}
            >
             {t("hr.save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddPosition;
