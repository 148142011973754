// OperationsIndex.js
import { Grid } from "@mui/material";
import React from "react";
import OperationsOverview from "./ActivityOverView/OperationsOverview";
import OperationsToBeDone from "./TestsTobeDone/OperationsToBeDone";

const OperationsIndex = () => {
  return (
    <Grid container maxWidth="xl" mb={3}>
      <Grid container gap={1} mt={2} justifyContent={"center"}>
        <Grid item xs={12} md={12} lg={3.2} bgcolor={"white"}>
          <OperationsOverview />
        </Grid>
        <Grid item xs={12} lg={8.5}>
          <OperationsToBeDone />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OperationsIndex;
