import React, { useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Tooltip,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { RemoveCircleOutline, Search } from "@mui/icons-material";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context";
import POSList from "./PosList";
import { useTranslation } from "react-i18next";

const getCurrentDate = () => new Date().toISOString().split("T")[0];

const MedicineOrder = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacyType = auth?.user?.user.pharmacy_type || "";

  const { data: stockMedicines, isLoading } = useFetchObjects(
    ["stock-medicines"],
    `stock-medicines/?pharmacy=${pharmacyType}`,
    token
  );

  const [input, setInput] = useState({
    date: getCurrentDate(),
    items: [],
    total_price: 0,
  });

  const [formErrors, setFormErrors] = useState({});
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

  const {
    handleAdd: handleAddOrder,
    resetForm,
    loading,
  } = useAdd(
    "medicine-orders",
    token,
    t("pharmacy.medicineOrderCreatedSuccess"),
    t("pharmacy.failedToCreateOrder")
  );

  const autocompleteRef = useRef(null);
  const quantityRefs = useRef([]);
  const priceRefs = useRef([]);

  useEffect(() => {
    if (resetForm) {
      setInput({
        date: getCurrentDate(),
        items: [],
        total_price: 0,
      });
      setFormErrors({});
    }
  }, [resetForm]);

  const handleChangeItemField = (index, e) => {
    const { name, value } = e.target;
    const items = [...input.items];
    items[index][name] = value ? parseInt(value) || value : "";

    // Recalculate item total price based on quantity and price
    if (name === "quantity" || name === "sell_price") {
      items[index].total_price =
        (parseFloat(items[index].quantity) || 0) *
        (parseFloat(items[index].sell_price) || 0);
    }

    setInput({ ...input, items });
    updateTotalBill(items); // Recalculate total bill whenever an item changes
  };

  const handleAddNewItem = (medicine) => {
    if (medicine) {
      const newItem = {
        stock_medicine: medicine.id,
        name: medicine.medicine_display,
        quantity: 1,
        sell_price: medicine.sell_price,
        total_price: medicine.sell_price,
        available_stock: medicine.quantity,
      };

      setInput((prevInput) => {
        const newItems = [...prevInput.items, newItem];
        return {
          ...prevInput,
          items: newItems,
        };
      });

      // Recalculate total price for the whole order immediately after adding an item
      updateTotalBill([...input.items, newItem]);

      setAutocompleteInputValue(""); // Clear search input after adding
      if (autocompleteRef.current) {
        autocompleteRef.current.focus(); // Focus the search input again
      }
    }
  };

  const handleRemoveItem = (index) => {
    const items = [...input.items];
    items.splice(index, 1);
    setInput({ ...input, items });
    updateTotalBill(items); // Recalculate total bill after removing an item
  };

  const updateTotalBill = (items) => {
    const totalPrice = items.reduce(
      (sum, item) => sum + (item.total_price || 0),
      0
    );
    setInput((prevInput) => ({ ...prevInput, total_price: totalPrice }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    let isValid = true;
    let errors = {};

    input.items.forEach((item, index) => {
      if (!item.stock_medicine) {
        errors[`stock_medicine_${index}`] = t("pharmacy.medicineRequired");
        isValid = false;
      }
      if (!item.quantity) {
        errors[`quantity_${index}`] = t("pharmacy.quantityRequired");
        isValid = false;
      }
    });

    setFormErrors(errors);
    if (isValid) {
      const payload = {
        date: input.date,
        items: input.items.map((item) => ({
          stock_medicine: item.stock_medicine,
          quantity: item.quantity,
        })),
      };
      handleAddOrder(payload);
    }
  };

  const handleKeyDown = (index, fieldType, e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (fieldType === "quantity") {
        if (priceRefs.current[index]) {
          priceRefs.current[index].focus();
        }
      } else if (fieldType === "sell_price") {
        if (index < input.items.length - 1) {
          if (quantityRefs.current[index + 1]) {
            quantityRefs.current[index + 1].focus();
          }
        } else {
          if (autocompleteRef.current) {
            autocompleteRef.current.focus();
          }
        }
      }
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper
      sx={{
        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
        borderRadius: "16px",
        mt: 4,
        mb: 4,
        p: 6,
        width: "100%",
        mx: "auto",
      }}
    >
      <Typography
        fontWeight={600}
        variant="h4"
        color="primary"
        gutterBottom
        sx={{ mb: 4, textAlign: "center" }}
      >
        {t("pharmacy.orderMedicines")}
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label={t("pharmacy.orderDate")}
            name="date"
            type="date"
            size="medium"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={input.date}
            onChange={(e) => setInput({ ...input, date: e.target.value })}
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Search Medicine Section */}
      <Box mb={4}>
        <Typography variant="subtitle1" fontWeight={500} mb={2}>
          {t("pharmacy.addMedicine")}
        </Typography>
        <Autocomplete
          ref={autocompleteRef}
          value={null}
          inputValue={autocompleteInputValue}
          onInputChange={(event, newInputValue) => {
            setAutocompleteInputValue(newInputValue);
          }}
          onChange={(event, value) => {
            handleAddNewItem(value);
            setAutocompleteInputValue("");
          }}
          options={stockMedicines || []}
          getOptionLabel={(option) => option.medicine_display}
          renderOption={(props, option) => (
            <Box component="li" {...props} sx={{ padding: 1 }}>
              <Typography variant="body2" fontWeight={600}>
                {option.medicine_display}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {` | ${t("pharmacy.price")}: ${option.sell_price} | ${t(
                  "pharmacy.expiryDate"
                )}: ${option.expiry_date}`}
              </Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("pharmacy.searchMedicine")}
              variant="outlined"
              placeholder={t("pharmacy.searchMedicinePlaceholder")}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#fff",
                borderRadius: 2,
              }}
            />
          )}
        />
      </Box>

      {/* Medicine List Table */}
      <TableContainer component={Paper} elevation={1} sx={{ mb: 4 }}>
        <Table>
          <TableHead sx={{ backgroundColor: "#eef2f5" }}>
            <TableRow>
              <TableCell>{t("pharmacy.medicineName")}</TableCell>
              <TableCell align="center">
                {t("pharmacy.availableStock")}
              </TableCell>
              <TableCell align="center">{t("pharmacy.quantity")}</TableCell>
              <TableCell align="center">{t("pharmacy.price")}</TableCell>
              <TableCell align="center">{t("pharmacy.totalPrice")}</TableCell>
              <TableCell align="center">{t("pharmacy.action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {input.items.map((item, index) => (
              <TableRow key={index} hover>
                <TableCell>{item.name}</TableCell>
                <TableCell align="center">{item.available_stock}</TableCell>
                <TableCell align="center">
                  <TextField
                    inputRef={(el) => (quantityRefs.current[index] = el)}
                    name="quantity"
                    type="number"
                    size="small"
                    variant="outlined"
                    value={item.quantity}
                    onChange={(e) => handleChangeItemField(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, "quantity", e)}
                    sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    inputRef={(el) => (priceRefs.current[index] = el)}
                    name="sell_price"
                    type="number"
                    size="small"
                    variant="outlined"
                    value={item.sell_price}
                    onChange={(e) => handleChangeItemField(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, "sell_price", e)}
                    sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                  />
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={600}>{item.total_price}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title={t("pharmacy.removeItem")} arrow>
                    <IconButton
                      color="error"
                      onClick={() => handleRemoveItem(index)}
                    >
                      <RemoveCircleOutline />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Save Button and Total Price Display */}
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <TextField
          size="small"
          label={t("pharmacy.totalPrice")}
          type="number"
          value={input.total_price}
          disabled
          sx={{ width: 200, backgroundColor: "#fff", borderRadius: 2 }}
        />
        <Button
          size="small"
          onClick={handleSave}
          variant="contained"
          disabled={loading}
          sx={{
            fontWeight: 700,
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          {loading ? t("pharmacy.saving") : t("pharmacy.savePrescription")}
        </Button>
      </Stack>

      {/* POS List Component */}
      <Box mt={4}>
        <Divider />
        <POSList />
      </Box>
    </Paper>
  );
};

export default MedicineOrder;
