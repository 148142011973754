import { useEffect } from "react";
import { useNewRecords } from "../context/NewRecordsProvider";

export const useHighlightData = (data, key, conditionFn) => {
  const { updateHighlight } = useNewRecords();

  useEffect(() => {
    if (data) {
      const condition = conditionFn(data);
      updateHighlight(key, condition);
    }
  }, [data, key, conditionFn, updateHighlight]);
};
