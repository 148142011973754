import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Divider,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { Wc, CalendarToday, Bloodtype } from "@mui/icons-material";
import MedicalHistory from "./MedicalHistory/MedicalHistory"; // Import Medical History
import { useTranslation } from "react-i18next";

const Profile = () => {
  const {t}=useTranslation();
  const location = useLocation();
  const userProfile = location.state;

  if (!userProfile) {
    return (
      <Box sx={{ p: 3, display: "flex", justifyContent: "center" }}>
        <Typography variant="h6" color="textSecondary">
         {t("profile.noPatientSelected")}
        </Typography>
      </Box>
    );
  }

  const { firstname, lastname, id, gender, age, blood_type_display } =
    userProfile.results[0];

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", mt: 2, width: "100%" }}
    >
      <Card
        sx={{
          width: "100%",
          maxWidth: 900,
          mx: "auto",
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            {/* Left Side: Patient Details */}
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                {/* Full Name */}
                <Grid item xs={12}>
                  <Typography variant="h5" fontWeight="bold">
                    {firstname} {lastname}
                  </Typography>
                </Grid>

                {/* Patient ID */}
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <strong> {t("profile.patientId")}:</strong> {id}
                  </Typography>
                </Grid>

                {/* Gender */}
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Wc fontSize="small" sx={{ mr: 1, color: "#1976d2" }} />
                    <Typography variant="body1">
                      <strong>{t("profile.gender")}:</strong> {gender ? t("profile.male") : t("profile.female")}
                    </Typography>
                  </Box>
                </Grid>

                {/* Age */}
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CalendarToday
                      fontSize="small"
                      sx={{ mr: 1, color: "#1976d2" }}
                    />
                    <Typography variant="body1">
                      <strong>{t("profile.age")}:</strong> {age}
                    </Typography>
                  </Box>
                </Grid>

                {/* Blood Type */}
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Bloodtype
                      fontSize="small"
                      sx={{ mr: 1, color: "#1976d2" }}
                    />
                    <Typography variant="body1">
                      <strong>{t("profile.bloodType")}:</strong>{" "}
                      {blood_type_display || t("profile.unknown")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {/* Right Side: Avatar and Name */}
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ bgcolor: "#1976d2", width: 150, height: 150 }}>
                {firstname.charAt(0)}
              </Avatar>
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                {firstname} {lastname}
              </Typography>
            </Grid>
          </Grid>
          {/* Divider */}
          <Divider sx={{ my: 3 }} />
          {/* Medical History Section */}
          <MedicalHistory /> {/* Render the new Medical History component */}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Profile;
