import React, { useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Tooltip,
  Card,
  CardContent,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { RemoveCircleOutline, Search } from "@mui/icons-material";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";
import ListPatientTest from "./ListPatientTest";

const PatientTest = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data: patients, isLoading: loadingPatients } = useFetchObjects(
    ["patient"],
    "patients",
    token
  );
  const { data: tests } = useFetchObjects(
    ["laboratory-tests"],
    "laboratory-tests",
    token
  );
  const { data: doctors, isLoading: loadingDoctors } = useFetchObjects(
    ["users"],
    "users",
    token
  );

  const [orderData, setOrderData] = useState({
    date: new Date().toISOString().split("T")[0],
    doctor: auth?.user?.user?.id || "",
    patient: "",
    urgency: false,
    tests: [],
  });
  const [formErrors, setFormErrors] = useState({});
  const [selectedTests, setSelectedTests] = useState([]);
  const [searchText, setSearchText] = useState(""); // Track the search text
  const [filteredTests, setFilteredTests] = useState([]); // Filtered list of tests

  const { handleAdd, loading, resetForm } = useAdd(
    "laboratory-test-orders",
    token
  );

  useEffect(() => {
    if (resetForm) {
      setOrderData({
        date: new Date().toISOString().split("T")[0],
        doctor: auth?.user?.user?.id || "",
        patient: "",
        urgency: false,
        tests: [],
      });
      setSelectedTests([]);
      setFormErrors({});
    }
  }, [resetForm]);

  useEffect(() => {
    // Filter tests based on search text
    if (tests && searchText) {
      const filtered = tests.filter((test) =>
        test.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredTests(filtered);
    } else {
      setFilteredTests([]);
    }
  }, [searchText, tests]);

  const handleAddNewTest = (test) => {
    if (test) {
      const allTests = test.sub_tests ? [test, ...test.sub_tests] : [test];
      setSelectedTests((prevTests) => [...prevTests, ...allTests]);
      setSearchText("");
    }
  };

  const handleRemoveTest = (index) => {
    const updatedTests = [...selectedTests];
    updatedTests.splice(index, 1);
    setSelectedTests(updatedTests);
  };

  const handleChangeField = (e) => {
    const { name, value } = e.target;
    setOrderData({ ...orderData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!orderData.patient) {
      setFormErrors({ patient: t("laboratory.patientRequired") });
      return;
    }
    if (!orderData.doctor) {
      setFormErrors({ doctor: t("laboratory.doctorRequired") });
      return;
    }
    handleAdd({
      ...orderData,
      tests: selectedTests.map((test) => ({ test_type: test.id })),
    });
  };

  return (
    <Card
      sx={{
        boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
        backgroundColor: "#f4f6f8",
        width: "100%",
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Typography
          variant="h5"
          fontWeight={600}
          color="primary"
          textAlign="center"
          gutterBottom
        >
          {t("laboratory.addPatientTestsTitle")}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Box mb={4}>
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            {t("laboratory.patientInfo")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={t("laboratory.orderDate")}
                name="date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={orderData.date}
                onChange={handleChangeField}
                sx={{ backgroundColor: "#fff", borderRadius: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {loadingPatients ? (
                <CircularProgress size={24} />
              ) : (
                <Autocomplete
                  options={patients.results || []}
                  getOptionLabel={(option) =>
                    `${option.firstname} ${option.lastname} (${option.id})`
                  }
                  onChange={(event, value) =>
                    setOrderData({
                      ...orderData,
                      patient: value ? value.id : "",
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("laboratory.patient")}
                      placeholder={t("laboratory.searchByIdOrName")}
                      error={!!formErrors.patient}
                      helperText={formErrors.patient}
                      sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {loadingDoctors ? (
                <CircularProgress size={24} />
              ) : (
                <Autocomplete
                  options={doctors || []}
                  getOptionLabel={(option) => `${option.username}`}
                  value={
                    doctors.find((doctor) => doctor.id === orderData.doctor) ||
                    null
                  }
                  onChange={(event, value) =>
                    setOrderData({
                      ...orderData,
                      doctor: value ? value.id : "",
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("laboratory.doctor")}
                      error={!!formErrors.doctor}
                      helperText={formErrors.doctor}
                      sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Test Selection */}
        <Box mb={4}>
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            {t("laboratory.addTest")}
          </Typography>
          <Autocomplete
            value={null}
            inputValue={searchText}
            onInputChange={(event, newInputValue) =>
              setSearchText(newInputValue)
            }
            onChange={(event, value) => handleAddNewTest(value)}
            options={filteredTests} // Use filtered tests
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("laboratory.searchTest")}
                placeholder={t("laboratory.searchTestPlaceholder")}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                sx={{ backgroundColor: "#fff", borderRadius: 2 }}
              />
            )}
          />
        </Box>

        {/* Selected Tests Table */}
        <TableContainer component={Paper} elevation={1} sx={{ mb: 4 }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#eef2f5" }}>
              <TableRow>
                <TableCell>{t("laboratory.testName")}</TableCell>
                <TableCell align="center">{t("laboratory.testCost")}</TableCell>
                <TableCell align="center">{t("laboratory.action")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedTests.map((test, index) => (
                <TableRow key={index} hover>
                  <TableCell>{test.name}</TableCell>
                  <TableCell align="center">{test.cost || "N/A"}</TableCell>
                  <TableCell align="center">
                    <Tooltip title={t("laboratory.removeTest")} arrow>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveTest(index)}
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Submit Button */}
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            size="small"
            onClick={handleSubmit}
            variant="contained"
            disabled={loading}
            sx={{
              fontWeight: 700,
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
          >
            {loading ? t("laboratory.saving") : t("laboratory.saveOrder")}
          </Button>
        </Stack>
      </CardContent>
      <Divider />
      <Box m={3}>
        <ListPatientTest />
      </Box>
    </Card>
  );
};

export default PatientTest;
