import React, { useEffect } from "react";

const LaboratoryResultPaper = ({ labTest, tests }) => {
  useEffect(() => {
    const printStyles = `
      @media print {
        @page {
          size: A4;
           margin: 100mm; /* Top and bottom margins for header/footer */
        }
        html, body {
          font-family: 'Roboto', sans-serif;
          margin: 0;
          padding: 0;
          color: #000;
        }
        .print-container {
          padding-top: 120px; /* Space for the logo/header */
          padding-bottom: 50px; /* Space for the footer */
          background-color: white;
        }
        table {
          width: 100%;
        }
      }
    `;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = printStyles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  if (!labTest || !tests || tests.length === 0) {
    return null;
  }

  // Group tests by category
  const groupedTests = tests.reduce((acc, test) => {
    const category = test.test_type_display.category.name;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(test);
    return acc;
  }, {});

  return (
    <div
      className="print-container"
      style={{
        fontFamily: "'Roboto', sans-serif",
        backgroundColor: "#fff",
        color: "#333",
        lineHeight: "1.5",
        fontSize: "10px",
        marginTop: "150px",
        marginBottom: "150px",
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      {/* Patient Information */}
      <div style={{ marginBottom: "15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p>
              <strong>Patient ID:</strong> {labTest.patient_display.id}
            </p>
            <p>
              <strong>Patient Name:</strong> {labTest.patient_display.name}
            </p>
            <p>
              <strong>Age/Sex:</strong> {labTest.patient_display.age || "N/A"}{" "}
              {labTest.patient_display.gender || "N/A"}
            </p>
          </div>
          <div>
            <p>
              <strong>Referred By:</strong>{" "}
              {labTest.doctor_display.username || "N/A"}
            </p>
            <p>
              <strong>Date:</strong>{" "}
              {new Date(labTest.order_date).toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>

      {/* Laboratory Results */}
      <h2
        style={{
          textAlign: "center",
          fontSize: "12px",
          fontWeight: "bold",
          marginBottom: "15px",
        }}
      >
        Laboratory Test Results
      </h2>

      {/* Table Header (Shown Only Once) */}
      <table
        style={{
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: "#f5f5f5",
              textAlign: "left",
              fontSize: "10px",
              fontWeight: "bold",
            }}
          >
            <th style={{ padding: "6px", border: "1px solid #ddd" }}>
              Test Name
            </th>
            <th style={{ padding: "6px", border: "1px solid #ddd" }}>Result</th>
            <th style={{ padding: "6px", border: "1px solid #ddd" }}>Unit</th>
            <th style={{ padding: "6px", border: "1px solid #ddd" }}>
              Normal Range
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Tests grouped by category */}
          {Object.keys(groupedTests).map((category, index) => (
            <React.Fragment key={index}>
              {/* Category Row */}
              <tr>
                <td
                  colSpan={4}
                  style={{
                    fontWeight: "bold",
                    fontSize: "11px",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  {category.toUpperCase()}
                </td>
              </tr>

              {/* Parent and Child Tests */}
              {Object.entries(
                groupedTests[category].reduce((parents, test) => {
                  const parentTest = test.parent_test
                    ? test.parent_test.name
                    : test.test_type_display.name;
                  if (!parents[parentTest]) {
                    parents[parentTest] = [];
                  }
                  if (test.parent_test) {
                    parents[parentTest].push(test);
                  }
                  return parents;
                }, {})
              ).map(([parentTest, childTests], parentIndex) => (
                <React.Fragment key={parentIndex}>
                  {/* Parent Test */}
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        fontWeight: "bold",
                        fontSize: "10px",
                        padding: "6px 8px",
                        textAlign: "left",
                      }}
                    >
                      {parentTest}
                    </td>
                  </tr>

                  {/* Child Tests */}
                  {childTests.map((childTest, childIndex) => (
                    <tr key={childIndex}>
                      <td
                        style={{
                          padding: "6px 24px", // Indent for child tests
                          fontSize: "10px",
                        }}
                      >
                        {childTest.test_type_display.name}
                      </td>
                      <td style={{ padding: "6px", fontSize: "10px" }}>
                        {childTest.test_type_display.result_value || "N/A"}
                      </td>
                      <td style={{ padding: "6px", fontSize: "10px" }}>
                        {childTest.test_type_display.units || "N/A"}
                      </td>
                      <td style={{ padding: "6px", fontSize: "10px" }}>
                        {childTest.test_type_display.min_value || "N/A"} -{" "}
                        {childTest.test_type_display.max_value || "N/A"}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LaboratoryResultPaper;
