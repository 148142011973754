import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import { Months, years } from "../../../../utils";
import PayrollList from "./PayrollList";
import { useTranslation } from "react-i18next";

const Payroll = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const { data: employeeData } = useFetchObjects(["users"], "users", token);

  // Get the current date, month, and year
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // JavaScript months are zero-indexed
  const currentYear = currentDate.getFullYear();

  const [input, setInput] = useState({
    employee: "",
    total_salary: "",
    month: currentMonth,
    year: currentYear,
    paid_salary: "",
    date: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [advanceAmount, setAdvanceAmount] = useState(0);

  const { handleAdd, resetForm, loading } = useAdd(
    "employee-payroll",
    token,
    t("hr.payrollCreate"),
    t("hr.operationFailed")
  );

  // Construct endpoint for advance amount based on the current state
  const advanceEndpoint = `employee-advance/?month=${input.month}&year=${input.year}&employee=${input.employee}`;

  // Use `useFetchObjects` to fetch the advance data
  const {
    data: advanceData,
    isLoading: isAdvanceLoading,
    isError: isAdvanceError,
  } = useFetchObjects(["employee-advance", input], advanceEndpoint, token);

  // Set advance amount when advance data changes
  useEffect(() => {
    if (advanceData && advanceData.length > 0) {
      const totalAdvance = advanceData.reduce(
        (acc, curr) => acc + curr.amount,
        0
      );
      setAdvanceAmount(totalAdvance);
    } else {
      setAdvanceAmount(0);
    }
  }, [advanceData]);

  useEffect(() => {
    if (resetForm) {
      setInput({
        employee: "",
        total_salary: "",
        month: currentMonth,
        year: currentYear,
        paid_salary: "",
        date: "",
      });
      setFormErrors({});
      setAdvanceAmount(0);
    }
  }, [resetForm, currentMonth, currentYear]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleEmployeeChange = (event, value) => {
    if (value) {
      setInput({
        ...input,
        employee: value.id,
        total_salary: value.salary_amount, // Automatically set the salary amount
      });
    } else {
      setInput({ ...input, employee: "", total_salary: "" });
      setAdvanceAmount(0);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let isValid = true;
    let errors = {};

    if (!input.employee) {
      errors.employee = t("hr.employeeRequired");
      isValid = false;
    }

    if (!input.total_salary) {
      errors.total_salary = t("hr.totalSalaryRequired");
      isValid = false;
    }

    if (!input.month) {
      errors.month = t("hr.monthRequired");
      isValid = false;
    }

    if (!input.year) {
      errors.year = t("hr.yearRequired");
      isValid = false;
    }

    if (!input.paid_salary) {
      errors.paid_salary = t("hr.paidRequired");
      isValid = false;
    }

    if (!input.date) {
      errors.date = t("hr.dateRequired");
      isValid = false;
    }

    setFormErrors(errors);

    if (isValid) {
      const formData = new FormData();
      formData.append("employee", input.employee);
      formData.append("total_salary", input.total_salary);
      formData.append("month", input.month);
      formData.append("year", input.year);
      formData.append("paid_salary", input.paid_salary);
      formData.append("date", input.date);
      handleAdd(formData);
    }
  };

  return (
    <Container>
      <Paper
        component="form"
        onSubmit={handleSave}
        sx={{
          boxShadow: "0px 0px 0px",
          marginTop: "2rem",
          marginBottom: "2rem",
          padding: "1rem",
        }}
      >
        <Typography fontWeight={500} variant="h4" color="primary">
          {t("hr.payrollManagement")}
        </Typography>

        <Divider sx={{ mt: 2, mb: 2, width: "100%" }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              options={employeeData}
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              onChange={handleEmployeeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  fullWidth
                  label={t("hr.selectEmployee")}
                  error={!!formErrors.employee}
                  helperText={formErrors.employee}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label={t("hr.totalSalary")}
              name="total_salary"
              size="small"
              variant="outlined"
              value={input.total_salary}
              onChange={handleChange}
              error={!!formErrors.total_salary}
              helperText={formErrors.total_salary}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              select
              size="small"
              name="month"
              variant="outlined"
              label={t("hr.selectMonth")}
              value={input.month}
              onChange={handleChange}
              error={!!formErrors.month}
              helperText={formErrors.month}
            >
              {Months.map((monthOption) => (
                <MenuItem key={monthOption.id} value={monthOption.id}>
                  {monthOption.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              select
              fullWidth
              label={t("hr.selectYear")}
              size="small"
              name="year"
              variant="outlined"
              value={input.year}
              onChange={handleChange}
              error={!!formErrors.year}
              helperText={formErrors.year}
            >
              {years.map((yearOption) => (
                <MenuItem key={yearOption} value={yearOption}>
                  {yearOption}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label={t("hr.paidSalary")}
              name="paid_salary"
              size="small"
              variant="outlined"
              value={input.paid_salary}
              onChange={handleChange}
              error={!!formErrors.paid_salary}
              helperText={formErrors.paid_salary}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label={t("hr.remainingSalary")}
              name="remaining_salary"
              size="small"
              variant="outlined"
              disabled
              helperText={t("hr.automaticallyPayment")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              name="date"
              type="date"
              label={t("hr.date")}
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={input.date}
              onChange={handleChange}
              error={!!formErrors.date}
              helperText={formErrors.date}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label={t("hr.advanceAmount")}
              name="advance_amount"
              size="small"
              variant="outlined"
              value={advanceAmount}
              disabled
              helperText={t("hr.advanceAmount")}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="flex-end" mt={3}>
          <Button variant="contained" type="submit" disabled={loading}>
            {loading ? t("hr.save1") : t("hr.save")}
          </Button>
        </Stack>
        <Divider sx={{ mt: 2, width: "100%" }} />
        <PayrollList />
      </Paper>
    </Container>
  );
};

export default Payroll;
