import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  Chip,
  Radio,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import { useAuth } from "../../../../context";
import Loading from "./skeletonLoaiding/Loading";
import AddAppointment from "./addAppointment/AddAppointment";
import useFetchObjects from "../../../../api/useFetchObjects";
import NoRecordMessage from "../../../../Components/GeneralComponents/noRecordMessage/NoRecordMessage";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";
import Print from "./Print";
import EditAppointmentDialog from "./EditAppointmentDialog";

const searchStyle = {
  padding: "14px",
  borderRadius: "10px",
  backgroundColor: "#edf7fa",
  border: "1px solid #ccc",
  ":focus": { outline: "0px", borderColor: "#90caf9" },
};

const customTabsStyle = {
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
    gap: "12px",
  },
  "& .MuiTab-root": {
    minHeight: "48px",
    minWidth: "120px",
    color: "#333",
    fontWeight: "500",
    ":hover": {
      color: "black",
      backgroundColor: "lightGray",
      borderRadius: "8px",
    },
  },
  "& .MuiTab-root.Mui-selected": {
    backgroundColor: "#007174",
    borderRadius: "8px",
    color: "white",
    fontWeight: "700",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
};

const buttonStyle = {
  textTransform: "capitalize",
  fontWeight: "bold",
  bgcolor: "#007bff",
  color: "#fff",
  borderRadius: "8px",
  px: 3,
  transition: "background-color 0.3s ease",
  ":hover": {
    bgcolor: "#0056b3",
  },
};

const ActionSection = ({
  bulkStatus,
  setBulkStatus,
  selectedAppointment,
  handleBulkStatusUpdate,
  isSmallScreen,
}) => {
  const { t } = useTranslation();
  return (
    <Paper
      elevation={3}
      sx={{
        mt: 3,
        p: isSmallScreen ? 2 : 3,
        display: "flex",
        justifyContent: isSmallScreen ? "center" : "space-between",
        alignItems: "center",
        flexDirection: isSmallScreen ? "column" : "row",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        gap: 2,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "500",
          textAlign: isSmallScreen ? "center" : "left",
          mb: isSmallScreen ? 2 : 0,
        }}
      >
        {t("reception.updateStatus")}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isSmallScreen ? "center" : "flex-start",
          gap: 2,
        }}
      >
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>{t("reception.status")} </InputLabel>
          <Select
            value={bulkStatus}
            onChange={(event) => setBulkStatus(event.target.value)}
            label="Status"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "8px",
              "& .MuiOutlinedInput-root:hover": {
                borderColor: "#90caf9",
              },
            }}
          >
            <MenuItem value="">
              <em>--------</em>
            </MenuItem>
            <MenuItem value={1}> {t("reception.pending")} </MenuItem>
            <MenuItem value={2}> {t("reception.completed")}</MenuItem>
            <MenuItem value={3}> {t("reception.canceled")} </MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={handleBulkStatusUpdate}
          disabled={!selectedAppointment}
          sx={{
            height: "36px",
            borderRadius: "8px",
            backgroundColor: "#007bff",
            textTransform: "capitalize",
            px: 3,
            ":hover": {
              backgroundColor: "#0056b3",
            },
          }}
        >
          {t("reception.save")}
        </Button>
      </Box>
    </Paper>
  );
};

const Appointments = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;
  const [open, setOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [bulkStatus, setBulkStatus] = useState("");
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [status, setStatus] = useState(1);
  const [search, setSearch] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const printRef = useRef();
  const [printData, setPrintData] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [editAppointment, setEditAppointment] = useState(null);

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["AppointmentsList"],
    `appointments/?search=${search}&status=${status}`,
    token
  );

  const { handleUpdate } = useUpdate("appointments", token);

  useEffect(() => {
    refetch();
  }, [status, search]);

  const handleStatusChange = (event, newValue) => {
    setValue(newValue);
    setStatus(newValue === 0 ? 1 : newValue === 1 ? 2 : 3);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleRadioChange = (appointmentId) => {
    setSelectedAppointment(appointmentId);
  };

  const handleBulkStatusUpdate = () => {
    handleUpdate(selectedAppointment, { status: bulkStatus });
    setSelectedAppointment(null);
    setBulkStatus("");
    refetch();
  };

  const handlePrintClick = (xray) => {
    setPrintData(xray);
    setTimeout(() => {
      if (printRef.current) {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }, 500);
  };

  const getStatusChip = (status) => {
    switch (status) {
      case 1:
        return <Chip label={t("reception.pending")} color="warning" />;
      case 2:
        return <Chip label={t("reception.completed")} color="success" />;
      case 3:
        return <Chip label={t("reception.canceled")} color="error" />;
      default:
        return <Chip label={t("reception.unknown")} />;
    }
  };

  const handleEditClick = (appointment) => {
    setEditAppointment(appointment);
    setEditOpen(true);
  };

  const handleSaveEdit = (updatedAppointment) => {
    handleUpdate(updatedAppointment.id, updatedAppointment);
    setEditOpen(false);
    refetch();
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isError) {
    return <Typography>{t("reception.error")} </Typography>;
  }
  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <Grid item xs={12} m={2} margin={"20px auto"}>
        <Paper sx={{ boxShadow: "0px 0px 0px" }}>
          <Grid container padding={"20px"}>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Tabs
                value={value}
                onChange={handleStatusChange}
                variant={isSmallScreen ? "scrollable" : "standard"}
                scrollButtons={isSmallScreen ? "auto" : false}
                allowScrollButtonsMobile
                sx={{
                  ...customTabsStyle,
                  width: "100%",
                  overflowX: "auto",
                }}
              >
                <Tab label={t("reception.newOPD")} />
                <Tab label={t("reception.completedOPD")} />
                <Tab label={t("reception.cancelledOPD")} />
              </Tabs>

              <Button
                sx={buttonStyle}
                startIcon={<AddIcon />}
                onClick={() => setOpen(true)}
              >
                {t("reception.newOPD")}
              </Button>
            </Grid>

            {selectedAppointment && (
              <ActionSection
                bulkStatus={bulkStatus}
                setBulkStatus={setBulkStatus}
                selectedAppointment={selectedAppointment}
                handleBulkStatusUpdate={handleBulkStatusUpdate}
                isSmallScreen={isSmallScreen}
              />
            )}

            <Divider sx={{ mt: 2, width: "100%" }} />

            <Grid container mt={2}>
              <Grid
                item
                xs={12}
                md={4}
                component={"input"}
                sx={searchStyle}
                placeholder={t("reception.search")}
                value={search}
                onChange={handleSearchChange}
                autoFocus
              />
            </Grid>

            <TableContainer sx={{ mt: 2 }}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                  <TableRow>
                    <TableCell padding="radio" align="center">
                      {t("reception.select")}
                    </TableCell>
                    <TableCell align="center">{t("reception.time")}</TableCell>
                    <TableCell align="center">{t("reception.date")}</TableCell>
                    <TableCell align="center">
                      {t("reception.patientName")}
                    </TableCell>
                    <TableCell align="center">
                      {t("reception.doctor")}
                    </TableCell>
                    <TableCell align="center">
                      {t("reception.inLine")}
                    </TableCell>
                    <TableCell align="center">
                      {t("reception.status")}
                    </TableCell>
                    <TableCell align="center">{t("reception.fees")}</TableCell>
                    <TableCell align="center">
                      {t("reception.action")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} style={{ textAlign: "center" }}>
                        <NoRecordMessage />
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.results.map((item) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell padding="radio" align="center">
                          <Radio
                            checked={selectedAppointment === item.id}
                            onChange={() => handleRadioChange(item.id)}
                          />
                        </TableCell>
                        <TableCell align="center">{item.timeOfVisit}</TableCell>
                        <TableCell align="center">{item.dateOfVisit}</TableCell>
                        <TableCell align="center">
                          {item.patient_name_display +
                            " " +
                            item.patient_lastname_display}
                        </TableCell>
                        <TableCell align="center">
                          {item.doctor_name_display}
                        </TableCell>
                        <TableCell align="center">
                          {item.appointments_before}
                        </TableCell>
                        <TableCell align="center">
                          {getStatusChip(item.status)}
                        </TableCell>
                        <TableCell align="center">{item.fees}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            color="primary"
                            onClick={() => handlePrintClick(item)}
                          >
                            <PrintIcon />
                          </IconButton>
                          <IconButton
                            color="secondary"
                            onClick={() => handleEditClick(item)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
          {printData && (
            <div ref={printRef} style={{ display: "none" }}>
              <Print appointment={printData} />
            </div>
          )}
        </Paper>
        <AddAppointment open={open} onClose={handleClose} />
        {editAppointment && (
          <EditAppointmentDialog
            open={editOpen}
            onClose={() => setEditOpen(false)}
            appointment={editAppointment}
            onSave={handleSaveEdit}
            token={token}
          />
        )}
      </Grid>
    );
  }
};

export default Appointments;
