import FinanceNav from "../../pages/Dashboard/Finance/FinanceNav";
import FinanceIndex from "../../pages/Dashboard/Finance/FinanceIndex";
import PatientsInFinance from "../../pages/Dashboard/Finance/Patients/Patients";
import Expenses from "../../pages/Dashboard/Finance/Expenses/Expenses";
import EmployeesList from "../../pages/Dashboard/HumanResource/Employees/EmployeesList/EmployeesList";
import ApprovePurchase from "../../pages/Dashboard/Finance/ApprovePurchase/ApprovePurchase";
import Payroll from "../../pages/Dashboard/Finance/Payroll/Payroll";
import Advance from "../../pages/Dashboard/Finance/Advance/Advance";
import Report from "../../pages/Dashboard/Finance/Report/Report";
import ManageExpensesType from "../../pages/Dashboard/Finance/expensesType/ManageExpensesType";
import Laboratory from "../../pages/Dashboard/Finance/Laboratory/Laboratory";
import Echo from "../../pages/Dashboard/Finance/Echo/Echo";
import Ultrasound from "../../pages/Dashboard/Finance/Ultrasound/Ultrasound";
import Xray from "../../pages/Dashboard/Finance/Xray/Xray";

const financeRoutes = {
  path: "/finance",
  element: <FinanceNav />,
  children: [
    { path: "/finance", element: <FinanceIndex /> },
    { path: "/finance/patients", element: <PatientsInFinance /> },
    { path: "/finance/laboratory", element: <Laboratory /> },
    { path: "/finance/echo", element: <Echo /> },
    { path: "/finance/ultrasound", element: <Ultrasound /> },
    { path: "/finance/xray", element: <Xray /> },
    { path: "/finance/expenses-type", element: <ManageExpensesType /> },
    { path: "/finance/expenses", element: <Expenses /> },
    { path: "/finance/payroll", element: <Payroll /> },
    { path: "/finance/advance", element: <Advance /> },
    // { path: "/finance/account/", element: <EmployeesList /> },
    { path: "/finance/approve/", element: <ApprovePurchase /> },
    { path: "/finance/report/", element: <Report /> },
  ],
};

export default financeRoutes;
