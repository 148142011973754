// EditExpenseDialog.js
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";

const EditExpenseDialog = ({ open, onClose, expense }) => {
  const {t}=useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [formData, setFormData] = useState({
    amount: "",
    expenseType: "",
    description: "",
    date: "",
    time: "",
  });

  const { data: expenseTypes } = useFetchObjects(
    ["expense-types"],
    "expense-types/",
    token
  );

  const { handleUpdate } = useUpdate("expenses", token);

  useEffect(() => {
    if (expense) {
      setFormData({
        amount: expense.amount,
        expenseType: expense.expenseType,
        description: expense.description,
        date: expense.date,
        time: expense.time,
      });
    }
  }, [expense]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = () => {
    handleUpdate(expense.id, formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("finance.editExpense")}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          label="Amount"
          name="amount"
          value={formData.amount}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("finance.expenseType")}</InputLabel>
          <Select
            name="expenseType"
            value={formData.expenseType}
            onChange={handleChange}
          >
            {expenseTypes?.results?.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          label={t("finance.description")}
          name="description"
          value={formData.description}
          onChange={handleChange}
          multiline
          rows={4}
        />
        <TextField
          fullWidth
          margin="normal"
          label={t("finance.date")}
          name="date"
          type="date"
          value={formData.date}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          fullWidth
          margin="normal"
          label={t("finance.time")}
          name="time"
          type="time"
          value={formData.time}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
        {t("finance.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" sx={{margin:"0px 10px"}}>
        {t("finance.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditExpenseDialog;
