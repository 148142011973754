import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useFetchObjects from "../../../../api/useFetchObjects";

const EditAppointmentDialog = ({
  open,
  onClose,
  appointment,
  onSave,
  token,
}) => {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useFetchObjects(
    ["users"],
    "users/",
    token
  );

  const [doctor, setDoctor] = useState(appointment?.doctor || "");
  const [sicknessHistory, setSicknessHistory] = useState(
    appointment?.sickness_history || ""
  );
  const [timeOfVisit, setTimeOfVisit] = useState(
    appointment?.timeOfVisit || ""
  );
  const [dateOfVisit, setDateOfVisit] = useState(
    appointment?.dateOfVisit || ""
  );
  const [fees, setFees] = useState(appointment?.fees || 0);
  const [due, setDue] = useState(appointment?.due || 0);
  const [status, setStatus] = useState(appointment?.status || 1);

  const handleSave = () => {
    onSave({
      id: appointment.id,
      doctor,
      sickness_history: sicknessHistory,
      timeOfVisit,
      dateOfVisit,
      fees,
      due,
      status,
    });
    onClose();
  };

  if (isLoading) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t("reception.editAppointment")}</DialogTitle>
        <DialogContent>{t("loading")}</DialogContent>
      </Dialog>
    );
  }

  if (isError) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t("reception.editAppointment")}</DialogTitle>
        <DialogContent>{t("errorFetchingUsers")}</DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("reception.editAppointment")}</DialogTitle>
      <DialogContent>
        <TextField
          select
          label={t("reception.doctor")}
          value={doctor}
          onChange={(e) => setDoctor(e.target.value)}
          fullWidth
          margin="normal"
        >
          {(data || []).map((item, index) => (
            <MenuItem
              key={index}
              value={item.id}
            >{`${item.first_name} ${item.last_name}`}</MenuItem>
          ))}
        </TextField>
        <TextField
          label={t("reception.sickness_history")}
          value={sicknessHistory}
          onChange={(e) => setSicknessHistory(e.target.value)}
          fullWidth
          multiline
          rows={3}
          margin="normal"
        />
        <TextField
          label={t("reception.timeOfVisit")}
          type="time"
          value={timeOfVisit}
          onChange={(e) => setTimeOfVisit(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t("reception.dateOfVisit")}
          type="date"
          value={dateOfVisit}
          onChange={(e) => setDateOfVisit(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t("reception.fees")}
          type="number"
          value={fees}
          onChange={(e) => setFees(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t("reception.due")}
          type="number"
          value={due}
          onChange={(e) => setDue(e.target.value)}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("reception.status")}</InputLabel>
          <Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            label={t("reception.status")}
          >
            <MenuItem value={1}>{t("reception.pending")}</MenuItem>
            <MenuItem value={2}>{t("reception.completed")}</MenuItem>
            <MenuItem value={3}>{t("reception.canceled")}</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          color="error"
          size="small"
        >
          {t("reception.cancel")}
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="success"
          size="small"
        >
          {t("reception.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAppointmentDialog;
