import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const EditAssignedRoomDialog = ({ open, onClose, onSave, assignment }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    discharge_date: "",
    discount: 0,
    is_paid: false,
    is_active: false,
  });

  useEffect(() => {
    if (assignment) {
      setFormData({
        discharge_date: assignment.discharge_date
          ? dayjs(assignment.discharge_date).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"),
        discount: assignment.discount || "",
        is_paid: assignment.is_paid || false,
        is_active: assignment.is_active || false,
      });
    }
  }, [assignment]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSave = () => {
    onSave(formData);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("room.editAssignment")}</DialogTitle>
      <DialogContent>
        {/* Discharge Date */}
        <TextField
          label={t("room.dischargeDate")}
          type="date"
          name="discharge_date"
          fullWidth
          margin="normal"
          value={formData.discharge_date}
          onChange={handleInputChange}
          InputLabelProps={{
            shrink: true,
          }}
        />

        {/* Discount Amount */}
        <TextField
          label={t("room.discountAmount")}
          type="number"
          name="discount"
          fullWidth
          margin="normal"
          value={formData.discount || 0}
          onChange={handleInputChange}
        />

        {/* Payment Status */}
        <FormControlLabel
          control={
            <Switch
              checked={formData.is_paid}
              name="is_paid"
              onChange={handleSwitchChange}
              color="primary"
            />
          }
          label={t("room.paymentStatus")}
        />

        {/* Room Status */}
        <FormControlLabel
          control={
            <Switch
              checked={formData.is_active}
              name="is_active"
              onChange={handleSwitchChange}
              color="primary"
            />
          }
          label={t("room.status")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          {t("room.cancel")}
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          {t("room.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAssignedRoomDialog;
