import {
  Divider,
  Grid,
  Pagination,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import useFetchObjects from "../../../../../api/useFetchObjects";
import { useAuth } from "../../../../../context";
import Loading from "./skeletonLoading/Loading";
import ErrorPage from "../../../../../Components/GeneralComponents/errorPage/ErrorPage";
import useDelete from "../../../../../api/useDelete";
import useUpdate from "../../../../../api/useUpdate";
import { useTranslation } from "react-i18next";

const UpdateDepartmentDialog = ({ open, onClose, department, onUpdate }) => {
  const { t } = useTranslation();
  const [name, setName] = useState(department?.name || "");
  const [code, setCode] = useState(department?.code || "");

  const handleSubmit = () => {
    onUpdate(department.id, { name, code });
    onClose();
  };

  useEffect(() => {
    if (department) {
      setName(department.name);
      setCode(department.code);
    }
  }, [department]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "500px",
          maxWidth: "100%",
        },
      }}
    >
      <DialogTitle>{t("hr.updateDepartment")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("hr.department")}
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          label={t("hr.departmentCode")}
          fullWidth
          variant="outlined"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("hr.cancel")}</Button>
        <Button onClick={handleSubmit} color="primary">
          {t("hr.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DepartmentsList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [value, setValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["departments"],
    "departments",
    token
  );
  const { handleDelete, ConfirmDialog } = useDelete("departments", token);
  const { handleUpdate } = useUpdate(`departments`, token, "");

  useEffect(() => {
    refetch();
  }, [refetch]);

  const openUpdateDialog = (department) => {
    setSelectedDepartment(department);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedDepartment(null);
  };

  const handleUpdateDepartment = (id, data) => {
    handleUpdate(id, data);
    refetch();
  };

  if (isLoading) return <Loading />;
  if (isError)
    return (
      <Grid container>
        <ErrorPage />
      </Grid>
    );

  return (
    <Grid item xs={12}>
      <Paper sx={{ boxShadow: "0px 0px 0px" }}>
        <Grid container padding={"10px"}>
          <Grid item>
            <Tabs
              value={value}
              onChange={(event, newValue) => setValue(newValue)}
              aria-label="tabs"
            >
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={t("hr.departments")}
              />
            </Tabs>
          </Grid>
          <Divider sx={{ mt: 2, width: "100%" }} />
          <TableContainer sx={{ mt: 2 }}>
            <Table sx={{ minWidth: "100%" }} aria-label="departments table">
              <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                <TableRow>
                  <TableCell align="center">{t("hr.departmentCode")}</TableCell>
                  <TableCell align="center">{t("hr.department")}</TableCell>
                  <TableCell align="center">
                    {t("hr.numberOfEmployees")}
                  </TableCell>
                  <TableCell align="center">{t("hr.action")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {item.code}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {item.name}
                    </TableCell>
                    <TableCell align="center">{item.total_employees}</TableCell>
                    <TableCell align="center">
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={() => openUpdateDialog(item)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Grid container justifyContent={"center"} margin={"18px 0px"}>
              <Pagination
                count={5}
                page={1}
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </TableContainer>
        </Grid>
        <ConfirmDialog />
        <UpdateDepartmentDialog
          open={openDialog}
          onClose={handleDialogClose}
          department={selectedDepartment}
          onUpdate={handleUpdateDepartment}
        />
      </Paper>
    </Grid>
  );
};

export default DepartmentsList;
