import { Box, Grid, Paper, Tabs, Tab } from "@mui/material";
import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DoctorNav = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("/doctor");
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    const path = location.pathname;
    const validPaths = [
      "/doctor",
      "/doctor/patients",
      "/doctor/prescription",
      "/doctor/add-task-to-nurse",
    ];
    if (validPaths.includes(path)) {
      setValue(path);
    } else {
      setValue("/doctor");
    }
  }, [location.pathname]);

  const tabs = [
    { path: "/doctor", label: t("doctor.dashboard") },
    { path: "/doctor/patients", label: t("doctor.patients") },
    { path: "/doctor/prescription", label: t("doctor.prescription") },
    { path: "/doctor/add-task-to-nurse", label: t("doctor.assignTask") },
  ];

  return (
    <Grid container maxWidth="xl">
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Paper sx={{ padding: "10px 0px", boxShadow: "0px 0px 0px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              ml: "5px",
              "& .MuiTabs-indicator": { display: "none" },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                style={{
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  backgroundColor: value === tab.path ? "#007174" : "white",
                  color: value === tab.path ? "white" : "black",
                }}
                label={tab.label}
                value={tab.path}
                component={NavLink}
                to={tab.path}
              />
            ))}
          </Tabs>
        </Paper>
      </Box>
      <Outlet />
    </Grid>
  );
};

export default DoctorNav;
