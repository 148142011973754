import React, { useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Box,
  Chip,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrintIcon from "@mui/icons-material/Print";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useDelete from "../../../../api/useDelete";
import PrescriptionDetailsEdit from "./PrescriptionDetailsEdit";
import { useTranslation } from "react-i18next";
import PrescriptionPrint from "./PrescriptionPrint";

const PrescriptionList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { handleDelete: handleDeletePrescription, ConfirmDialog } = useDelete(
    "prescriptions",
    token
  );
  const {
    handleDelete: handleDeletePrescriptionItem,
    ConfirmDialog: ConfirmDialogItem,
  } = useDelete("prescription-details", token);

  const { data, isLoading, isError, isSuccess, error } = useFetchObjects(
    ["prescriptions"],
    `prescriptions/?doctor=${auth?.user?.user?.id}`,
    token
  );

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [printData, setPrintData] = useState(null);
  const printRef = useRef();

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  const handlePrintClick = (prescription) => {
    setPrintData(prescription);
    setTimeout(() => {
      if (printRef.current) {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }, 500);
  };

  const getPaymentStatusLabel = (status) => {
    switch (status) {
      case 1:
        return t("pharmacy.pending");
      case 2:
        return t("pharmacy.paid");
      case 3:
        return t("pharmacy.canceled");
      default:
        return t("pharmacy.unknown");
    }
  };

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case 1:
        return "warning";
      case 2:
        return "success";
      case 3:
        return "error";
      default:
        return "default";
    }
  };

  if (isLoading) {
    return <Typography>{t("pharmacy.loading")}</Typography>;
  }

  if (isError) {
    return <Typography>{error.message}</Typography>;
  }

  if (isSuccess) {
    return (
      <Grid container spacing={3} padding="20px" mt={3}>
        {data.map((prescription, index) => {
          const isPaid = prescription.payment_status === 2;
          return (
            <Grid item xs={12} md={6} key={index}>
              <Paper
                elevation={isPaid ? 2 : 4}
                sx={{
                  mb: 3,
                  padding: "24px",
                  borderRadius: "12px",
                  width: "100%",
                  boxShadow: isPaid
                    ? "0px 4px 12px rgba(0, 0, 0, 0.1)"
                    : "0px 8px 24px rgba(255, 0, 0, 0.3)",
                  backgroundColor: isPaid ? "#f5f5f5" : "#fff9f5",
                  borderLeft: !isPaid ? "4px solid #ff1744" : "none",
                }}
              >
                {/* Prescription Header */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="primary"
                    sx={{ fontSize: isSmallScreen ? "1rem" : "1.25rem" }}
                  >
                    {t("pharmacy.prescription")} #{prescription.id} -{" "}
                    {prescription.date}
                  </Typography>
                  <Chip
                    label={getPaymentStatusLabel(prescription.payment_status)}
                    color={getPaymentStatusColor(prescription.payment_status)}
                    sx={{
                      fontWeight: "bold",
                      fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                    }}
                  />
                  <Stack direction="row" justifyContent="flex-end">
                    <Tooltip title={t("pharmacy.print_prescription")}>
                      <IconButton
                        color="primary"
                        onClick={() => handlePrintClick(prescription)}
                      >
                        <PrintIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>

                {/* Prescription Info */}
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Typography
                    fontSize={isSmallScreen ? "0.85rem" : "1rem"}
                    color={!isPaid ? "error.main" : "text.primary"}
                  >
                    <strong>{t("pharmacy.doctor")}:</strong>{" "}
                    {prescription.doctor_display}
                  </Typography>
                  <Typography fontSize={isSmallScreen ? "0.85rem" : "1rem"}>
                    <strong>{t("pharmacy.patient")}:</strong>{" "}
                    {prescription.patient_display}
                  </Typography>
                  <Typography fontSize={isSmallScreen ? "0.85rem" : "1rem"}>
                    <strong>{t("pharmacy.total_price")}:</strong>{" "}
                    {prescription.total_price} ؋
                  </Typography>
                </Stack>

                {/* Action Buttons */}
                {!isPaid && (
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <Tooltip title={t("pharmacy.delete_prescription")}>
                      <IconButton
                        color="error"
                        onClick={() =>
                          handleDeletePrescription(prescription.id)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}

                <Divider sx={{ mt: 2, mb: 2 }} />

                {/* Accordion for Prescription Details (Medicines) */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      fontWeight="bold"
                      color={!isPaid ? "error.main" : "text.primary"}
                    >
                      {t("pharmacy.medicines")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {prescription.drugs && prescription.drugs.length > 0 ? (
                      <Box sx={{ overflowX: "auto" }}>
                        <Table sx={{ minWidth: 650 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t("pharmacy.category")}</TableCell>
                              <TableCell>{t("pharmacy.medicine")}</TableCell>
                              <TableCell>{t("pharmacy.dosage")}</TableCell>
                              <TableCell>
                                {t("pharmacy.unitsPerMeal")}
                              </TableCell>
                              <TableCell>{t("pharmacy.quantity")}</TableCell>
                              <TableCell>{t("pharmacy.notation")}</TableCell>
                              <TableCell>
                                {t("pharmacy.is_before_meal")}
                              </TableCell>
                              <TableCell align="center">
                                {t("pharmacy.actions")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {prescription.drugs.map((item) => (
                              <TableRow
                                key={item.id}
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#f0f4f8",
                                  },
                                }}
                              >
                                <TableCell>
                                  <Typography>
                                    {item.stock_medicine_category_display}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>
                                    {item.stock_medicine_display}
                                  </Typography>
                                </TableCell>
                                <TableCell>{item.dosage}</TableCell>
                                <TableCell>{item.units_per_meal}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>{item.notation}</TableCell>
                                <TableCell>{item.is_before}</TableCell>
                                <TableCell align="center">
                                  {!isPaid && (
                                    <>
                                      <Tooltip title={t("pharmacy.edit_item")}>
                                        <IconButton
                                          color="primary"
                                          onClick={() => handleOpen(item)}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title={t("pharmacy.delete_item")}
                                      >
                                        <IconButton
                                          color="error"
                                          onClick={() =>
                                            handleDeletePrescriptionItem(
                                              item.id
                                            )
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    ) : (
                      <Typography>{t("pharmacy.no_medicines")}</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </Grid>
          );
        })}

        <ConfirmDialog />
        <ConfirmDialogItem />

        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle>
            <Typography fontWeight="bold" color="primary">
              {t("pharmacy.edit_prescription_item")}
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ padding: "20px" }}>
            {selectedItem && (
              <PrescriptionDetailsEdit
                prescriptionDetail={selectedItem}
                onClose={handleClose}
                token={token}
              />
            )}
          </DialogContent>
        </Dialog>

        {/* Print Section (Hidden) */}
        {printData && (
          <div ref={printRef} style={{ display: "none" }}>
            <PrescriptionPrint data={printData} />
          </div>
        )}
      </Grid>
    );
  }
};

export default PrescriptionList;
