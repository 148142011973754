import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Alert,
  CircularProgress,
  Stack,
  Grid,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { useParams, useNavigate } from "react-router-dom";
import useFetchObjects from "../../../../api/useFetchObjects";
import useDelete from "../../../../api/useDelete";
import useAdd from "../../../../api/useAdd";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../context";

const PatientTestResults = () => {
  const { t } = useTranslation();
  const { id: orderId } = useParams();
  const navigate = useNavigate();
  const token = useAuth()?.user?.token;

  const {
    data: orderData,
    isLoading,
    isError,
  } = useFetchObjects(
    ["laboratory-test-orders"],
    `laboratory-test-orders/${orderId}/`,
    token
  );

  const { handleDelete, ConfirmDialog } = useDelete(
    "laboratory-patient-tests",
    token
  );

  const { handleAdd, loading: addLoading } = useAdd(
    "laboratory-results",
    token
  );
  const { handleUpdate, loading: updateLoading } = useUpdate(
    "laboratory-results",
    token
  );

  const [results, setResults] = useState({});
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (orderData?.tests_display) {
      const initialResults = {};
      orderData.tests_display.forEach((test) => {
        initialResults[test.id] = {
          value: test.test_type_display.result_value || "",
          resultId: test.test_type_display.result_id || null,
        };
      });
      setResults(initialResults);
    }
  }, [orderData]);

  const handleResultChange = (testId, value) => {
    setResults((prevResults) => ({
      ...prevResults,
      [testId]: { ...prevResults[testId], value },
    }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [testId]: "" }));
  };

  const handleSaveResults = async () => {
    // Filter out parent tests
    const childResults = Object.entries(results).filter(([testId]) => {
      const test = orderData.tests_display.find(
        (t) => t.id === parseInt(testId)
      );
      return test?.parent_test !== null; // Exclude parent tests
    });

    // Check for missing values in child tests
    const missingResults = childResults.filter(([_, { value }]) => !value);

    if (missingResults.length > 0) {
      setFormErrors(
        missingResults.reduce(
          (acc, [testId]) => ({
            ...acc,
            [testId]: t("laboratory.resultRequired"),
          }),
          {}
        )
      );
      return;
    }

    try {
      // Prepare promises only for child tests
      const updatePromises = childResults.map(
        async ([testId, { value, resultId }]) => {
          const resultData = {
            test: testId,
            result_value: parseFloat(value),
          };

          if (resultId) {
            await handleUpdate(resultId, resultData);
          } else {
            await handleAdd({ results: [resultData] });
          }
        }
      );

      await Promise.all(updatePromises);
      navigate(-1);
    } catch (error) {
      console.error("Error saving results:", error);
    }
  };

  // Filter child tests (exclude tests with `parent_test === null`)
  const childTests = orderData?.tests_display?.filter(
    (test) => test.parent_test !== null
  );

  // Group tests by parent_test
  const groupedTests = childTests?.reduce((acc, test) => {
    const parentId = test.parent_test.id;
    if (!acc[parentId]) {
      acc[parentId] = {
        parent: test.parent_test.name,
        tests: [],
      };
    }
    acc[parentId].tests.push(test);
    return acc;
  }, {});

  return (
    <Box
      sx={{
        width: "100%",
        p: 4,
        bgcolor: "#f4f6f8",
        borderRadius: "12px",
        boxShadow: 2,
      }}
    >
      <Typography variant="h5" fontWeight="bold" color="primary" gutterBottom>
        {t("laboratory.testResults")}
      </Typography>

      {/* Patient Information Section */}
      <Box
        sx={{
          p: 3,
          bgcolor: "white",
          borderRadius: 2,
          boxShadow: 1,
          mb: 4,
          borderLeft: "5px solid #007174",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              <strong>{t("laboratory.patientName")}:</strong>{" "}
              {orderData?.patient_display?.name || t("laboratory.noData")}
            </Typography>
            <Typography variant="subtitle1">
              <strong>{t("laboratory.patientId")}:</strong>{" "}
              {orderData?.patient_display?.id || t("laboratory.noData")}
            </Typography>
            <Typography variant="subtitle1">
              <strong>{t("laboratory.orderDate")}:</strong>{" "}
              {new Date(orderData?.order_date).toLocaleDateString() ||
                t("laboratory.noData")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              <strong>{t("laboratory.doctorName")}:</strong>{" "}
              {orderData?.doctor_display?.username || t("laboratory.noData")}
            </Typography>
            <Typography variant="subtitle1">
              <strong>{t("laboratory.urgency")}:</strong>{" "}
              {orderData?.urgency
                ? t("laboratory.urgent")
                : t("laboratory.routine")}
            </Typography>
            <Typography variant="subtitle1">
              <strong>{t("laboratory.status")}:</strong>{" "}
              {orderData?.is_paid
                ? t("laboratory.paid")
                : t("laboratory.pending")}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Total Amount Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          bgcolor: "#e8f5e9",
          borderRadius: 2,
          boxShadow: 1,
          mb: 4,
        }}
      >
        <Typography variant="h6" fontWeight="bold" color="success.main">
          {t("laboratory.totalAmount")}: {orderData?.total_amount || "0.00"}؋
        </Typography>
        <Divider sx={{ mx: 2 }} />
        <Typography variant="subtitle1" color="text.secondary">
          {t("laboratory.discount")}: {orderData?.discount || "0.00"}؋
        </Typography>
      </Box>

      {isLoading && (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      )}

      {isError && (
        <Alert severity="error" sx={{ mt: 4 }}>
          {t("laboratory.errorFetchingTests")}
        </Alert>
      )}

      {/* Grouped Test Results */}
      {groupedTests ? (
        Object.entries(groupedTests).map(([parentId, group]) => (
          <Box key={parentId} sx={{ mb: 4 }}>
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
              {group.parent}
            </Typography>
            <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#007174" }}>
                    <TableCell sx={{ color: "#ffffff", fontWeight: "bold" }}>
                      #
                    </TableCell>
                    <TableCell sx={{ color: "#ffffff", fontWeight: "bold" }}>
                      {t("laboratory.testName")}
                    </TableCell>
                    <TableCell sx={{ color: "#ffffff", fontWeight: "bold" }}>
                      {t("laboratory.resultValue")}
                    </TableCell>
                    <TableCell sx={{ color: "#ffffff", fontWeight: "bold" }}>
                      {t("laboratory.normalRange")} ({t("laboratory.units")})
                    </TableCell>
                    <TableCell sx={{ color: "#ffffff", fontWeight: "bold" }}>
                      {t("laboratory.action")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {group.tests.map((test, index) => (
                    <TableRow key={test.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{test.test_type_display.name}</TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          size="small"
                          value={results[test.id]?.value || ""}
                          error={!!formErrors[test.id]}
                          helperText={formErrors[test.id]}
                          onChange={(e) =>
                            handleResultChange(test.id, e.target.value)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {test.test_type_display.min_value} -{" "}
                        {test.test_type_display.max_value}{" "}
                        {test.test_type_display.units}
                      </TableCell>
                      <TableCell>
                        <Tooltip title={t("laboratory.deleteTest")}>
                          <IconButton
                            color="error"
                            onClick={() => handleDelete(test.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))
      ) : (
        <Alert severity="info" sx={{ mt: 4 }}>
          {t("laboratory.noTests")}
        </Alert>
      )}

      <ConfirmDialog />

      <Stack direction="row" justifyContent="flex-end" spacing={2} mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveResults}
          startIcon={<SaveIcon />}
          disabled={addLoading || updateLoading}
        >
          {addLoading || updateLoading
            ? t("laboratory.saving")
            : t("laboratory.saveResults")}
        </Button>
        <Button variant="outlined" onClick={() => navigate(-1)}>
          {t("laboratory.back")}
        </Button>
      </Stack>
    </Box>
  );
};

export default PatientTestResults;
