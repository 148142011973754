import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EventAvailableIcon from "@mui/icons-material/EventAvailable"; // for Total Appointments
import QueueIcon from "@mui/icons-material/Queue"; // for Queued Patients
import HotelIcon from "@mui/icons-material/Hotel"; // for Available Beds
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import SkeletonLoading from "./loading/SkeletonLoading";
import { useTranslation } from "react-i18next";

const gridItemCenterStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#007174",
  padding: "25px 5px",
  borderRadius: "10px",
  color: "white",
};

const DoctorActivityOverView = () => {
  const { t } = useTranslation();
  const [option, setOption] = useState("daily");
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data, isLoading, isError, error } = useFetchObjects(
    ["doctor-report", option],
    `doctor-report/?type=${option}`,
    token
  );

  const handleChangeOption = (event) => {
    setOption(event.target.value || "daily");
  };

  return (
    <Paper sx={{ boxShadow: "0px 0px 0px", width: "100%" }}>
      <Grid container padding={"20px"}>
        <Grid
          item
          xs={11}
          md={11}
          display={"flex"}
          margin={"0px auto"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Typography>{t("overview.doctorOverview")}</Typography>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="report-type-select-label">
                {t("overview.option")}
              </InputLabel>
              <Select
                labelId="report-type-select-label"
                id="report-type-select"
                value={option}
                onChange={handleChangeOption}
                label={t("overview.option")}
              >
                <MenuItem value="daily">{t("overview.daily")}</MenuItem>
                <MenuItem value="weekly">{t("overview.weekly")}</MenuItem>
                <MenuItem value="monthly">{t("overview.monthly")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {isLoading ? (
          <SkeletonLoading /> // Show Skeleton Loading while fetching data
        ) : (
          <Grid container gap={3} mt={2} justifyContent={"center"}>
            {/* Total Appointments */}
            <Grid item xs={12} sm={2.5} lg={5} sx={gridItemCenterStyle}>
              <EventAvailableIcon fontSize="large" />
              {isError ? (
                <Typography>
                  {t("overview.error")}: {error.message}
                </Typography>
              ) : (
                <>
                  <Typography
                    textAlign={"center"}
                    sx={{ fontWeight: "bold", margin: "5px 0px" }}
                  >
                    {data?.total_appointments ?? 0}
                  </Typography>
                  <Typography textAlign={"center"}>
                    {t("overview.totalAppointments")}
                  </Typography>
                </>
              )}
            </Grid>

            {/* Total Inline Appointments */}
            <Grid item xs={12} sm={2.5} lg={5} sx={gridItemCenterStyle}>
              <QueueIcon fontSize="large" />
              {isError ? (
                <Typography>
                  {t("overview.error")}: {error.message}
                </Typography>
              ) : (
                <>
                  <Typography
                    textAlign={"center"}
                    sx={{ fontWeight: "bold", margin: "5px 0px" }}
                  >
                    {data?.total_inline_appointments ?? 0}
                  </Typography>
                  <Typography textAlign={"center"}>
                    {t("overview.queuedPatients")}
                  </Typography>
                </>
              )}
            </Grid>

            {/* Available Beds */}
            <Grid item xs={12} sm={2.5} lg={5} sx={gridItemCenterStyle}>
              <HotelIcon fontSize="large" />
              {isError ? (
                <Typography>
                  {t("overview.error")}: {error.message}
                </Typography>
              ) : (
                <>
                  <Typography
                    textAlign={"center"}
                    sx={{ fontWeight: "bold", margin: "5px 0px" }}
                  >
                    {data?.available_beds ?? 0}
                  </Typography>
                  <Typography textAlign={"center"}>
                    {t("overview.availableBeds")}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default DoctorActivityOverView;
