import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";

const AttendanceReport2 = () => {
  const { t } = useTranslation();
  const { user: userStorage } = useAuth();
  const token = userStorage?.token;

  const MonthOptions = [
    { id: "", value: t("hr.allMonths") },
    { id: 1, value: "January" },
    { id: 2, value: "February" },
    { id: 3, value: "March" },
    { id: 4, value: "April" },
    { id: 5, value: "May" },
    { id: 6, value: "June" },
    { id: 7, value: "July" },
    { id: 8, value: "August" },
    { id: 9, value: "September" },
    { id: 10, value: "October" },
    { id: 11, value: "November" },
    { id: 12, value: "December" },
  ];

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // JS months are 0-indexed, so add 1

  const startYear = 2023;
  const Years = [
    { id: "", value: t("hr.allYears") },
    ...Array.from({ length: currentYear - startYear + 1 }, (_, index) => ({
      id: startYear + index,
      value: startYear + index,
    })),
  ];

  const [user, setUser] = useState("");
  const [year, setYear] = useState(currentYear); // Default to current year
  const [month, setMonth] = useState(currentMonth); // Default to current month
  const [userAttendanceData, setUserAttendanceData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [totals, setTotals] = useState({ present: 0, absent: 0 });

  // Fetch users
  const { data: usersData, isLoading: isLoadingUsers } = useFetchObjects(
    ["attendance-users"],
    "attendance-users",
    token
  );

  const {
    data: recordsData,
    refetch,
    isLoading: isLoadingRecords,
  } = useFetchObjects(
    ["attendance-records", user, year, month],
    `attendance-records/?user_id=${user}${year ? `&year=${year}` : ""}${
      month ? `&month=${month}` : ""
    }`,
    token
  );

  useEffect(() => {
    if (usersData) {
      const formattedUsers = Object.entries(usersData).map(([key, value]) => ({
        user_id: key,
        name: value,
      }));
      setUserAttendanceData(formattedUsers);
    }
  }, [usersData]);

  useEffect(() => {
    if (recordsData) {
      const formattedData = recordsData.map((record, index) => ({
        number: index + 1,
        user: record.user_name,
        date: record.date,
        time: record.time,
        attendanceType: record.attendanceType,
        status: record.status,
      }));
      setAttendanceData(formattedData);
    }
  }, [recordsData]);

  const handleFilter = () => {
    refetch();
  };

  const exportToExcel = () => {
    // Calculate totals based on the specified logic
    let totalPresent = 0;
    let totalAbsent = 0;

    const dateWiseData = attendanceData.reduce((acc, record) => {
      if (!acc[record.date]) acc[record.date] = [];
      acc[record.date].push(record);
      return acc;
    }, {});

    Object.values(dateWiseData).forEach((records) => {
      const checkInRecord = records.find(
        (r) => r.attendanceType === "check_in"
      );
      const checkOutRecord = records.find(
        (r) => r.attendanceType === "check_out"
      );

      if (
        checkInRecord?.status === "Present" &&
        checkOutRecord?.status === "Present"
      ) {
        totalPresent++;
      } else {
        totalAbsent++;
      }
    });

    const ws = XLSX.utils.json_to_sheet(attendanceData);

    // Add totals for present and absent at the end of the sheet
    XLSX.utils.sheet_add_aoa(ws, [[t("hr.totalPresent"), totalPresent]], {
      origin: -1,
    });
    XLSX.utils.sheet_add_aoa(ws, [[t("hr.totalAbsent"), totalAbsent]], {
      origin: -1,
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Attendance");
    XLSX.writeFile(wb, "attendance.xlsx");
  };

  const exportToPDF = () => {
    // Calculate totals based on the specified logic
    let totalPresent = 0;
    let totalAbsent = 0;

    const dateWiseData = attendanceData.reduce((acc, record) => {
      if (!acc[record.date]) acc[record.date] = [];
      acc[record.date].push(record);
      return acc;
    }, {});

    Object.values(dateWiseData).forEach((records) => {
      const checkInRecord = records.find(
        (r) => r.attendanceType === "check_in"
      );
      const checkOutRecord = records.find(
        (r) => r.attendanceType === "check_out"
      );

      if (
        checkInRecord?.status === "Present" &&
        checkOutRecord?.status === "Present"
      ) {
        totalPresent++;
      } else {
        totalAbsent++;
      }
    });

    const doc = new jsPDF();

    // Add table with attendance data
    doc.autoTable({
      head: [["#", "User", "Date", "Time", "Attendance Type", "Status"]],
      body: attendanceData.map((record) => [
        record.number,
        record.user,
        record.date,
        record.time,
        record.attendanceType,
        record.status,
      ]),
      startY: 20,
    });

    // Add totals for present and absent below the table
    doc.text(
      `${t("hr.totalPresent")}: ${totalPresent}`,
      14,
      doc.autoTable.previous.finalY + 10
    );
    doc.text(
      `${t("hr.totalAbsent")}: ${totalAbsent}`,
      14,
      doc.autoTable.previous.finalY + 20
    );

    doc.save("attendance.pdf");
  };

  return (
    <Container>
      <Paper sx={{ padding: "1rem", marginTop: "2rem" }}>
        <Typography variant="h4" color="primary" fontWeight={500}>
          {t("hr.attendanceReport")}
        </Typography>
        <Grid container spacing={3} sx={{ marginTop: "1rem" }}>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label={t("hr.selectUser")}
              fullWidth
              value={user}
              onChange={(e) => setUser(e.target.value)}
            >
              {userAttendanceData.map((item) => (
                <MenuItem key={item.user_id} value={item.user_id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label={t("hr.year")}
              fullWidth
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              {Years.map((yearOption) => (
                <MenuItem key={yearOption.id} value={yearOption.id}>
                  {yearOption.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label={t("hr.month")}
              fullWidth
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              {MonthOptions.map((monthOption) => (
                <MenuItem key={monthOption.id} value={monthOption.id}>
                  {monthOption.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} textAlign="right">
            <Button variant="contained" color="primary" onClick={handleFilter}>
              {t("hr.filter")}
            </Button>
          </Grid>
        </Grid>

        {isLoadingUsers || isLoadingRecords ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box display="flex" justifyContent="flex-end" mb={2} mt={2}>
              <Button
                onClick={exportToExcel}
                variant="contained"
                sx={{ mr: 2 }}
              >
                {t("hr.exportToExcel")}
              </Button>
              <Button
                onClick={exportToPDF}
                variant="contained"
                color="secondary"
              >
                {t("hr.exportToPDF")}
              </Button>
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>{t("hr.user")}</TableCell>
                    <TableCell>{t("hr.date")}</TableCell>
                    <TableCell>{t("hr.time")}</TableCell>
                    <TableCell>{t("hr.attendanceType")}</TableCell>
                    <TableCell>{t("hr.status")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendanceData.map((row) => (
                    <TableRow key={row.number}>
                      <TableCell>{row.number}</TableCell>
                      <TableCell>{row.user}</TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>{row.time}</TableCell>
                      <TableCell>{row.attendanceType}</TableCell>
                      <TableCell>{row.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default AttendanceReport2;
