import { Grid } from "@mui/material";
import React from "react";
import ActivityOverView from "./ActivityOverView/ActivityOverView";
import Appointments from "./Appointments/Appointments";
import useFetchObjects from "../../../api/useFetchObjects";
import { useAuth } from "../../../context";
import IndexSkeleton from "./skeletonLoading/IndexSkeleton";
import ErrorPage from "../../../Components/GeneralComponents/errorPage/ErrorPage";

const PharmacyIndex = () => {
  const auth = useAuth();
  const token = auth?.user?.token;
  const { data, isLoading, isSuccess, isError, error } = useFetchObjects(
    ["top-four-medicine-sold"],
    "top-four-sold-medicines",
    token
  );

  if (isLoading) {
    return <IndexSkeleton />;
  }

  if (isError) {
    return <ErrorPage errorMessage={error.message} />;
  }

  if (isSuccess) {
    return (
      <Grid container maxWidth="xl" mb={3}>
        <Grid container gap={1} mt={2} justifyContent={"center"}>
          <Grid item xs={12} md={12} lg={4.5}>
            <Grid container>
              <ActivityOverView />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Appointments />
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

export default PharmacyIndex;
