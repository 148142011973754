import React, { useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import useUpdate from "../../../../api/useUpdate";
import { useAuth } from "../../../../context";

const EditRoom = ({ roomData, onClose }) => {
  const auth = useAuth();
  const token = auth?.user?.token;

  const [formData, setFormData] = useState({
    id: roomData.id,
    room_number: roomData.room_number,
    floor: roomData.floor,
    is_available: roomData.is_available,
    price_per_night: roomData.price_per_night,
    number_of_bed: roomData.number_of_bed,
    room_type: roomData.room_type,
    total_beds_available: roomData.total_beds_available,
  });

  const { handleUpdate, loading } = useUpdate("rooms", token);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "is_available" ? value === "true" : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    handleUpdate(formData.id, formData);
    onClose();
  };

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: "#ffffff",
        maxWidth: 600,
        margin: "auto",
      }}
    >
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Room Number"
          name="room_number"
          value={formData.room_number}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
          required
        />

        <TextField
          fullWidth
          label="Floor"
          name="floor"
          type="number"
          value={formData.floor}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
          required
        />

        <TextField
          fullWidth
          label="Price per Night"
          name="price_per_night"
          type="number"
          value={formData.price_per_night}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
          required
        />

        <TextField
          fullWidth
          label="Number of Beds"
          name="number_of_bed"
          type="number"
          value={formData.number_of_bed}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
          required
        />

        <TextField
          fullWidth
          label="Total Beds Available"
          name="total_beds_available"
          type="number"
          value={formData.total_beds_available}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
          required
        />

        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <FormLabel component="legend" sx={{ fontWeight: "bold" }}>
            Availability
          </FormLabel>
          <RadioGroup
            row
            name="is_available"
            value={formData.is_available ? "true" : "false"}
            onChange={handleChange}
          >
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label="Available"
            />
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label="Not Available"
            />
          </RadioGroup>
        </FormControl>

        <TextField
          fullWidth
          label="Room Type"
          name="room_type"
          value={formData.room_type}
          onChange={handleChange}
          variant="outlined"
          sx={{ mb: 2 }}
          select
          required
        >
          <MenuItem value={1}>Normal</MenuItem>
          <MenuItem value={2}>Vip</MenuItem>

          {/* Add more room types as needed */}
        </TextField>

        <Button variant="contained" color="primary" type="submit">
          {loading ? <CircularProgress /> : "Update Room"}
        </Button>
      </form>
    </Box>
  );
};

export default EditRoom;
