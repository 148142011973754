import React from "react";
import {
  Skeleton,
  Grid,
  Paper,
  Box,
  Tab,
  Tabs,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const Loading = () => {
  return (
    <Grid item xs={10} m={2} margin={"20px auto"}>
      <Paper sx={{ boxShadow: "0px 0px 0px" }}>
        <Grid container padding={"20px"}>
          <Grid container justifyContent={"space-between"}>
            <Box>
              <Tabs value={0} aria-label="scrollable force tabs example">
                <Tab label={<Skeleton width={120} />} />
                <Tab label={<Skeleton width={180} />} />
              </Tabs>
            </Box>
            <Button
              sx={{
                textTransform: "capitalize",
                fontWeight: "bold",
                bgcolor: "#071952",
              }}
              variant="contained"
              startIcon={<AddIcon />}
              disabled
            >
              <Skeleton width={160} />
            </Button>
          </Grid>
          <Divider sx={{ mt: 1, width: "100%" }} />
          <Grid container mt={2}>
            <Grid
              item
              xs={12}
              md={4}
              component={"input"}
              sx={{
                padding: "14px",
                borderRadius: "17px",
                backgroundColor: "#cdf6f7",
                border: "0px",
                ":focus": { outline: "0px" },
              }}
              placeholder="Search..."
              disabled
            />
          </Grid>
          <TableContainer sx={{ mt: 2 }}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                <TableRow>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(new Array(5)).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Typography ml={1}>
                          <Skeleton width={100} />
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justifyContent={"center"} mt={2}>
            <Skeleton width={240} height={40} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Loading;
