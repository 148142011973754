import { Grid } from "@mui/material";
import React from "react";

import UltrasoundList from "./UltrasoundList";

const Ultrasound = () => {
  return (
    <Grid container maxWidth="xl" mb={3}>
      <Grid container gap={1} mt={2} justifyContent={"center"}>
        <Grid item xs={12} lg={12}>
          <UltrasoundList />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Ultrasound;
