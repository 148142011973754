import React, { useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Box,
  useMediaQuery,
} from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context";
import LanguageSelector from "../../../lang/LanguageSelector";
import { useTranslation } from "react-i18next";
import { getAccess } from "../../../permissions/Permissions";
import { useTheme } from "@mui/material/styles";

const tabStyle = {
  color: "white",
  textTransform: "capitalize",
  fontWeight: "600",
  padding: "10px 18px",
  borderRadius: "8px",
  transition: "background-color 0.3s ease, color 0.3s ease",
};

const selectedTabStyle = {
  backgroundColor: "#007174",
  borderRadius: "8px",
  color: "white",
  fontWeight: "700",
  border: "3px solid white",
  borderBottom: "5px solid white",
};

const NavBar = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const firstname = user?.user?.name;
  const permissions = getAccess(user?.user?.permissions, user?.user?.isAdmin);

  const [value, setValue] = useState("/reception");
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleLogoutClick = () => {
    auth.logout();
    navigate("/user/sign-in/");
    handleMenuClose();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    const topLevelPath = location.pathname.split("/")[1];
    setValue(`/${topLevelPath}`);
  }, [location.pathname]);

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#007471",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
        minHeight: "10vh",
        padding: isSmallScreen ? "8px" : "0 16px",
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingX: isSmallScreen ? "8px" : "16px" }}
      >
        {/* Logo Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            minWidth: isSmallScreen ? "auto" : "100px",
            width: isSmallScreen && "100%",
            mb: isSmallScreen && "10px",
          }}
        >
          <img
            src={`${process.env.REACT_APP_MEDIA_URL}/images/logo/logo2.png`}
            alt="Company Logo"
            style={{
              marginTop: "2px",
              height: isSmallScreen ? "70px" : "100px",
              width: "auto",
              borderRadius: "50%",
              padding: "8px",
            }}
          />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            maxWidth: isSmallScreen ? "100%" : "90%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            indicatorColor="none"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            {permissions.reception && (
              <Tab
                style={{
                  ...tabStyle,
                  ...(value === "/reception" ? selectedTabStyle : {}),
                }}
                label={t("navbar.reception")}
                value="/reception"
                component={NavLink}
                to="/reception"
              />
            )}
            {permissions.pharmacy && (
              <Tab
                style={{
                  ...tabStyle,
                  ...(value === "/pharmacy" ? selectedTabStyle : {}),
                }}
                label={t("navbar.pharmacy")}
                value="/pharmacy"
                component={NavLink}
                to="/pharmacy"
              />
            )}
            {permissions.doctor && (
              <Tab
                style={{
                  ...tabStyle,
                  ...(value === "/doctor" ? selectedTabStyle : {}),
                }}
                label={t("navbar.doctor")}
                value="/doctor"
                component={NavLink}
                to="/doctor"
              />
            )}
            {permissions.nurse && (
              <Tab
                style={{
                  ...tabStyle,
                  ...(value === "/nurse" ? selectedTabStyle : {}),
                }}
                label={t("navbar.nurse")}
                value="/nurse"
                component={NavLink}
                to="/nurse"
              />
            )}
            {permissions.x_ray && (
              <Tab
                style={{
                  ...tabStyle,
                  ...(value === "/x-rays" ? selectedTabStyle : {}),
                }}
                label={t("navbar.x-rays")}
                value="/x-rays"
                component={NavLink}
                to="/x-rays"
              />
            )}
            {permissions.ultrasounds && (
              <Tab
                style={{
                  ...tabStyle,
                  ...(value === "/ultra-sound" ? selectedTabStyle : {}),
                }}
                label={t("navbar.ultra-sound")}
                value="/ultra-sound"
                component={NavLink}
                to="/ultra-sound"
              />
            )}
            {permissions.laboratory && (
              <Tab
                style={{
                  ...tabStyle,
                  ...(value === "/laboratory" ? selectedTabStyle : {}),
                }}
                label={t("navbar.laboratory")}
                value="/laboratory"
                component={NavLink}
                to="/laboratory"
              />
            )}
            {permissions.echo && (
              <Tab
                style={{
                  ...tabStyle,
                  ...(value === "/echocardiography" ? selectedTabStyle : {}),
                }}
                label={t("navbar.echocardiography")}
                value="/echocardiography"
                component={NavLink}
                to="/echocardiography"
              />
            )}
            {permissions.finance && (
              <Tab
                style={{
                  ...tabStyle,
                  ...(value === "/finance" ? selectedTabStyle : {}),
                }}
                label={t("navbar.finance")}
                value="/finance"
                component={NavLink}
                to="/finance"
              />
            )}
            {permissions.hr && (
              <Tab
                style={{
                  ...tabStyle,
                  ...(value === "/human-resource" ? selectedTabStyle : {}),
                }}
                label={t("navbar.hr")}
                value="/human-resource"
                component={NavLink}
                to="/human-resource"
              />
            )}
            {permissions.rooms && (
              <Tab
                style={{
                  ...tabStyle,
                  ...(value === "/rooms" ? selectedTabStyle : {}),
                }}
                label={t("navbar.rooms")}
                value="/rooms"
                component={NavLink}
                to="/rooms"
              />
            )}
            {permissions.operations && (
              <Tab
                style={{
                  ...tabStyle,
                  ...(value === "/operations" ? selectedTabStyle : {}),
                }}
                label={t("navbar.operations")}
                value="/operations"
                component={NavLink}
                to="/operations"
              />
            )}
          </Tabs>
        </Box>

        {/* Right Section (User Profile and Language Selector) */}
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            justifyContent: "flex-end",
          }}
        >
          <LanguageSelector />
          <Typography
            variant="body2"
            sx={{ color: "white", fontWeight: "500" }}
          >
            {firstname}
          </Typography>
          <Avatar
            onClick={handleMenuClick}
            sx={{
              cursor: "pointer",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
            }}
          />
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "8px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <MenuItem onClick={handleLogoutClick} sx={{ fontWeight: "500" }}>
              {t("navbar.logout")}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default NavBar;
