import React from "react";
import {
  Biotech,
  Image,
  Favorite,
  LocalHospital,
  CalendarToday,
} from "@mui/icons-material"; // Import valid Material-UI icons

// This component selects an appropriate icon based on the event type
const EventIcon = ({ type }) => {
  switch (type) {
    case "lab":
      return <Biotech fontSize="small" />; // Lab-related events (e.g., blood tests)
    case "xray":
    case "ultrasound":
      return <Image fontSize="small" />; // Imaging-related events (X-ray, ultrasound)
    case "echo":
      return <Favorite fontSize="small" />; // Heart-related events (echocardiogram)
    case "visit":
      return <LocalHospital fontSize="small" />; // Doctor visit events (replaced Stethoscope with LocalHospital)
    default:
      return <CalendarToday fontSize="small" />; // Default for unknown event types
  }
};

export default EventIcon;
