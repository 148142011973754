// src/routes/operationsRoutes.js
import OperationsNav from "../../pages/Dashboard/Operations/OperationsNav";
import OperationIndex from "../../pages/Dashboard/Operations/OperationsIndex";
import AddOperation from "../../pages/Dashboard/Operations/Surgery/AddOperation";
import SurgeryList from "../../pages/Dashboard/Operations/Surgery/ListOperation";

const operationsRoutes = {
  path: "/operations",
  element: <OperationsNav />,
  children: [
    { path: "/operations", element: <OperationIndex /> },
    { path: "/operations/add", element: <AddOperation /> },
    { path: "/operations/list", element: <SurgeryList /> },
  ],
};

export default operationsRoutes;
