import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        sx={{
          padding: "8px",
          borderRadius: "8px",
          backgroundColor: "#f5f5f5",
          boxShadow: 3,
          minWidth: "100px",
        }}
      >
        <Typography variant="subtitle2" color="textSecondary">
          {label}
        </Typography>
        <Typography
          variant="body1"
          color="primary"
        >{`${payload[0].value.toLocaleString()}؋`}</Typography>
      </Paper>
    );
  }
  return null;
};

const TotalCustomers = ({ roomData = {}, appointmentData = {} }) => {
  const { t } = useTranslation();
  const data = [
    {
      name: t("finance.totalRoomRevenue"),
      value: roomData.total_room_revenue || 0,
    },
    {
      name: t("finance.unpaidRoomAmount"),
      value: roomData.unpaid_room_amount || 0,
    },
    {
      name: t("finance.totalPendingAppointments"),
      value: appointmentData.total_pending_appointments || 0,
    },
    {
      name: t("finance.totalAppointmentFees"),
      value: appointmentData.total_pending_fees || 0,
    },
    {
      name: t("finance.totalAppointmentRevenue"),
      value: appointmentData.total_revenue_from_appointments || 0,
    },
  ];

  return (
    <Grid container padding={"0px 5px"}>
      <Paper
        sx={{ width: "100%", padding: 3, borderRadius: "8px", boxShadow: 4 }}
      >
        <Box textAlign={"center"} mt={1} mb={2}>
          <Typography variant="h5" fontWeight="bold" color="primary">
            {t("finance.roomReAppointment")}
          </Typography>
        </Box>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
            <XAxis
              dataKey="name"
              tick={{ fill: "#555", fontSize: 12, fontWeight: "bold" }}
            />
            <YAxis tick={{ fill: "#555", fontSize: 12, fontWeight: "bold" }} />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ stroke: "#ccc", strokeWidth: 2 }}
            />
            <Legend verticalAlign="top" align="right" iconType="circle" />
            <Line
              type="monotone"
              dataKey="value"
              name={t("finance.value")}
              stroke="#1976d2"
              strokeWidth={3}
              activeDot={{
                r: 6,
                stroke: "#1976d2",
                strokeWidth: 2,
                fill: "#fff",
              }}
              dot={{ r: 5 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Paper>
    </Grid>
  );
};

export default TotalCustomers;
