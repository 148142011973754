import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  Divider,
  CircularProgress,
} from "@mui/material";
import useAdd from "../../../../../api/useAdd";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../context";

const buttonStyle = {
  backgroundColor: "#071952",
  color: "white",
  textTransform: "capitalize",
  margin: "0 10px",
};

const AddCompanyMedicine = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    address: "",
  });

  const { handleAdd, resetForm, loading } = useAdd(
    "medicine-companies",
    token,
    t("pharmacy.addedSuccess"),
    t("pharmacy.addFailed")
  );

  useEffect(() => {
    if (resetForm) {
      setFormData({
        name: "",
        phone: "",
        address: "",
      });
      setFormErrors({});
    }
  }, [resetForm]);

  const handleSave = (e) => {
    e.preventDefault();
    let errors = {};
    if (!formData.name) errors.name = t("pharmacy.nameRequired");
    if (!formData.phone) errors.phone = t("pharmacy.phoneRequired");
    if (!formData.address) errors.address = t("pharmacy.addressRequired");

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      handleAdd(formData);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Grid container mt={3}>
      <Grid item xs={12} md={12}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 3 }}>
          <Typography
            variant="h5"
            fontWeight="bold"
            mb={2}
            color="primary"
            textAlign="center"
          >
            {t("pharmacy.addCompany")}
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Box component="form" onSubmit={handleSave}>
            <TextField
              fullWidth
              size="small"
              label={t("pharmacy.companyName")}
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              error={!!formErrors.name}
              helperText={formErrors.name}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              size="small"
              label={t("pharmacy.phoneNumber")}
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              error={!!formErrors.phone}
              helperText={formErrors.phone}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              size="small"
              label={t("pharmacy.address")}
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              error={!!formErrors.address}
              helperText={formErrors.address}
              sx={{ mb: 3 }}
              multiline
              rows={3}
            />

            <Grid container justifyContent="flex-end" mt={3}>
              <Button
                variant="contained"
                style={buttonStyle}
                disabled={loading}
                type="submit"
              >
                {loading ? <CircularProgress size={20} /> : t("pharmacy.save")}
              </Button>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AddCompanyMedicine;
