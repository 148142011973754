import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        sx={{
          padding: "8px",
          borderRadius: "8px",
          backgroundColor: "#fff",
          boxShadow: 2,
        }}
      >
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
        <Typography
          variant="h6"
          color="primary"
        >{`${payload[0].value.toLocaleString()}؋`}</Typography>
      </Paper>
    );
  }
  return null;
};

const TotalProfit = ({ summaryData = {} }) => {
  const { t } = useTranslation();
  const data = [
    { name: t("finance.totalIncome"), value: summaryData.total_income || 0 },
    {
      name: t("finance.totalExpenses"),
      value: summaryData.total_expenses || 0,
    },
    { name: t("finance.netIncome"), value: summaryData.net_income || 0 },
  ];

  return (
    <Grid container padding={"0px 5px"}>
      <Paper
        sx={{ width: "100%", padding: 3, borderRadius: "8px", boxShadow: 3 }}
      >
        <Box textAlign={"center"} mt={1} mb={2}>
          <Typography variant="h5" fontWeight="bold" color={"primary"}>
            {t("finance.profitSummary")}
          </Typography>
        </Box>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
            <XAxis dataKey="name" tick={{ fill: "#555", fontWeight: "bold" }} />
            <YAxis tick={{ fill: "#555", fontWeight: "bold" }} />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ fill: "rgba(200, 200, 200, 0.3)" }}
            />
            <Legend verticalAlign="top" align="right" iconType="circle" />
            <Bar
              dataKey="value"
              name={t("finance.value")}
              fill="#1976d2"
              barSize={30}
              radius={[4, 4, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    </Grid>
  );
};

export default TotalProfit;
