import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  Typography,
  Stack,
  Box,
  Chip,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Container,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
  CircularProgress,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import SkeletonLoading from "./skeletonLoading/SkeletonLoading";
import ErrorPage from "../../../../Components/GeneralComponents/errorPage/ErrorPage";
import useUpdate from "../../../../api/useUpdate";
import { useTranslation } from "react-i18next";
import PrintIcon from "@mui/icons-material/Print";
import Print from "./Print";

const CollectLaboratoryFees = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [printData, setPrintData] = useState(null);
  const printRef = useRef();

  const [searchTerm, setSearchTerm] = useState("");
  const [query, setQuery] = useState("");

  const { data, isLoading, isError, error, refetch } = useFetchObjects(
    ["collect-laboratory"],
    `laboratory-test-orders/?search=${query}`,
    token
  );

  useEffect(() => {
    setQuery(searchTerm);
    refetch();
  }, [searchTerm, refetch]);

  const { handleUpdate, isLoading: updateLoading } = useUpdate(
    "laboratory-test-orders",
    token
  );

  const handleStatusChange = (id) => {
    const formData = new FormData();
    formData.append("is_paid", true);
    handleUpdate(id, formData);
  };

  const handlePrintClick = (order) => {
    setPrintData(order);
    setTimeout(() => {
      if (printRef.current) {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(printRef.current.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }, 500);
  };

  if (isLoading) {
    return <SkeletonLoading />;
  }

  if (isError) {
    return <ErrorPage errorMessage={error.message} />;
  }

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return t("reception.pending");
      case 2:
        return t("reception.paid");
      case 3:
        return t("reception.canceled");
      default:
        return t("reception.unknown");
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return "warning";
      case 2:
        return "success";
      case 3:
        return "error";
      default:
        return "default";
    }
  };

  return (
    <Container maxWidth="xl">
      <Box my={4}>
        <TextField
          fullWidth
          label={t("reception.search")}
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        />
      </Box>

      <Grid container spacing={3} padding="20px" mt={3}>
        {data.length === 0 ? (
          <Typography>{t("reception.noMatching")}</Typography>
        ) : (
          data.map((order, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Paper
                elevation={order.is_paid ? 2 : 4}
                sx={{
                  mb: 3,
                  padding: "24px",
                  borderRadius: "12px",
                  boxShadow: order.is_paid
                    ? "0px 4px 12px rgba(0, 0, 0, 0.1)"
                    : "0px 8px 24px rgba(255, 0, 0, 0.3)",
                  backgroundColor: order.is_paid ? "#f9f9f9" : "#ffebee",
                  borderLeft: order.is_paid ? "none" : "4px solid #d32f2f",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="primary"
                    sx={{ fontSize: isSmallScreen ? "1rem" : "1.25rem" }}
                  >
                    {t("reception.order")} #{order.id} -{" "}
                    {new Date(order.order_date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </Typography>
                  <Box>
                    <Chip
                      label={
                        order.is_paid
                          ? t("reception.paid")
                          : t("reception.pending")
                      }
                      color={order.is_paid ? "success" : "warning"}
                      sx={{ fontWeight: "bold" }}
                    />
                    <IconButton
                      color="primary"
                      onClick={() => handlePrintClick(order)}
                    >
                      <PrintIcon />
                    </IconButton>
                  </Box>
                </Box>

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Typography fontSize={isSmallScreen ? "0.85rem" : "1rem"}>
                    <strong>{t("reception.doctor")}:</strong>{" "}
                    {order.doctor_display.username}
                  </Typography>
                  <Typography fontSize={isSmallScreen ? "0.85rem" : "1rem"}>
                    <strong>{t("reception.patient")}:</strong>{" "}
                    {order.patient_display.name}
                  </Typography>
                  <Typography fontSize={isSmallScreen ? "0.85rem" : "1rem"}>
                    <strong>{t("reception.totalAmount")}:</strong>{" "}
                    {order.total_amount} ؋
                  </Typography>
                </Stack>

                {!order.is_paid && (
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <Button
                      variant="contained"
                      fullWidth={isSmallScreen}
                      disabled={updateLoading}
                      startIcon={
                        updateLoading && <CircularProgress size={24} />
                      }
                      onClick={() => handleStatusChange(order.id)}
                      sx={{
                        minWidth: 150,
                        background: "linear-gradient(90deg, #d32f2f, #f44336)",
                        color: "#fff",
                        fontWeight: "bold",
                        borderRadius: "8px",
                        padding: "10px 20px",
                        transition: "0.3s",
                        "&:hover": {
                          background:
                            "linear-gradient(90deg, #f44336, #d32f2f)",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        },
                      }}
                    >
                      {updateLoading
                        ? t("reception.loading")
                        : t("reception.collectFees")}
                    </Button>
                  </Stack>
                )}

                <Divider sx={{ mt: 2, mb: 2 }} />

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography fontWeight="bold">
                      {t("reception.testIncluded")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {order.tests_display && order.tests_display.length > 0 ? (
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">
                              {t("reception.testType")}
                            </TableCell>
                            <TableCell align="center">
                              {t("reception.cost")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.tests_display.map((test) => (
                            <TableRow key={test.id}>
                              <TableCell align="center">
                                {test.test_type_display.name}
                              </TableCell>
                              <TableCell align="center">
                                {test.test_type_display.cost} ؋
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <Typography>{t("reception.noTests")}</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Paper>
            </Grid>
          ))
        )}

        {/* Print Component */}
        {printData && (
          <div ref={printRef} style={{ display: "none" }}>
            <Print labTest={printData} />
          </div>
        )}
      </Grid>
    </Container>
  );
};

export default CollectLaboratoryFees;
