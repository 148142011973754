import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
  Divider,
  InputLabel,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import XrayCollectFees from "./XrayCollectFees";

const XRayReferral = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data: xrayTypesData } = useFetchObjects(
    ["xray-types"],
    "xray-types/",
    token
  );
  const { data: patientsData } = useFetchObjects(
    ["patients"],
    "patients/",
    token
  );

  const { handleAdd, resetForm } = useAdd("patient-xray", token);

  const xrayTypes = xrayTypesData || [];
  const patients = patientsData?.results || [];

  // Get current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [xrayDetails, setXrayDetails] = useState({
    xrayType: "",
    testDate: getCurrentDate(), // Automatically set current date
    notes: "",
    isUrgent: false,
    totalAmount: "",
    patient: null,
    doctor: auth?.user?.user?.id,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (resetForm) {
      setXrayDetails({
        xrayType: "",
        testDate: getCurrentDate(), // Reset to current date
        notes: "",
        isUrgent: false,
        totalAmount: "",
        patient: null,
        doctor: auth?.user?.user?.id,
      });
      setErrors({});
    }
  }, [resetForm]);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setXrayDetails((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "xrayType") {
      const selectedType = xrayTypes.find(
        (type) => type.id.toString() === value
      );
      setXrayDetails((prev) => ({
        ...prev,
        totalAmount: selectedType ? selectedType.cost.toString() : "",
      }));
    }

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!xrayDetails.patient) {
      tempErrors.patient = t("doctor.patientRequired");
    }
    if (!xrayDetails.xrayType) {
      tempErrors.xrayType = t("doctor.xrayTypeRequired");
    }
    if (!xrayDetails.testDate) {
      tempErrors.testDate = t("doctor.testDateRequired");
    }
    if (!xrayDetails.totalAmount) {
      tempErrors.totalAmount = t("doctor.totalAmountRequired");
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const payload = {
        patient: xrayDetails.patient.id,
        doctor: xrayDetails.doctor,
        xray_type: xrayDetails.xrayType,
        date: xrayDetails.testDate,
        is_urgent: xrayDetails.isUrgent,
        total_amount: xrayDetails.totalAmount,
        notes: xrayDetails.notes,
      };
      handleAdd(payload);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        margin: "auto",
        width: "100%",
        borderRadius: "16px",
        backgroundColor: "background.default",
        boxShadow: "0px 6px 18px rgba(0,0,0,0.12)",
      }}
    >
      <Typography
        variant="h4"
        textAlign="center"
        fontWeight="bold"
        sx={{ mb: 3 }}
        color="primary"
      >
        {t("doctor.x-rayReferralForm")}
      </Typography>

      <Divider sx={{ mb: 4 }} />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={patients}
            getOptionLabel={(option) =>
              option
                ? `${option.id} - ${option.firstname} ${option.lastname}`
                : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("doctor.selectPatient")}
                variant="outlined"
                fullWidth
                error={!!errors.patient}
                helperText={errors.patient}
              />
            )}
            value={xrayDetails.patient}
            onChange={(event, newValue) => {
              setXrayDetails((prev) => ({
                ...prev,
                patient: newValue,
              }));
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                (option) =>
                  option.id.toString().includes(inputValue) ||
                  option.firstname
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()) ||
                  option.lastname
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
              )
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>{t("doctor.xrayType")}</InputLabel>
            <Select
              name="xrayType"
              value={xrayDetails.xrayType}
              onChange={handleChange}
              displayEmpty
              error={!!errors.xrayType}
            >
              {xrayTypes.map((type) => (
                <MenuItem key={type.id} value={type.id.toString()}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
            {errors.xrayType && (
              <Typography color="error" variant="caption">
                {errors.xrayType}
              </Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="testDate"
            label={t("doctor.testDate")}
            type="date"
            fullWidth
            value={xrayDetails.testDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            error={!!errors.testDate}
            helperText={errors.testDate}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="totalAmount"
            label={t("doctor.totalAmount")}
            type="number"
            fullWidth
            value={xrayDetails.totalAmount}
            onChange={handleChange}
            error={!!errors.totalAmount}
            helperText={errors.totalAmount}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={xrayDetails.isUrgent}
                onChange={handleChange}
                name="isUrgent"
              />
            }
            label={t("doctor.urgent")}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="notes"
            label={t("doctor.notes")}
            multiline
            rows={4}
            fullWidth
            value={xrayDetails.notes}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            {t("doctor.submit")}
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 4 }} />

      <Box sx={{ mt: 3 }}>
        <XrayCollectFees />
      </Box>
    </Paper>
  );
};

export default XRayReferral;
