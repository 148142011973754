import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTranslation } from "react-i18next";

const ConfirmDialog = ({
  confirmDialogOpen,
  setConfirmDialogOpen,
  confirmDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={confirmDialogOpen}
      onClose={() => setConfirmDialogOpen(false)}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
          }}
        >
          <WarningAmberIcon color="error" sx={{ fontSize: "5rem" }} />
          <Typography variant="h5" fontWeight={"bold"}>
            {t("use_delete.confiremDeleting")}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>{t("use_delete.deletingMessage")}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setConfirmDialogOpen(false)}
          color="primary"
        >
          {t("use_delete.cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={confirmDelete}
          color="error"
          sx={{ margin: "0px 10px" }}
        >
          {t("use_delete.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
