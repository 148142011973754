import { Grid } from "@mui/material";
import React from "react";
import ActivityOverView from "./ActivityOverview/ActivityOverview";
import Appointments from "./Appointments/Appointments";

const DoctorIndex = () => {
  return (
    <Grid container maxWidth="xl" mb={3}>
      <Grid container gap={1} mt={2} justifyContent={"center"}>
        <Grid item xs={12} md={11} lg={3.3}>
          <Grid container>
            <ActivityOverView />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8.4}>
          <Appointments />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DoctorIndex;
