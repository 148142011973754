export const getAccess = (permissions = {}, isAdmin = false) => {
  if (isAdmin) {
    return {
      reception: true,
      pharmacy: true,
      doctor: true,
      nurse: true,
      x_ray: true,
      ultrasounds: true,
      laboratory: true,
      echo: true,
      finance: true,
      hr: true,
      rooms: true,
      operations: true,
    };
  }

  return {
    reception: permissions.reception || false,
    pharmacy: permissions.pharmacy || false,
    doctor: permissions.doctor || false,
    nurse: permissions.nurse || false,
    x_ray: permissions.x_ray || false,
    ultrasounds: permissions.ultrasounds || false,
    laboratory: permissions.laboratory || false,
    echo: permissions.echo || false,
    finance: permissions.finance || false,
    hr: permissions.hr || false,
    rooms: permissions.rooms || false,
    operations: permissions.operations || false,
  };
};
