import {
  Divider,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";

const Appointments = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState(1);
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { data, isLoading, isError, refetch } = useFetchObjects(
    ["AppointmentsList"],
    `appointments/?status=${status}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [status]);

  const handleStatusChange = (event, newValue) => {
    setValue(newValue);
    setStatus([1, 2, 3][newValue]); // Map value to status
  };

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          padding: "25px",
          borderRadius: "15px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: "#333", mb: 2 }}
        >
          {t("reception.OPDOverview")}
        </Typography>
        <Tabs
          value={value}
          onChange={handleStatusChange}
          variant={isSmallScreen ? "scrollable" : "fullWidth"}
          scrollButtons={isSmallScreen ? "auto" : false}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            "& .MuiTab-root": { fontWeight: "bold", textTransform: "none" },
          }}
        >
          <Tab label={t("reception.newOPD")} />
          <Tab label={t("reception.completedOPD")} />
          <Tab label={t("reception.cancelledOPD")} />
        </Tabs>
        <Divider sx={{ mt: 2 }} />

        <TableContainer sx={{ mt: 2 }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#f9f9f9" }}>
              <TableRow>
                <TableCell align="center">{t("reception.time")}</TableCell>
                <TableCell align="center">{t("reception.date")}</TableCell>
                <TableCell align="center">
                  {t("reception.patientName")}
                </TableCell>
                <TableCell align="center">{t("reception.doctor")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.results?.map((appointment, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:hover": { backgroundColor: "#f1f1f1" } }}
                >
                  <TableCell align="center">
                    {appointment.timeOfVisit}
                  </TableCell>
                  <TableCell align="center">
                    {appointment.dateOfVisit}
                  </TableCell>
                  <TableCell align="center">{`${appointment.patient_name_display} ${appointment.patient_lastname_display}`}</TableCell>
                  <TableCell align="center">
                    {appointment.doctor_name_display}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default Appointments;
