// src/routes/xrayRoutes.js
import XrayNav from "../../pages/Dashboard/XRays/XraysNav";
import XrayIndex from "../../pages/Dashboard/XRays/XrayIndex";
import TestXrayResult from "../../pages/Dashboard/XRays/testResult/TestXrayResult";
import XrayType from "../../pages/Dashboard/XRays/XrayType/XrayTypes";

const xrayRoutes = {
  path: "/x-rays",
  element: <XrayNav />,
  children: [
    { path: "/x-rays", element: <XrayIndex /> },
    { path: "/x-rays/test-result", element: <TestXrayResult /> },
    { path: "/x-rays/xray-types", element: <XrayType /> },
  ],
};

export default xrayRoutes;
