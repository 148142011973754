import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useDelete from "../../../../api/useDelete";
import SkeletonLoading from "./loading/SkeletonLoading";
import UpdateLaboratoryTypeDialog from "./UpdateLaboratoryTypeDialog";
import { useTranslation } from "react-i18next";

const ListLaboratoryTypes = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const { data, isLoading, isError, error, refetch } = useFetchObjects(
    ["laboratory-tests"],
    `laboratory-tests/?search=${searchTerm}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [searchTerm]);

  const { handleDelete, ConfirmDialog } = useDelete("laboratory-tests", token);

  // Open dialog for editing
  const handleEdit = (type) => {
    setSelectedType(type);
    setOpenDialog(true);
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedType(null);
  };

  return (
    <Paper
      sx={{
        padding: "30px 40px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <Typography variant="h5" gutterBottom>
        {t("laboratory.listLabTestTypes")}
      </Typography>

      {/* Search bar */}
      <TextField
        label={t("laboratory.search")}
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 4 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder={t("laboratory.searchByTestDescription")}
      />

      {/* Loading state */}
      {isLoading && <SkeletonLoading />}
      {isError && (
        <Typography color="error">
          {t("laboratory.error")}: {error.message}
        </Typography>
      )}

      {/* Laboratory types table */}
      {data && (
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead style={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.testName")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.description")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.category")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.parentTest")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.cost")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.minValue")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.maxValue")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.units")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.action")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((type) => (
                <TableRow
                  key={type.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": { backgroundColor: "#f9f9f9" },
                    transition: "0.3s",
                  }}
                >
                  <TableCell align="center">{type.name}</TableCell>
                  <TableCell align="center">
                    {type.description || t("laboratory.noDescription")}
                  </TableCell>
                  <TableCell align="center">
                    {type.category_display?.name || t("laboratory.noCategory")}
                  </TableCell>
                  <TableCell align="center">
                    {type.parent_test_display?.name ||
                      t("laboratory.noParentTest")}
                  </TableCell>
                  <TableCell align="center">{type.cost || "N/A"}</TableCell>
                  <TableCell align="center">
                    {type.min_value || "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    {type.max_value || "N/A"}
                  </TableCell>
                  <TableCell align="center">{type.units || "N/A"}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(type)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(type.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <ConfirmDialog />

      {/* No data found state */}
      {!isLoading && data?.length === 0 && (
        <Typography variant="body1" color="textSecondary" mt={2}>
          {t("laboratory.noLabTestTypesFound")}
        </Typography>
      )}

      {/* Update Dialog */}
      {selectedType && (
        <UpdateLaboratoryTypeDialog
          open={openDialog}
          onClose={handleCloseDialog}
          type={selectedType}
          token={token}
          refetch={refetch}
        />
      )}
    </Paper>
  );
};

export default ListLaboratoryTypes;
