import React, { createContext, useState, useContext } from "react";

const NewRecordsContext = createContext();

export const NewRecordsProvider = ({ children }) => {
  const [newRecords, setNewRecords] = useState({
    prescriptionFees: false,
    medicineOrderFees: false,
    laboratoryFees: false,
    ultrasoundFees: false,
    echoFees: false,
    xrayFees: false,
  });

  const updateHighlight = (key, condition) => {
    setNewRecords((prev) => ({
      ...prev,
      [key]: condition,
    }));
  };

  return (
    <NewRecordsContext.Provider value={{ newRecords, updateHighlight }}>
      {children}
    </NewRecordsContext.Provider>
  );
};

export const useNewRecords = () => useContext(NewRecordsContext);
