import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { Edit, Delete, InfoOutlined } from "@mui/icons-material";
import NoRecordMessage from "../../../../Components/GeneralComponents/noRecordMessage/NoRecordMessage";
import { useTranslation } from "react-i18next";

const TaskTable = ({
  assignedTasks,
  handleOpenEdit,
  handleOpenDescription,
  handleDelete,
}) => {
  const { t } = useTranslation();
  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 2, boxShadow: "0px 0px 5px rgba(0,0,0,0.1)" }}
    >
      <Table>
        <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
          <TableRow>
            <TableCell align="center">
              <Typography fontWeight="bold">{t("doctor.nurse")}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight="bold">{t("doctor.patient")}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight="bold">{t("doctor.roomNo")}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight="bold">{t("doctor.date")}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight="bold">{t("doctor.time")}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight="bold">
                {t("doctor.description")}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight="bold">{t("doctor.priority")}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight="bold">{t("doctor.status")}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight="bold">{t("doctor.action")}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assignedTasks && assignedTasks.length > 0 ? (
            assignedTasks.map((task) => (
              <TableRow
                key={task.id}
                sx={{
                  "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                  "&:hover": { backgroundColor: "#f1f1f1" },
                }}
              >
                <TableCell align="center">{task.nurse_display}</TableCell>
                <TableCell align="center">{task.patient_display}</TableCell>
                <TableCell align="center">{task.room_number_display}</TableCell>
                <TableCell align="center">{task.assigned_date}</TableCell>
                <TableCell align="center">{task.time}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => handleOpenDescription(task)}>
                    <InfoOutlined color="primary" />
                  </IconButton>
                </TableCell>
                <TableCell align="center">{task.priority_display}</TableCell>
                <TableCell align="center">{task.status_display}</TableCell>
                <TableCell align="center">
                  <Box display="flex" justifyContent="center" gap={2}>
                    <IconButton onClick={() => handleOpenEdit(task)}>
                      <Edit sx={{ color: "#1e88e5" }} />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(task.id)}>
                      <Delete sx={{ color: "#f44336" }} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={9} align="center">
                <NoRecordMessage />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TaskTable;
