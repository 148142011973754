import React from "react";
import { Grid, Paper, Skeleton } from "@mui/material";

const SkeletonLoading = () => {
  return (
    <Paper
      sx={{
        padding: "30px 40px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <Skeleton variant="text" width="30%" height={40} sx={{ mb: 3 }} />
      <Skeleton variant="rectangular" height={40} width="100%" sx={{ mb: 4 }} />
      <Grid container spacing={2}>
        {Array.from(new Array(5)).map((_, index) => (
          <Grid item xs={12} key={index}>
            <Skeleton
              variant="rectangular"
              height={40}
              width="100%"
              sx={{ borderRadius: "8px" }}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default SkeletonLoading;
