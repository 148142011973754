import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useUpdate from "../../../../api/useUpdate";
import SkeletonLoading from "./loading/SkeletonLoading";
import { useTranslation } from "react-i18next";

const getButtonColor = (status) => {
  switch (status) {
    case 1:
      return "#FFC107";
    case 2:
      return "#4CAF50";
    case 3:
      return "#F44336";
    default:
      return "#a7e2f9";
  }
};

const TestsToBeDone = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const auth = useAuth();
  const token = auth?.user?.token;

  // Status filtering logic
  const status = value === 0 ? 1 : value === 1 ? 2 : 3;

  // Fetch echo data by status using the "patient-echo" endpoint
  const { data, isLoading, isError, error } = useFetchObjects(
    ["patient-echo", status],
    `patient-echo/?status=${status}`,
    token
  );

  // Update echo status
  const { handleUpdate } = useUpdate("patient-echo", token);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEcho, setSelectedEcho] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, echo) => {
    setAnchorEl(event.currentTarget);
    setSelectedEcho(echo);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedEcho(null);
  };

  const handleStatusChange = (newStatus) => {
    const formData = new FormData();
    formData.append("status", newStatus);
    handleUpdate(selectedEcho.id, formData);
    handleClose();
  };

  const buttonStyle = {
    border: "2px solid",
    borderRadius: "0.5em",
    color: "#a7e2f9",
    margin: "1em",
  };
  const tableStyle = {
    textAlign: "center",
  };

  const EchoStatus = {
    1: t("echo.pending"),
    2: t("echo.completed"),
    3: t("echo.cancelled"),
  };

  return (
    <Grid item xs={12}>
      {isLoading ? (
        <SkeletonLoading />
      ) : (
        <Paper sx={{ boxShadow: "0px 0px 0px" }}>
          <Grid container padding={"10px"}>
            <Grid item>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="scrollable force tabs example"
              >
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={t("echo.echoToBeDone")}
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={t("echo.done")}
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={t("echo.cancelled")}
                />
              </Tabs>
            </Grid>
            <Divider sx={{ mt: 2, width: "100%" }} />

            {isError && (
              <Typography>
                {" "}
                {t("echo.error")}: {error.message}
              </Typography>
            )}
            {data && (
              <TableContainer sx={{ mt: 2 }}>
                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "#f2f2f2" }}>
                    <TableRow>
                      <TableCell style={tableStyle}>{t("echo.date")}</TableCell>
                      <TableCell style={tableStyle}>
                        {t("echo.patientName")}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {t("echo.doctor")}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {t("echo.testType")}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {t("echo.amount")}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {t("echo.paymentStatus")}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {t("echo.status")}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {t("echo.actions")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell style={tableStyle}>{item.date}</TableCell>
                        <TableCell style={tableStyle} component="th">
                          {item.patient_display}
                        </TableCell>
                        <TableCell style={tableStyle}>
                          {item.doctor_display}
                        </TableCell>
                        <TableCell style={tableStyle}>
                          {item.echo_type_display}
                        </TableCell>
                        <TableCell style={tableStyle}>
                          {item.total_amount} ؋
                        </TableCell>
                        <TableCell style={tableStyle}>
                          {item.is_paid ? (
                            <Typography color="green">
                              {t("echo.paid")}
                            </Typography>
                          ) : (
                            <Typography color="red">
                              {t("echo.unpaid")}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell style={tableStyle}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: getButtonColor(item.status),
                            }}
                          >
                            {EchoStatus[item.status]}
                          </Typography>
                        </TableCell>
                        <TableCell style={tableStyle}>
                          {item.status === 1 ? (
                            <Button
                              id="demo-positioned-button"
                              aria-controls={
                                open ? "demo-positioned-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={(event) => handleClick(event, item)}
                            >
                              <MoreHorizIcon />
                            </Button>
                          ) : (
                            <Typography>{t("echo.noAction")}</Typography>
                          )}
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            PaperProps={{
                              sx: {
                                boxShadow: "0px 20px 10px rgba(0, 0, 0, 0.02)",
                                "&:before": { boxShadow: "none" },
                                border: "1px solid lightgray",
                                borderRadius: "1em",
                                padding: "10px",
                              },
                            }}
                          >
                            <Grid>
                              <Button
                                variant="none"
                                onClick={handleClose}
                                style={{
                                  position: "absolute",
                                  top: "0.01em",
                                  right: "0.01em",
                                }}
                              >
                                <CancelTwoToneIcon
                                  style={{ color: "#a7e2f9" }}
                                />
                              </Button>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: "#081c54",
                                  marginTop: "1em",
                                  marginLeft: "0.5em",
                                  fontSize: "27px",
                                }}
                              >
                                {t("echo.task")}
                              </Typography>
                              <Typography style={{ margin: "1em" }}>
                                {t("echo.echoTest")} : {item.echo_type_display}
                              </Typography>
                              <Typography style={{ margin: "1em" }}>
                                {t("echo.status")} : {EchoStatus[item.status]}
                              </Typography>
                              {item.status === 1 && (
                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <MenuItem
                                    style={{
                                      ...buttonStyle,
                                      border: `2px solid ${getButtonColor(
                                        item.status
                                      )}`,
                                      color: getButtonColor(item.status),
                                    }}
                                    onClick={() => handleStatusChange(2)} // Mark as Completed
                                  >
                                    {t("echo.markCompleted")}
                                  </MenuItem>
                                  <MenuItem
                                    style={{
                                      ...buttonStyle,
                                      border: `2px solid ${getButtonColor(
                                        item.status
                                      )}`,
                                      color: getButtonColor(item.status),
                                    }}
                                    onClick={() => handleStatusChange(3)}
                                  >
                                    {t("echo.markCancelled")}
                                  </MenuItem>
                                </Box>
                              )}
                            </Grid>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};

export default TestsToBeDone;
