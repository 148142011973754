import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useUpdate from "../../../../api/useUpdate";
import SkeletonLoading from "./loading/SkeletonLoading";
import SurgeryDetailsDialog from "./SurgeryDetailsDialog";
import { useTranslation } from "react-i18next";
const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return "#FFC107";
    case 2:
      return "#4CAF50";
    case 3:
      return "#F44336";
    default:
      return "#a7e2f9";
  }
};

const OperationsToBeDone = () => {
  const { t } = useTranslation();
  const SurgeryStatus = {
    1: t("operations.pending"),
    2: t("operations.completed"),
    3: t("operations.cancelled"),
  };

  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSurgery, setSelectedSurgery] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const auth = useAuth();
  const token = auth?.user?.token;

  const state = tabIndex === 0 ? 1 : tabIndex === 1 ? 2 : 3;

  const { data, isLoading, isError, error } = useFetchObjects(
    ["surgeries", state],
    `surgeries/?surgery_state=${state}`,
    token
  );

  const { handleUpdate } = useUpdate("surgeries", token);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const openMenu = (event, surgery) => {
    setAnchorEl(event.currentTarget);
    setSelectedSurgery(surgery);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedSurgery(null);
  };

  const viewDetails = (surgery) => {
    setSelectedSurgery(surgery);
    setOpenDetails(true);
  };

  const updateSurgeryStatus = (newStatus) => {
    if (selectedSurgery) {
      const formData = new FormData();
      formData.append("surgery_state", newStatus);
      handleUpdate(selectedSurgery.id, formData);
      closeMenu();
    }
  };

  return (
    <Grid item xs={12}>
      {isLoading ? (
        <SkeletonLoading />
      ) : (
        <Paper sx={{ boxShadow: "0px 0px 0px", padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="surgery status tabs"
                sx={{ borderBottom: "1px solid #ddd" }}
              >
                <Tab label={t("operations.pendingSurgeries")} />
                <Tab label={t("operations.completedSurgeries")} />
                <Tab label={t("operations.cancelledSurgeries")} />
              </Tabs>
            </Grid>
            <Divider sx={{ mt: 2, width: "100%" }} />

            {isError ? (
              <Typography color="error" textAlign="center">
                {t("operations.error")}: {error.message}
              </Typography>
            ) : (
              <TableContainer sx={{ mt: 2, overflowX: "auto" }}>
                <Table sx={{ minWidth: 650 }} aria-label="surgery table">
                  <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableRow>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {t("operations.date")}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {t("operations.patientName")}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {t("operations.doctor")}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {t("operations.surgeryType")}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {" "}
                        {t("operations.amount")}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {" "}
                        {t("operations.status")}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        {" "}
                        {t("operations.actions")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((surgery, index) => (
                      <TableRow key={index} hover>
                        <TableCell align="center">
                          {new Date(surgery.surgery_date).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="center">
                          {surgery.patient_display}
                        </TableCell>
                        <TableCell align="center">
                          {surgery.doctor_display}
                        </TableCell>
                        <TableCell align="center">
                          {surgery.surgery_type_display}
                        </TableCell>
                        <TableCell align="center">
                          {surgery.total_surgery_amount} ؋
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: getStatusColor(state),
                            }}
                          >
                            {SurgeryStatus[state]}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => viewDetails(surgery)}
                            size="small"
                          >
                            {t("operations.viewDetails")}
                          </Button>
                          {tabIndex === 0 && (
                            <Button
                              onClick={(event) => openMenu(event, surgery)}
                              size="small"
                            >
                              <MoreHorizIcon />
                            </Button>
                          )}
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={closeMenu}
                          >
                            <MenuItem onClick={() => updateSurgeryStatus(2)}>
                              {t("operations.markCompleted")}
                            </MenuItem>
                            <MenuItem onClick={() => updateSurgeryStatus(3)}>
                              {t("operations.cancelSurgery")}
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Paper>
      )}
      {/* Surgery Details Dialog */}
      <SurgeryDetailsDialog
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        surgery={selectedSurgery}
      />
    </Grid>
  );
};

export default OperationsToBeDone;
