import React, { useState } from "react";
import useUpdate from "../../../../../api/useUpdate";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const EditExpensesType = ({ open, onClose, expenseType, token }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: expenseType?.name || "",
  });

  const [errors, setErrors] = useState({
    name: "",
  });

  const { handleUpdate } = useUpdate("expense-types", token);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = t("finance.nameRequired");
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("name", formData.name);

      handleUpdate(expenseType.id, formDataToSubmit);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("finance.updateExpenseType")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("finance.expenseTypeName")}
          name="name"
          fullWidth
          variant="outlined"
          value={formData.name}
          error={!!errors.name}
          helperText={errors.name}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          color="warning"
          sx={{ textTransform: "capitalize" }}
        >
          {t("finance.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="success"
          sx={{ textTransform: "capitalize", margin: "0px 10px" }}
        >
          {t("finance.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditExpensesType;
