import React, { useState } from "react";
import useUpdate from "../../../../../api/useUpdate";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../context";

const EditCompanyMedicine = ({ open, onClose, company, token }) => {
  const { t } = useTranslation();

  // State for each field
  const [formData, setFormData] = useState({
    name: company?.name || "",
    phone: company?.phone || "",
    address: company?.address || "",
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    address: "",
  });

  const { handleUpdate } = useUpdate("medicine-companies", token);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = t("pharmacy.nameRequired");
      isValid = false;
    }

    if (!formData.phone.trim()) {
      newErrors.phone = t("pharmacy.phoneRequired");
      isValid = false;
    }

    if (!formData.address.trim()) {
      newErrors.address = t("pharmacy.addressRequired");
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("name", formData.name);
      formDataToSubmit.append("phone", formData.phone);
      formDataToSubmit.append("address", formData.address);

      handleUpdate(company.id, formDataToSubmit);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("pharmacy.updateCompany")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("pharmacy.companyName")}
          name="name"
          fullWidth
          variant="outlined"
          value={formData.name}
          error={!!errors.name}
          helperText={errors.name}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          label={t("pharmacy.phoneNumber")}
          name="phone"
          fullWidth
          variant="outlined"
          value={formData.phone}
          error={!!errors.phone}
          helperText={errors.phone}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          label={t("pharmacy.address")}
          name="address"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={formData.address}
          error={!!errors.address}
          helperText={errors.address}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          color="warning"
          sx={{ textTransform: "capitalize" }}
        >
          {t("pharmacy.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="success"
          sx={{ textTransform: "capitalize", margin: "0px 10px" }}
        >
          {t("pharmacy.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCompanyMedicine;
