import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Months } from "../../../../utils";
import { useTranslation } from "react-i18next";

const EditEmployeeLeaveDialog = ({
  open,
  onClose,
  evaluation,
  employees,
  onSave,
}) => {
  const {t}=useTranslation();
  const [editedEvaluation, setEditedEvaluation] = useState(evaluation);

  useEffect(() => {
    if (evaluation) {
      setEditedEvaluation(evaluation);
    }
  }, [evaluation]);

  const handleSave = () => {
    if (editedEvaluation) {
      onSave(editedEvaluation);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("hr.editEmployeeLeave")}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth variant="outlined" margin="dense">
          <InputLabel id="month-label">{t("hr.month")}</InputLabel>
          <Select
            labelId="month-label"
            value={editedEvaluation?.month || ""}
            onChange={(e) =>
              setEditedEvaluation({
                ...editedEvaluation,
                month: e.target.value,
              })
            }
            label={t("hr.month")}
          >
            {Months.map((m) => (
              <MenuItem key={m.id} value={m.id}>
                {m.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined" margin="dense">
          <InputLabel id="year-label">{t("hr.year")}</InputLabel>
          <Select
            labelId="year-label"
            value={editedEvaluation?.year || ""}
            onChange={(e) =>
              setEditedEvaluation({
                ...editedEvaluation,
                year: e.target.value,
              })
            }
            label={t("hr.year")}
          >
            {Array.from(
              { length: 4 },
              (_, index) => new Date().getFullYear() - index
            ).map((y) => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined" margin="dense">
          <InputLabel id="employee-label">{t("hr.employee")}</InputLabel>
          <Select
            labelId="employee-label"
            value={editedEvaluation?.employee || ""}
            onChange={(e) =>
              setEditedEvaluation({
                ...editedEvaluation,
                employee: e.target.value,
              })
            }
            label={t("hr.employee")}
          >
            {employees && employees.length > 0 ? (
              employees.map((emp) => (
                <MenuItem key={emp.id} value={emp.id}>
                  {emp.first_name + " " + emp.last_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>{t("hr.noEmployeesFound")}</MenuItem>
            )}
          </Select>
        </FormControl>

        <TextField
          margin="dense"
          label={t("hr.days")}
          type="number"
          fullWidth
          variant="outlined"
          value={editedEvaluation?.days || ""}
          onChange={(e) =>
            setEditedEvaluation({
              ...editedEvaluation,
              days: e.target.value,
            })
          }
        />
        <TextField
          margin="dense"
          label={t("hr.description")}
          type="text"
          fullWidth
          variant="outlined"
          value={editedEvaluation?.description || ""}
          onChange={(e) =>
            setEditedEvaluation({
              ...editedEvaluation,
              description: e.target.value,
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
        {t("hr.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary">
        {t("hr.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditEmployeeLeaveDialog;
