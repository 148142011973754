import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Stack,
} from "@mui/material";
import useUpdate from "../../../../api/useUpdate";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useTranslation } from "react-i18next";

const SurgeryPrescriptionDetailsEdit = ({
  prescriptionDetail,
  onClose,
  token,
}) => {
  const { t } = useTranslation();
  const { data: medicines, isLoading: loadingMedicines } = useFetchObjects(
    ["stock-medicines"],
    "stock-medicines",
    token
  );

  const [input, setInput] = useState({
    stock_medicine: prescriptionDetail?.stock_medicine || "",
    quantity: prescriptionDetail?.quantity || "",
  });

  const [formErrors, setFormErrors] = useState({});
  const { handleUpdate, isLoading: updating } = useUpdate(
    "surgery-prescription-details",
    token
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    let isValid = true;

    if (!input.stock_medicine) {
      errors.stock_medicine = t("pharmacy.medicineRequired");
      isValid = false;
    }

    if (!input.quantity) {
      errors.quantity = t("pharmacy.quantityRequired");
      isValid = false;
    }

    setFormErrors(errors);

    if (isValid) {
      const formData = new FormData();
      formData.append("stock_medicine", input.stock_medicine);
      formData.append("quantity", input.quantity);

      handleUpdate(prescriptionDetail.id, formData);
      onClose();
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      {loadingMedicines ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ py: 3 }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="h6" color="primary" fontWeight="bold" mb={2}>
            {t("pharmacy.editMedicineDetails")}
          </Typography>

          {/* Medicine Select */}
          <TextField
            select
            fullWidth
            label={t("pharmacy.medicine")}
            name="stock_medicine"
            size="small"
            margin="normal"
            value={input.stock_medicine}
            onChange={handleChange}
            error={!!formErrors.stock_medicine}
            helperText={formErrors.stock_medicine}
            disabled={loadingMedicines}
          >
            {medicines?.map((medicine) => (
              <MenuItem key={medicine.id} value={medicine.id}>
                {medicine.medicine_display} - {t("pharmacy.price")}:{" "}
                {medicine.sell_price}؋
              </MenuItem>
            ))}
          </TextField>

          {/* Quantity Field */}
          <TextField
            fullWidth
            label={t("pharmacy.quantity")}
            name="quantity"
            type="number"
            margin="normal"
            size="small"
            value={input.quantity}
            onChange={handleChange}
            error={!!formErrors.quantity}
            helperText={formErrors.quantity}
          />

          <Stack direction="row" spacing={2} justifyContent="flex-end" mt={3}>
            <Button variant="outlined" onClick={onClose} size="small">
              {t("pharmacy.cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              sx={{ backgroundColor: "green", color: "white" }}
              disabled={updating}
            >
              {updating ? <CircularProgress size={20} /> : t("pharmacy.save")}
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default SurgeryPrescriptionDetailsEdit;
