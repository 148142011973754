import React from "react";
import { Grid, Paper, Skeleton, Box } from "@mui/material";

const SkeletonLoading = () => {
  const skeletonCardStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#007174",
    height: "100%",
  };

  return (
    <Paper sx={{ boxShadow: "none", width: "100%", padding: "20px" }}>
      <Grid container spacing={3}>
        {[...Array(8)].map((_, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <Box sx={skeletonCardStyle}>
              <Skeleton
                variant="circular"
                width={50}
                height={50}
                sx={{ mb: 2 }}
              />
              <Skeleton variant="text" width="70%" height={25} />
              <Skeleton variant="text" width="50%" height={20} sx={{ mt: 1 }} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default SkeletonLoading;
