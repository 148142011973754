import React, { Fragment, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Box,
  Chip,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import PurchaseDrugEdit from "./PurchaseDrugEdit";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../../../api/ConfirmDialog";
import usePurchaseDelete from "../../../../api/usePurchaseDelete";

const PurchaseDrugList = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacy = auth?.user?.user?.pharmacy_type;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    handleDelete: handleDeletePurchase,
    confirmDialogOpen,
    setConfirmDialogOpen,
    confirmDelete,
  } = usePurchaseDelete("purchases", token);

  const {
    handleDelete: handleDeletePurchaseItem,
    confirmDialogOpen: confirmDialogOpenItem,
    setConfirmDialogOpen: setConfirmDialogOpenItem,
    confirmDelete: confirmDeleteItem,
  } = usePurchaseDelete("purchase-items", token);

  const { data, isLoading, isError, isSuccess, error } = useFetchObjects(
    ["purchases"],
    `purchases/?purchase_items__pharmacy=${pharmacy}`,
    token
  );

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  const getApprovalStatusLabel = (status) => {
    if (status === 1) return t("pharmacy.pending");
    if (status === 2) return t("pharmacy.approved");
    if (status === 3) return t("pharmacy.rejected");
    return t("pharmacy.unknownStatus");
  };

  const getApprovalStatusColor = (status) => {
    if (status === 1) return "warning";
    if (status === 2) return "success";
    if (status === 3) return "error";
    return "default";
  };

  if (isLoading) {
    return <Typography>{t("pharmacy.loading")}</Typography>;
  }

  if (isError) {
    return <Typography>{error.message}</Typography>;
  }

  if (isSuccess) {
    return (
      <Fragment>
        <Box my={4}>
          <Typography variant="h4" fontWeight="bold" color="primary" mb={2}>
            {t("pharmacy.purchaseList")}
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {data.map((purchase, index) => {
            const status = purchase.is_approved;
            return (
              <Grid item xs={12} md={6} key={index}>
                <Paper
                  elevation={status === 2 ? 2 : 4}
                  sx={{
                    mb: 3,
                    padding: "24px",
                    borderRadius: "12px",
                    width: "100%",
                    boxShadow:
                      status === 2
                        ? "0px 4px 12px rgba(0, 0, 0, 0.1)"
                        : "0px 8px 24px rgba(255, 0, 0, 0.3)",
                    backgroundColor:
                      status === 2
                        ? "#f9f9f9"
                        : status === 3
                        ? "#ffebee"
                        : "#fffde7",
                    borderLeft:
                      status === 3
                        ? "4px solid #d32f2f"
                        : status === 1
                        ? "4px solid #fbc02d"
                        : "none",
                  }}
                >
                  {/* Purchase Header */}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="primary"
                        sx={{
                          fontSize: isSmallScreen ? "1rem" : "1.25rem",
                        }}
                      >
                        {t("pharmacy.purchase")} #{purchase.id} -{" "}
                        {purchase.date}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        sx={{
                          fontSize: isSmallScreen ? "0.9rem" : "1rem",
                          mt: 1,
                        }}
                      >
                        {t("pharmacy.medicineCompany")}:{" "}
                        {purchase.medicine_company_display}
                      </Typography>
                    </Box>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography
                        fontSize={isSmallScreen ? "0.8rem" : "0.9rem"}
                      >
                        <strong>{t("pharmacy.totalAmount")}:</strong>{" "}
                        {purchase.total_amount} ؋
                      </Typography>
                      <Chip
                        label={getApprovalStatusLabel(purchase.is_approved)}
                        color={getApprovalStatusColor(purchase.is_approved)}
                        sx={{
                          fontWeight: "bold",
                          fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                        }}
                      />
                    </Stack>
                  </Box>

                  <Divider sx={{ mt: 2, mb: 2 }} />

                  {/* Accordion for Purchase Details */}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        fontWeight="bold"
                        color={
                          status === 3
                            ? "error.main"
                            : status === 1
                            ? "warning.main"
                            : "text.primary"
                        }
                      >
                        {t("pharmacy.medicinesInPurchase")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {purchase.items && purchase.items.length > 0 ? (
                        <Box sx={{ overflowX: "auto" }}>
                          <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">
                                  {t("pharmacy.category")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("pharmacy.medicine")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("pharmacy.quantity")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("pharmacy.purchasePrice")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("pharmacy.sellPrice")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("pharmacy.expiryDate")}
                                </TableCell>
                                <TableCell align="center">
                                  {t("pharmacy.actions")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {purchase.items.map((item) => (
                                <TableRow
                                  key={item.id}
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#f0f4f8",
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    <Typography>
                                      {item.category_display}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography>
                                      {item.medicine_display}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography>{item.quantity}</Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography>
                                      {item.purchase_price} ؋
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography>{item.sell_price} ؋</Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography>{item.expiry_date}</Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    {status === 1 && (
                                      <>
                                        <Tooltip title={t("pharmacy.editItem")}>
                                          <IconButton
                                            color="primary"
                                            onClick={() => handleOpen(item)}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          title={t("pharmacy.deleteItem")}
                                        >
                                          <IconButton
                                            color="error"
                                            onClick={() =>
                                              handleDeletePurchaseItem(item.id)
                                            }
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      ) : (
                        <Typography>{t("pharmacy.noMedicines")}</Typography>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Paper>
              </Grid>
            );
          })}

          {/* Confirm Dialogs */}
          <ConfirmDialog
            confirmDialogOpen={confirmDialogOpen}
            setConfirmDialogOpen={setConfirmDialogOpen}
            confirmDelete={confirmDelete}
          />
          <ConfirmDialog
            confirmDialogOpen={confirmDialogOpenItem}
            setConfirmDialogOpen={setConfirmDialogOpenItem}
            confirmDelete={confirmDeleteItem}
          />

          {/* Edit Dialog */}
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>
              <Typography fontWeight="bold" color="primary">
                {t("pharmacy.editPurchaseItem")}
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: "20px" }}>
              {selectedItem && (
                <PurchaseDrugEdit
                  purchaseItem={selectedItem}
                  onClose={handleClose}
                  token={token}
                />
              )}
            </DialogContent>
          </Dialog>
        </Grid>
      </Fragment>
    );
  }
};

export default PurchaseDrugList;
