import { Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import useUpdate from "../../../../../api/useUpdate";
import { useAuth } from "../../../../../context";
import { useTranslation } from "react-i18next";

const buttonStyle = {
  textTransform: "capitalize",
  backgroundColor: "#071952",
  fontWeight: "bold",
};

const EditPosition = ({ position,  setOpenDialog }) => {
  const {t}=useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const [formData, setFormData] = useState({
    name: position?.name || "",
    code: position?.code || "",
  });

  const { handleUpdate } = useUpdate("position", token);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = () => {
    handleUpdate(position?.id, formData);
    setOpenDialog(false);
  };

  return (
    <Grid container>
      <TextField
        autoFocus
        margin="dense"
        label={t("hr.positionName")}
        name="name"
        value={formData.name}
        onChange={handleFormChange}
        fullWidth
      />
      <TextField
        margin="dense"
        label={t("hr.positionCode")}
        name="code"
        value={formData.code}
        onChange={handleFormChange}
        fullWidth
      />

      <Grid container justifyContent={"center"}>
        <Button
          variant="contained"
          style={{ ...buttonStyle, marginLeft: "20px" }}
          onClick={handleFormSubmit}
        >
          {t("hr.save")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditPosition;
