import {
  Grid,
  Skeleton,
  Paper,
  Typography,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import React from "react";

const IndexSkeleton = () => {
  return (
    <Grid container maxWidth="xl" mb={3}>
      {/* First Grid: ActivityOverView and Appointments */}
      <Grid container gap={1} mt={1} justifyContent={"center"} padding={"10px"}>
        {/* ActivityOverView Skeleton */}
        <Grid item xs={12} md={10} lg={3.5}>
          <Paper sx={{ boxShadow: "0px 0px 0px", width: "100%" }}>
            <Grid container padding={"20px"}>
              <Grid container gap={3} mt={2} justifyContent={"center"}>
                {[...Array(4)].map((_, index) => (
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    lg={5}
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "25px 10px",
                      borderRadius: "10px",
                    }}
                  >
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" width={50} />
                    <Skeleton variant="text" width={80} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Appointments Skeleton */}
        <Grid item xs={12} md={10} lg={7.5}>
          <Grid item xs={12}>
            <Paper sx={{ boxShadow: "0px 0px 0px" }}>
              <Grid container padding={"10px"}>
                <Grid item>
                  <Tabs value={0}>
                    <Tab label={<Skeleton width={120} />} />
                    <Tab label={<Skeleton width={150} />} />
                    <Tab label={<Skeleton width={170} />} />
                  </Tabs>
                </Grid>
                <Divider sx={{ mt: 2, width: "100%" }} />
                <Grid item xs={12}>
                  <TableSkeleton />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      {/* Second Grid: Laboratory, TopMedicinesSold, and PatientsFee */}
      <Grid container gap={1} mt={2} justifyContent={"center"} padding={"10px"}>
        {/* Laboratory Skeleton */}
        <Grid item xs={12} md={10} lg={3.4}>
          <Paper
            sx={{
              ml: 1,
              boxShadow: "0px 0px 0px",
              maxHeight: "400px",
              overflowX: "auto",
            }}
          >
            <Typography padding={"20px"}>
              <Skeleton width={120} />
            </Typography>
            {[...Array(5)].map((_, index) => (
              <Grid
                container
                padding={"8.2px"}
                justifyContent={"space-between"}
                alignItems={"center"}
                key={index}
              >
                <Grid item xs={6} display={"flex"}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Grid ml={1}>
                    <Skeleton width={100} />
                    <Skeleton width={80} />
                  </Grid>
                </Grid>
                <Grid item xs={6} textAlign={"end"}>
                  <Skeleton variant="rectangular" width={70} height={30} />
                </Grid>
              </Grid>
            ))}
          </Paper>
        </Grid>

        {/* TopMedicinesSold Skeleton */}
        <Grid item xs={12} md={10} lg={4} bgcolor={"white"}>
          <Paper
            sx={{
              ml: 1,
              boxShadow: "0px 0px 0px",
              maxHeight: "400px",
              overflowX: "auto",
            }}
          >
            <Typography padding={"20px"}>
              <Skeleton width={150} />
            </Typography>
            {[...Array(5)].map((_, index) => (
              <Grid
                container
                padding={"8.2px"}
                justifyContent={"space-between"}
                alignItems={"center"}
                key={index}
              >
                <Grid item xs={6} display={"flex"}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Grid ml={1}>
                    <Skeleton width={100} />
                    <Skeleton width={80} />
                  </Grid>
                </Grid>
                <Grid item xs={6} textAlign={"end"}>
                  <Skeleton variant="rectangular" width={90} height={30} />
                </Grid>
              </Grid>
            ))}
          </Paper>
        </Grid>

        {/* PatientsFee Skeleton */}
        <Grid item xs={12} md={10} lg={3.6}>
          <Paper
            sx={{
              ml: 1,
              boxShadow: "0px 0px 0px",
              maxHeight: "400px",
              overflowX: "auto",
            }}
          >
            <Typography padding={"20px"}>
              <Skeleton width={140} />
            </Typography>
            {[...Array(5)].map((_, index) => (
              <Grid
                container
                padding={"8.2px"}
                justifyContent={"space-between"}
                alignItems={"center"}
                key={index}
              >
                <Grid item xs={6} display={"flex"}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Grid ml={1}>
                    <Skeleton width={100} />
                    <Skeleton width={80} />
                  </Grid>
                </Grid>
                <Grid item xs={6} textAlign={"end"}>
                  <Skeleton variant="rectangular" width={90} height={30} />
                </Grid>
              </Grid>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

const TableSkeleton = () => (
  <Grid container padding={"10px"}>
    <Grid item xs={12}>
      {[...Array(5)].map((_, index) => (
        <Grid key={index} container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={3}>
            <Skeleton variant="text" width={"80%"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="text" width={"80%"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="text" width={"80%"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="text" width={"80%"} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  </Grid>
);

export default IndexSkeleton;
