import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const EventDetails = ({ event }) => {
  const {t}=useTranslation();
  return (
    <Box>
      <Typography variant="body2" color="textSecondary">
        {event.date}
      </Typography>
      <Typography variant="body1">{event.details}</Typography>

      {/* Lab Results */}
      {event.type === "lab" && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">{t("profile.labResult")}</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("profile.test")}</TableCell>
                <TableCell>{t("profile.result")}</TableCell>
                <TableCell>{t("profile.normalRange")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {event.results.map((result, index) => (
                <TableRow key={index}>
                  <TableCell>{result.test}</TableCell>
                  <TableCell>{result.result}</TableCell>
                  <TableCell>{result.normalRange}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}

      {/* Costs */}
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1">
          <strong>{t("profile.cost")}:</strong> ${event.cost}
        </Typography>
      </Box>
    </Box>
  );
};

export default EventDetails;
