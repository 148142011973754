import React, { useState } from "react";
import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";

import UltrasoundReferral from "./UltraSoundReferral/UltrasoundReferral";
import EchoReferral from "./EchoReferral/EchoReferral";
import XRayReferral from "./XreyReferal/XRayReferral";
import PatientTestReferral from "./PatientTestReferral/PatientTestReferral";

// Custom Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#f8f9fa",
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: "bold",
  fontSize: "15px",
  color: "#071952",
  "&.Mui-selected": {
    color: "#071952",
    backgroundColor: "rgba(7, 25, 82, 0.1)",
    borderRadius: theme.shape.borderRadius,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#071952",
  fontWeight: "bold",
}));

// Main Patient Component
const Patients = () => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Grid container mt={2} mb={10}>
      <Grid item xs={12} lg={12}>
        <StyledPaper>
          {/* Patient Information Section */}
          <StyledTypography variant="h5" gutterBottom>
            {t("doctor.patientInformation")}
          </StyledTypography>
          <Grid container spacing={2} mt={2}>
            {/* Fields like Patient ID, Name, Date, Medical Condition, Age, Gender */}
          </Grid>

          {/* Test Referral Section */}
          <StyledTypography variant="h6" gutterBottom mt={4}>
            {t("doctor.referForTests")}
          </StyledTypography>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            centered
            variant="scrollable"
            scrollButtons="auto"
          >
            <StyledTab label={t("doctor.referXray")} />
            <StyledTab label={t("doctor.referUltrasound")} />
            <StyledTab label={t("doctor.referEcho")} />
            <StyledTab label={t("doctor.referLab")} />
          </Tabs>

          {/* Test Referral Tab Content */}
          <Box mt={2}>
            {selectedTab === 0 && <XRayReferral />}
            {selectedTab === 1 && <UltrasoundReferral />}
            {selectedTab === 2 && <EchoReferral />}
            {selectedTab === 3 && <PatientTestReferral />}
          </Box>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default Patients;
