import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useAuth } from "../../../../../context";
import LoadingButton from "@mui/lab/LoadingButton";
import useFetchObjects from "../../../../../api/useFetchObjects";
import useAdd from "../../../../../api/useAdd";
import { useTranslation } from "react-i18next";

const AddAppointment = ({ open, onClose }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const token = user?.token;

  const getCurrentDate = () => new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD
  const getCurrentTime = () => new Date().toTimeString().split(" ")[0]; // Format: HH:mm:ss

  const [formData, setFormData] = useState({
    patient: "",
    doctor: "",
    sickness_history: "",
    timeOfVisit: getCurrentTime(), // Default time
    dateOfVisit: getCurrentDate(), // Default date
    due: "",
    fees: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const { data: users } = useFetchObjects(["UsersList"], `users/`, token);
  const { data: patientsData } = useFetchObjects(
    ["PatientsList"],
    `patients/`,
    token
  );

  const { handleAdd } = useAdd("appointments", token);

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) errors[key] = t("reception.requiredField");
    });

    if (Object.keys(errors).length === 0) {
      const appointmentData = new FormData();
      Object.keys(formData).forEach((key) =>
        appointmentData.append(key, formData[key])
      );
      handleAdd(appointmentData);
      onClose();
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const handlePatientChange = (e, value) => {
    setFormData({ ...formData, patient: value?.id });
    setFormErrors({ ...formErrors, patient: "" });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <form onSubmit={handleSubmit}>
        <DialogTitle
          style={{
            backgroundColor: "#3f51b5",
            color: "white",
            textAlign: "center",
          }}
        >
          <Typography variant="h6">{t("reception.createOPD")}</Typography>
        </DialogTitle>
        <DialogContent style={{ padding: "2rem" }}>
          <Grid spacing={4} container>
            <Grid xs={12} md={6} item>
              <Autocomplete
                options={patientsData?.results || []}
                getOptionLabel={(option) =>
                  `${option.firstname} ${option.lastname} (${option.id})`
                }
                size="small"
                fullWidth
                onChange={handlePatientChange}
                filterOptions={(options, { inputValue }) => {
                  if (!inputValue) return [];
                  return options.filter((option) =>
                    `${option.firstname} ${option.lastname} ${option.id}`
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  );
                }}
                noOptionsText={t("reception.noOptions")}
                loading={!patientsData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="patient"
                    label={t("reception.patientName")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <TextField
                select
                autoFocus
                size="small"
                label={t("reception.doctor")}
                name="doctor"
                type="text"
                fullWidth
                onChange={handleChange}
                variant="outlined"
                required
              >
                {users &&
                  users.map((doctor) => (
                    <MenuItem value={doctor.id} key={doctor.id}>
                      {doctor.first_name} {doctor.last_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid xs={12} item>
              <TextField
                autoFocus
                size="small"
                label={t("reception.sickness_history")}
                name="sickness_history"
                type="text"
                fullWidth
                onChange={handleChange}
                variant="outlined"
                multiline
                rows={2}
                required
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <TextField
                autoFocus
                size="small"
                label={t("reception.timeOfVisit")}
                name="timeOfVisit"
                type="time"
                fullWidth
                onChange={handleChange}
                value={formData.timeOfVisit}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <TextField
                autoFocus
                size="small"
                label={t("reception.dateOfVisit")}
                name="dateOfVisit"
                type="date"
                fullWidth
                onChange={handleChange}
                value={formData.dateOfVisit}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <TextField
                autoFocus
                size="small"
                label={t("reception.due")}
                name="due"
                type="number"
                fullWidth
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <TextField
                autoFocus
                size="small"
                label={t("reception.fees")}
                name="fees"
                type="number"
                fullWidth
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "20px" }}
        >
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            style={{ margin: "0px 10px" }}
          >
            {t("reception.cancel")}
          </Button>
          <LoadingButton type="submit" color="primary" variant="contained">
            {t("reception.save")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddAppointment;
