import React from "react";
import AddCompanyMedicine from "./addCompanyMedicine/AddCompanyMedicine";
import ListCompanyMedicine from "./ListCompanyMedicine/ListCompanyMedicine";

const ManageCompanyMedicine = () => {
  return (
    <>
      <AddCompanyMedicine />
      <ListCompanyMedicine />
    </>
  );
};

export default ManageCompanyMedicine;
