import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import useDelete from "../../../../api/useDelete";
import SkeletonLoading from "./loading/SkeletonLoading";
import { useTranslation } from "react-i18next";
import UpdateLaboratoryCategoryDialog from "./UpdateLaboratoryCategoryDialog";

const ListLaboratoryCategory = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const { data, isLoading, isError, error, refetch } = useFetchObjects(
    ["laboratory-test-categories"],
    `laboratory-test-categories/?search=${searchTerm}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [searchTerm]);

  const { handleDelete, ConfirmDialog } = useDelete(
    "laboratory-test-categories",
    token
  );

  // Open dialog for editing
  const handleEdit = (category) => {
    setSelectedCategory(category);
    setOpenDialog(true);
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCategory(null);
  };

  return (
    <Paper
      sx={{
        padding: "30px 40px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <Typography variant="h5" gutterBottom>
        {t("laboratory.listLabCategories")}
      </Typography>

      {/* Search bar */}
      <TextField
        label={t("laboratory.search")}
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 4 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder={t("laboratory.searchByCategoryName")}
      />

      {/* Loading state */}
      {isLoading && <SkeletonLoading />}
      {isError && (
        <Typography color="error">
          {t("laboratory.error")}: {error.message}
        </Typography>
      )}

      {/* Laboratory categories table */}
      {data && (
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead style={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.categoryName")}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {t("laboratory.action")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((category) => (
                <TableRow
                  key={category.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": { backgroundColor: "#f9f9f9" },
                    transition: "0.3s",
                  }}
                >
                  <TableCell align="center">{category.name}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(category)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(category.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <ConfirmDialog />

      {/* No data found state */}
      {!isLoading && data?.length === 0 && (
        <Typography variant="body1" color="textSecondary" mt={2}>
          {t("laboratory.noLabCategoriesFound")}
        </Typography>
      )}

      {/* Update Dialog */}
      {selectedCategory && (
        <UpdateLaboratoryCategoryDialog
          open={openDialog}
          onClose={handleCloseDialog}
          category={selectedCategory}
          token={token}
          refetch={refetch}
        />
      )}
    </Paper>
  );
};

export default ListLaboratoryCategory;
