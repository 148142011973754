import { Box, Grid, Paper, Tabs, Tab } from "@mui/material";
import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const tabStyle = {
  textTransform: "capitalize",
  fontWeight: "bold",
  borderRadius: "10px",
};

const UltraSoundNav = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("/ultra-sound");
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    const path = location.pathname;
    const validPaths = [
      "/ultra-sound",
      "/ultra-sound/test-result",
      "/ultra-sound/ultrasound-type",
    ];

    if (validPaths.includes(path)) {
      setValue(path);
    } else {
      setValue("/ultra-sound");
    }
  }, [location.pathname]);

  return (
    <Grid container maxWidth="xl">
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Paper sx={{ padding: "10px 0px", boxShadow: "0px 0px 0px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ ml: "5px", "& .MuiTabs-indicator": { display: "none" } }}
          >
            <Tab
              style={{
                ...tabStyle,
                backgroundColor: value === "/ultra-sound" ? "#007174" : "white",
                color: value === "/ultra-sound" ? "white" : "black",
              }}
              label={t("ultrasound.dashboard")}
              value="/ultra-sound"
              component={NavLink}
              to="/ultra-sound"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/ultra-sound/test-result" ? "#007174" : "white",
                color: value === "/ultra-sound/test-result" ? "white" : "black",
              }}
              label={t("ultrasound.testResult")}
              value="/ultra-sound/test-result"
              component={NavLink}
              to="/ultra-sound/test-result"
            />
            <Tab
              style={{
                ...tabStyle,
                backgroundColor:
                  value === "/ultra-sound/ultrasound-type"
                    ? "#007174"
                    : "white",
                color:
                  value === "/ultra-sound/ultrasound-type" ? "white" : "black",
              }}
              label={t("ultrasound.ultrasoundType")}
              value="/ultra-sound/ultrasound-type"
              component={NavLink}
              to="/ultra-sound/ultrasound-type"
            />
          </Tabs>
        </Paper>
      </Box>
      <Outlet />
    </Grid>
  );
};

export default UltraSoundNav;
