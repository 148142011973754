// src/routes/ultraSoundRoutes.js
import UltraSoundNav from "../../pages/Dashboard/UltraSound/UltraSoundNav";
import UtrlaSoundIndex from "../../pages/Dashboard/UltraSound/UltraSoundIndex";
import TestResult from "../../pages/Dashboard/UltraSound/testResult/TestResult";
import UltrasoundType from "../../pages/Dashboard/UltraSound/UltrasoundType/UltrasoundTypes";

const ultraSoundRoutes = {
  path: "/ultra-sound",
  element: <UltraSoundNav />,
  children: [
    { path: "/ultra-sound", element: <UtrlaSoundIndex /> },
    { path: "/ultra-sound/test-result", element: <TestResult /> },
    { path: "/ultra-sound/ultrasound-type", element: <UltrasoundType /> },
  ],
};

export default ultraSoundRoutes;
