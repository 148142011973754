
import React from "react";
import { Box, Skeleton, Stack } from "@mui/material";

const Loading = () => {
  return (
    <Box sx={{ padding: "1rem" }}>
      <Stack spacing={2}>
        <Skeleton variant="text" width="50%" height={40} />
        <Skeleton variant="rectangular" height={56} />
        <Skeleton variant="text" width="30%" height={40} />
        <Skeleton variant="rectangular" height={56} />
        <Skeleton variant="text" width="20%" height={40} />
        <Skeleton variant="rectangular" height={56} />
        <Skeleton variant="text" width="40%" height={40} />
      </Stack>
    </Box>
  );
};

export default Loading;
