import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
  Avatar,
  TextField,
  InputAdornment,
} from "@mui/material";
import useFetchObjects from "../../../../api/useFetchObjects";
import { useAuth } from "../../../../context";
import { Inventory2, Search } from "@mui/icons-material";
import { StockMedicineSkeleton } from "./loading/StockMedicineSkeleton";
import { useTranslation } from "react-i18next";
const tableStyle = {
  textAlign: "center",
};

const StockMedicine = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;
  const pharmacy = auth?.user?.user?.pharmacy_type;

  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: stockMedicines,
    isLoading,
    isError,
    error,
    refetch,
  } = useFetchObjects(
    ["stock-medicines"],
    `stock-medicines/?pharmacy=${pharmacy}&search=${searchQuery}`,
    token
  );

  useEffect(() => {
    refetch();
  }, [searchQuery]);

  if (isLoading) {
    return <StockMedicineSkeleton />;
  }

  if (isError) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">{error.message}</Typography>
      </Box>
    );
  }

  return (
    <Container>
      <Paper
        sx={{
          boxShadow: "0px 0px 16px rgba(0,0,0,0.1)",
          borderRadius: "12px",
          marginTop: "2rem",
          padding: { xs: "1.5rem", md: "2.5rem" },
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Avatar sx={{ bgcolor: "#1976d2", width: 56, height: 56 }}>
              <Inventory2 fontSize="large" />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography fontWeight={700} variant="h4" color="primary">
              {t("pharmacy.stockMedicine")}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {t("pharmacy.overview")}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder={t("pharmacy.searchPlaceholder")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ marginBottom: "1.5rem" }}
          />
        </Box>

        <TableContainer component={Box} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f0f0f0" }}>
                <TableCell style={tableStyle}>
                  <Typography fontWeight={700} color="primary">
                    #
                  </Typography>
                </TableCell>
                <TableCell style={tableStyle}>
                  <Typography fontWeight={700} color="primary">
                    {t("pharmacy.category")}
                  </Typography>
                </TableCell>
                <TableCell style={tableStyle}>
                  <Typography fontWeight={700} color="primary">
                    {t("pharmacy.medicineName")}
                  </Typography>
                </TableCell>
                <TableCell style={tableStyle}>
                  <Typography fontWeight={700} color="primary">
                    {t("pharmacy.expiryDate")}
                  </Typography>
                </TableCell>
                <TableCell style={tableStyle}>
                  <Typography fontWeight={700} color="primary">
                    {t("pharmacy.quantity")}
                  </Typography>
                </TableCell>
                <TableCell style={tableStyle}>
                  <Typography fontWeight={700} color="primary">
                    {t("pharmacy.sellPrice")} (؋)
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stockMedicines.map((stock, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:hover": { backgroundColor: "#f9f9f9" } }}
                >
                  <TableCell style={tableStyle}>
                    <Typography>{index + 1}</Typography>
                  </TableCell>
                  <TableCell style={tableStyle}>
                    <Typography>{stock.category_display}</Typography>
                  </TableCell>
                  <TableCell style={tableStyle}>
                    <Typography>{stock.medicine_display}</Typography>
                  </TableCell>
                  <TableCell style={tableStyle}>
                    <Typography>{stock.expiry_date}</Typography>
                  </TableCell>
                  <TableCell style={tableStyle}>
                    <Typography>{stock.quantity}</Typography>
                  </TableCell>
                  <TableCell style={tableStyle}>
                    <Typography>{stock.sell_price} ؋</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default StockMedicine;
