import React, { useState } from "react";
import {
  Grid,
  Alert,
  CircularProgress,
  Box,
  Button,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TotalProfit from "./TotalProfit/TotalProfit";
import TotalExpenses from "./TotalExpenses/TotalExpenses";
import TotalCustomers from "./TotalCustomers/TotalCustomers";
import OverallIncome from "./OverallIncome/OverallIncome";
import PharmacyReport from "./PharmacyReport/PharmacyReport";
import useFetchObjects from "../../../../api/useFetchObjects";
import "./DatePickerStyles.css"; // Custom styles if needed

const Index = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fetchParams, setFetchParams] = useState(
    "finance-report/overall_finance_report/"
  );

  const handleFilter = () => {
    const params = new URLSearchParams();
    if (startDate)
      params.append("start_date", startDate.toISOString().split("T")[0]);
    if (endDate) params.append("end_date", endDate.toISOString().split("T")[0]);

    setFetchParams(
      `finance-report/overall_finance_report/?${params.toString()}`
    );
  };

  const { data, isLoading, isError, error } = useFetchObjects(
    ["finance-report", fetchParams],
    fetchParams
  );

  return (
    <Box sx={{ width: "100%", padding: "20px" }}>
      {/* Header and Filter Controls */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
          Finance Report
        </Typography>

        {/* Date Range Filter */}
        <Box display="flex" alignItems="center" gap={2}>
          <Box>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="Start Date"
              className="date-picker-input"
            />
          </Box>
          <Box>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="End Date"
              className="date-picker-input"
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFilter}
            sx={{ height: "fit-content" }}
          >
            Apply Filter
          </Button>
        </Box>
      </Box>

      {/* Loading, Error, and Report Data */}
      {isLoading ? (
        <CircularProgress />
      ) : isError ? (
        <Alert severity="error">{error.message}</Alert>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={6}>
            <TotalProfit summaryData={data.summary || {}} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TotalExpenses
              payrollAndExpensesData={data.payroll_and_expenses || {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TotalCustomers
              roomData={data.room || {}}
              appointmentData={data.appointment || {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <OverallIncome
              appointmentData={data.appointment || {}}
              labData={data.lab || {}}
              radiologyData={data.radiology || {}}
            />
          </Grid>
          <Grid item xs={12}>
            <PharmacyReport
              medicineSalesData={data.medicine_sales || {}}
              prescriptionData={data.prescription || {}}
              echoData={data.echo || {}}
              purchaseData={data.purchase || {}}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Index;
