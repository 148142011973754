import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      language: require("./English/LanguageSelector.json"),
      navbar: require("./English/Navbar/Navbar.json"),
      reception: require("./English/Reception/Reception.json"),
      sign_in: require("./English/Signin/Signin.json"),
      use_add: require("./English/Api/UseAdd.json"),
      use_delete: require("./English/Api/UseDelete.json"),
      use_update: require("./English/Api/UseUpdate.json"),
      hr: require("./English/HumanResource/HR.json"),
      nurse: require("./English/Nurse/Nurse.json"),
      doctor: require("./English/Doctor/Doctor.json"),
      pharmacy: require("./English/Pharmacy/Pharmacy.json"),
      operations: require("./English/Operations/Operations.json"),
      finance: require("./English/Finance/Finance.json"),
      room: require("./English/Room/Room.json"),
      x_ray: require("./English/X-ray/X-ray.json"),
      ultrasound: require("./English/UltraSound/UltraSound.json"),
      echo: require("./English/Echocardiography/Echocardiography.json"),
      laboratory: require("./English/Laboratory/Laboratory.json"),
      overview:require("./English/ActivityOverView/ActivityOverView.json"),
      profile:require("./English/PatientProfile/PatientProfile.json")
    },
  },
  dr: {
    translation: {
      language: require("./Dari/LanguageSelector.json"),
      navbar: require("./Dari/Navbar/Navbar.json"),
      reception: require("./Dari/Reception/Reception.json"),
      sign_in: require("./Dari/Signin/Signin.json"),
      use_add: require("./Dari/Api/UseAdd.json"),
      use_delete: require("./Dari/Api/UseDelete.json"),
      use_update: require("./Dari/Api/UseUpdate.json"),
      hr: require("./Dari/HumanResource/HR.json"),
      nurse: require("./Dari/Nurse/Nurse.json"),
      doctor: require("./Dari/Doctor/Doctor.json"),
      pharmacy: require("./Dari/Pharmacy/Pharmacy.json"),
      operations: require("./Dari/Operations/Operations.json"),
      finance: require("./Dari/Finance/Finance.json"),
      room: require("./Dari/Room/Room.json"),
      x_ray: require("./Dari/X-ray/X-ray.json"),
      ultrasound: require("./Dari/UltraSound/UltraSound.json"),
      echo: require("./Dari/Echocardiography/Echocardiography.json"),
      laboratory: require("./Dari/Laboratory/Laboratory.json"),
      overview:require("./Dari/ActivityOverView/ActivityOverView.json"),
      profile:require("./Dari/PatientProfile/PatientProfile.json"),
    },
  },
  ps: {
    translation: {
      language: require("./Pashto/LanguageSelector.json"),
      navbar: require("./Pashto/Navbar/Navbar.json"),
      reception: require("./Pashto/Reception/Reception.json"),
      sign_in: require("./Pashto/Signin/Signin.json"),
      use_add: require("./Pashto/Api/UseAdd.json"),
      use_delete: require("./Pashto/Api/UseDelete.json"),
      use_update: require("./Pashto/Api/UseUpdate.json"),
      hr: require("./Pashto/HumanResource/HR.json"),
      nurse: require("./Pashto/Nurse/Nurse.json"),
      doctor: require("./Pashto/Doctor/Doctor.json"),
      pharmacy: require("./Pashto/Pharmacy/Pharmacy.json"),
      operations: require("./Pashto/Operations/Operations.json"),
      finance: require("./Pashto/Finance/Finance.json"),
      room: require("./Pashto/Room/Room.json"),
      x_ray: require("./Pashto/X-ray/X-ray.json"),
      ultrasound: require("./Pashto/UltraSound/UltraSound.json"),
      echo: require("./Pashto/Echocardiography/Echocardiography.json"),
      laboratory: require("./Pashto/Laboratory/Laboratory.json"),
      overview:require("./Pashto/ActivityOverView/ActivityOverView.json"),
      profile:require("./Pashto/PatientProfile/PatientProfile.json"),
    },
  },
};

// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
