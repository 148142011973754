import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Autocomplete,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Stack,
  Divider,
} from "@mui/material";
import { useAuth } from "../../../../context";
import useFetchObjects from "../../../../api/useFetchObjects";
import useUpdate from "../../../../api/useUpdate";

const ReturnMedicineDetailsEdit = ({
  returnMedicineDetail,
  onClose,
  token,
  pharmacy,
}) => {
  const [returnOption, setReturnOption] = useState(
    returnMedicineDetail.prescription_detail ? "prescription" : "order"
  );
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState(returnMedicineDetail.quantity || "");
  const [formErrors, setFormErrors] = useState({});
  const { data: prescriptions, isLoading: loadingPrescriptions } =
    useFetchObjects(
      ["prescriptions"],
      `prescriptions?drugs__stock_medicine__pharmacy=${pharmacy}`,
      token
    );
  const { data: orders, isLoading: loadingOrders } = useFetchObjects(
    ["medicine-orders"],
    `medicine-orders?items__stock_medicine__pharmacy=${pharmacy}`,
    token
  );

  const { handleUpdate, loading: updating } = useUpdate(
    "medicine-returns",
    token
  );

  // Pre-select the prescription, order, and item when component mounts
  useEffect(() => {
    if (returnMedicineDetail.prescription_detail && prescriptions) {
      const matchedPrescription = prescriptions.find((prescription) =>
        prescription.drugs.some(
          (drug) => drug.id === returnMedicineDetail.prescription_detail
        )
      );
      if (matchedPrescription) {
        setSelectedPrescription(matchedPrescription);
        setItems(matchedPrescription.drugs);
        const matchedItem = matchedPrescription.drugs.find(
          (drug) => drug.id === returnMedicineDetail.prescription_detail
        );
        setSelectedItem(matchedItem);
      }
    } else if (returnMedicineDetail.medicine_order_item && orders) {
      const matchedOrder = orders.find((order) =>
        order.items.some(
          (item) => item.id === returnMedicineDetail.medicine_order_item
        )
      );
      if (matchedOrder) {
        setSelectedOrder(matchedOrder);
        setItems(matchedOrder.items);
        const matchedItem = matchedOrder.items.find(
          (item) => item.id === returnMedicineDetail.medicine_order_item
        );
        setSelectedItem(matchedItem);
      }
    }
  }, [returnMedicineDetail, prescriptions, orders]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedItem || !quantity || quantity <= 0) {
      setFormErrors({ quantity: "Please enter a valid quantity." });
      return;
    }

    const payload = {
      stock_medicine: selectedItem.stock_medicine,
      quantity: parseInt(quantity, 10),
      prescription_detail:
        returnOption === "prescription" ? selectedItem.id : null,
      medicine_order_item: returnOption === "order" ? selectedItem.id : null,
    };

    handleUpdate(returnMedicineDetail.id, payload);
    if (!updating) {
      onClose();
    }
  };

  return (
    <Card
      sx={{ boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.1)", borderRadius: 4 }}
    >
      <CardContent sx={{ p: 4 }}>
        <Typography
          variant="h5"
          fontWeight={600}
          color="primary"
          textAlign="center"
          gutterBottom
        >
          Edit Return Medicine
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Box mb={4}>
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            Select Return Option
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="return-option-label">Return Option</InputLabel>
            <Select
              labelId="return-option-label"
              value={returnOption}
              onChange={(e) => {
                setReturnOption(e.target.value);
                setSelectedItem(null);
                setSelectedPrescription(null);
                setSelectedOrder(null);
                setItems([]);
              }}
              label="Return Option"
            >
              <MenuItem value="prescription">Prescription</MenuItem>
              <MenuItem value="order">Direct Order</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {returnOption === "prescription" && !loadingPrescriptions && (
          <Box mb={4}>
            <Typography variant="subtitle1" fontWeight={500} mb={2}>
              Select Prescription
            </Typography>
            <Autocomplete
              options={prescriptions || []}
              getOptionLabel={(option) =>
                `Prescription #${option.id} - ${option.patient_display} (Age: ${option.patient_age_display})`
              }
              value={selectedPrescription}
              onChange={(event, newValue) => {
                setSelectedPrescription(newValue);
                setItems(newValue ? newValue.drugs : []);
                setSelectedItem(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Prescription"
                  variant="outlined"
                />
              )}
              sx={{ backgroundColor: "#fff", borderRadius: 2 }}
            />
          </Box>
        )}

        {returnOption === "order" && !loadingOrders && (
          <Box mb={4}>
            <Typography variant="subtitle1" fontWeight={500} mb={2}>
              Select Order
            </Typography>
            <Autocomplete
              options={orders || []}
              getOptionLabel={(option) =>
                `Order #${option.id} - Total: ${option.total_price}؋`
              }
              value={selectedOrder}
              onChange={(event, newValue) => {
                setSelectedOrder(newValue);
                setItems(newValue ? newValue.items : []);
                setSelectedItem(null);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Order" variant="outlined" />
              )}
              sx={{ backgroundColor: "#fff", borderRadius: 2 }}
            />
          </Box>
        )}

        {(selectedPrescription || selectedOrder) && (
          <Box mb={4}>
            <Typography variant="subtitle1" fontWeight={500} mb={2}>
              Select Item
            </Typography>
            <Autocomplete
              options={items}
              getOptionLabel={(option) =>
                `${option.stock_medicine_display} - Quantity: ${option.quantity}`
              }
              value={selectedItem}
              onChange={(event, newValue) => setSelectedItem(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Item" variant="outlined" />
              )}
              sx={{ backgroundColor: "#fff", borderRadius: 2 }}
            />
          </Box>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Quantity"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              fullWidth
              variant="outlined"
              required
              error={!!formErrors.quantity}
              helperText={formErrors.quantity}
              sx={{ backgroundColor: "#fff", borderRadius: 2 }}
            />
          </Grid>
        </Grid>

        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 4 }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            sx={{ fontWeight: 700 }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={updating}
            sx={{
              fontWeight: 700,
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
          >
            {updating ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Save Changes"
            )}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ReturnMedicineDetailsEdit;
