import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";
import ListLaboratoryCategory from "./ListLaboratoryCategory";

const LaboratoryCategory = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
  });
  const [errors, setErrors] = useState({});

  const auth = useAuth();
  const token = auth?.user?.token;
  const { handleAdd, loading, resetForm } = useAdd(
    "laboratory-test-categories",
    token
  );

  useEffect(() => {
    if (resetForm) {
      setFormData({
        name: "",
      });
      setErrors({});
    }
  }, [resetForm]);

  const validate = () => {
    let newErrors = {};
    if (!formData.name) newErrors.name = t("laboratory.categoryNameRequired");

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleAdd(formData);
    }
  };

  return (
    <Grid container mt={2} mb={10}>
      <Grid item xs={12} lg={12}>
        <Paper
          component="form"
          onSubmit={handleSubmit}
          sx={{
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            padding: "30px 40px",
            borderRadius: "8px",
            mb: 5,
            width: "100%",
          }}
        >
          <Typography variant="h5" gutterBottom>
            {t("laboratory.addLabCategory")}
          </Typography>

          {/* Laboratory Category Name Input */}
          <TextField
            label={t("laboratory.categoryName")}
            fullWidth
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            sx={{ mb: 3 }}
            error={!!errors.name}
            helperText={errors.name}
          />

          <Button
            variant="contained"
            disabled={loading}
            type="submit"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              padding: "10px 20px",
              backgroundColor: "#071952",
              ":hover": {
                backgroundColor: "#071952",
              },
            }}
          >
            {loading ? t("laboratory.saving") : t("laboratory.addCategory")}
          </Button>
        </Paper>

        <Divider sx={{ my: 4 }} />
        <ListLaboratoryCategory />
      </Grid>
    </Grid>
  );
};

export default LaboratoryCategory;
