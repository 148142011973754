import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import useAdd from "../../../../api/useAdd";
import { useAuth } from "../../../../context";
import ListEchoTypes from "./ListEchoTypes";
import { useTranslation } from "react-i18next";

const EchoType = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    cost: "",
  });

  const [errors, setErrors] = useState({});
  const auth = useAuth();
  const token = auth?.user?.token;
  const { handleAdd, loading } = useAdd("echo-types", token);

  const validate = () => {
    let newErrors = {};
    if (!formData.name) {
      newErrors.name = t("echo.echoTypeNameRequired");
    }
    if (formData.cost === "") {
      newErrors.cost = t("echo.costRequired");
    } else if (Number(formData.cost) <= 0) {
      newErrors.cost = t("echo.costBePositiveNumber");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleAdd(formData);
      setFormData({ name: "", description: "", cost: "" });
      setErrors({});
    }
  };

  return (
    <Grid container maxWidth={"xl"} mt={2} justifyContent={"center"} mb={10}>
      <Grid item xs={12} lg={8}>
        <Paper
          component="form"
          onSubmit={handleSubmit}
          sx={{
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            padding: "30px 40px",
            borderRadius: "8px",
            mb: 5,
          }}
        >
          <Typography variant="h5" gutterBottom>
            {t("echo.addNewEchoType")}
          </Typography>

          <TextField
            label={t("echo.echoTypeName")}
            fullWidth
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            sx={{ mb: 3 }}
            error={!!errors.name}
            helperText={errors.name}
          />

          <TextField
            label={t("echo.description")}
            fullWidth
            multiline
            rows={4}
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
            sx={{ mb: 3 }}
          />

          <TextField
            label={t("echo.cost")}
            fullWidth
            type="number"
            value={formData.cost}
            onChange={(e) => setFormData({ ...formData, cost: e.target.value })}
            sx={{ mb: 3 }}
            error={!!errors.cost}
            helperText={errors.cost}
          />

          <Button
            variant="contained"
            disabled={loading}
            type="submit"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              padding: "10px 20px",
              backgroundColor: "#071952",
              ":hover": {
                backgroundColor: "#071952",
              },
            }}
          >
            {loading ? t("echo.saving") : t("echo.addType")}
          </Button>
        </Paper>

        <Divider sx={{ marginY: 4 }} />

        <ListEchoTypes />
      </Grid>
    </Grid>
  );
};

export default EchoType;
