import React, { useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Stack,
  Tooltip,
  Card,
  CardContent,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { RemoveCircleOutline, Search } from "@mui/icons-material";
import useFetchObjects from "../../../../api/useFetchObjects";
import useAdd from "../../../../api/useAdd";
import PrescriptionList from "./PrescriptionList";
import { useAuth } from "../../../../context";
import { useTranslation } from "react-i18next";

const getCurrentDate = () => new Date().toISOString().split("T")[0];
const getCurrentTime = () =>
  new Date().toTimeString().split(":").slice(0, 2).join(":");

const AddPrescription = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.user?.token;

  const { data: patients } = useFetchObjects(["patient"], "patients", token);
  const { data: medicines } = useFetchObjects(
    ["stock-medicines"],
    `stock-medicines/`,
    token
  );

  const [input, setInput] = useState({
    date: getCurrentDate(),
    time: getCurrentTime(),
    doctor: auth?.user?.user?.id || "",
    patient: "",
    duration: "",
    total_price: 0,
    isManualTotal: false,
    items: [],
  });

  const [formErrors, setFormErrors] = useState({});
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const autocompleteRef = useRef(null);
  const dosageRefs = useRef([]);
  const quantityRefs = useRef([]);
  const priceRefs = useRef([]);
  const notationRefs = useRef([]);
  const isBeforeRefs = useRef([]);
  const unitsPerMealRefs = useRef([]);

  const {
    handleAdd: handleAddPrescription,
    resetForm,
    loading,
  } = useAdd(
    "prescriptions",
    token,
    t("pharmacy.prescriptionCreatedSuccess"),
    t("pharmacy.prescriptionCreateFail")
  );

  useEffect(() => {
    if (resetForm) {
      setInput({
        date: getCurrentDate(),
        time: getCurrentTime(),
        doctor: auth?.user?.user?.id || "",
        patient: "",
        duration: "",
        total_price: 0,
        items: [],
      });
      setFormErrors({});
    }
  }, [resetForm]);

  const handleChangePrescriptionField = (e) => {
    const { name, value } = e.target;
    if (name === "total_price") {
      setInput({ ...input, [name]: parseInt(value) || 0, isManualTotal: true });
    } else {
      setInput({ ...input, [name]: value });
    }
  };

  const handleAddNewItem = (medicine) => {
    if (medicine) {
      setInput((prevInput) => {
        const newItems = [
          ...prevInput.items,
          {
            stock_medicine: medicine.id,
            name: medicine.medicine_display,
            quantity: 1,
            dosage: 1,
            sell_price: parseFloat(medicine.sell_price).toFixed(2), // Ensure two decimal places
            total_price: parseFloat(medicine.sell_price).toFixed(2),
            available_stock: medicine.quantity,
            notation: "",
            is_before: "after",
            units_per_meal: 1,
          },
        ];
        return {
          ...prevInput,
          items: newItems,
          total_price: calculateTotalPrice(newItems),
          isManualTotal: false,
        };
      });

      setAutocompleteInputValue("");
      if (autocompleteRef.current) {
        autocompleteRef.current.focus();
      }
    }
  };

  const handleChangeItemField = (index, e) => {
    const { name, value } = e.target;
    const items = [...input.items];

    if (name === "quantity" || name === "sell_price") {
      items[index][name] = value ? parseFloat(value) : "";
      items[index].total_price = (
        parseFloat(items[index].sell_price || 0) *
        parseFloat(items[index].quantity || 0)
      ).toFixed(2);
    } else {
      items[index][name] = value;
    }

    setInput({ ...input, items, total_price: calculateTotalPrice(items) });
  };

  const handleKeyDown = (e, index, field) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (field === "dosage" && unitsPerMealRefs.current[index]) {
        unitsPerMealRefs.current[index].focus();
      } else if (field === "units_per_meal" && quantityRefs.current[index]) {
        quantityRefs.current[index].focus();
      } else if (field === "quantity" && notationRefs.current[index]) {
        notationRefs.current[index].focus();
      } else if (field === "notation" && dosageRefs.current[index + 1]) {
        dosageRefs.current[index + 1].focus();
      }
    }
  };

  const handleRemoveItem = (index) => {
    const items = [...input.items];
    items.splice(index, 1);
    setInput({ ...input, items, total_price: calculateTotalPrice(items) });
  };

  const calculateTotalPrice = (items) => {
    if (input.isManualTotal) return parseFloat(input.total_price).toFixed(2);
    return items
      .reduce((sum, item) => sum + parseFloat(item.total_price || 0), 0)
      .toFixed(2);
  };

  const handleSave = (e) => {
    e.preventDefault();
    let isValid = true;
    let errors = {};

    if (!input.date) {
      errors.date = t("pharmacy.dateRequired");
      isValid = false;
    }
    if (!input.doctor) {
      errors.doctor = t("pharmacy.doctorRequired");
      isValid = false;
    }
    if (!input.patient) {
      errors.patient = t("pharmacy.patientRequired");
      isValid = false;
    }

    input.items.forEach((item, index) => {
      if (!item.stock_medicine) {
        errors[`stock_medicine_${index}`] = t("pharmacy.medicineRequired");
        isValid = false;
      }
      if (!item.quantity) {
        errors[`quantity_${index}`] = t("pharmacy.quantityRequired");
        isValid = false;
      }
      if (!item.sell_price) {
        errors[`sell_price_${index}`] = t("pharmacy.priceRequired");
        isValid = false;
      }
    });

    setFormErrors(errors);

    if (isValid) {
      const items = input.items.map((item) => ({
        ...item,
        quantity: parseFloat(item.quantity),
        sell_price: parseFloat(item.sell_price),
        total_price: parseFloat(item.total_price),
      }));

      const total_price = input.isManualTotal
        ? parseFloat(input.total_price)
        : parseFloat(calculateTotalPrice(items));

      handleAddPrescription({ ...input, total_price, items });
    }
  };

  return (
    <Card
      sx={{
        boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
        backgroundColor: "#f4f6f8",
        width: "100%",
      }}
    >
      <CardContent sx={{ p: 4 }}>
        <Typography
          variant="h5"
          fontWeight={600}
          color="primary"
          textAlign="center"
          gutterBottom
        >
          {t("pharmacy.addPrescriptionTitle")}
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Box mb={4}>
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            {t("pharmacy.patientInfo")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={t("pharmacy.prescriptionDate")}
                name="date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={input.date}
                onChange={handleChangePrescriptionField}
                error={!!formErrors.date}
                helperText={formErrors.date}
                sx={{ backgroundColor: "#fff", borderRadius: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={t("pharmacy.time")}
                name="time"
                type="time"
                InputLabelProps={{ shrink: true }}
                value={input.time}
                onChange={handleChangePrescriptionField}
                error={!!formErrors.time}
                helperText={formErrors.time}
                sx={{ backgroundColor: "#fff", borderRadius: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={patients?.results || []}
                getOptionLabel={(option) =>
                  `${option.firstname} (ID: ${option.id})`
                } // Display name and ID in dropdown
                value={
                  patients?.results.find(
                    (patient) => patient.id === input.patient
                  ) || null
                }
                onChange={(event, value) =>
                  setInput({ ...input, patient: value ? value.id : "" })
                }
                filterOptions={(options, { inputValue }) =>
                  options.filter(
                    (option) =>
                      option.firstname
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      String(option.id).includes(inputValue)
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("pharmacy.patient")}
                    variant="outlined"
                    error={!!formErrors.patient}
                    helperText={formErrors.patient}
                    sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={t("pharmacy.duration")}
                name="duration"
                variant="outlined"
                value={input.duration}
                onChange={handleChangePrescriptionField}
                error={!!formErrors.duration}
                helperText={formErrors.duration}
                sx={{ backgroundColor: "#fff", borderRadius: 2 }}
              />
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Search Medicine Section */}
        <Box mb={4}>
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            {t("pharmacy.addMedicine")}
          </Typography>
          <Autocomplete
            ref={autocompleteRef}
            value={null}
            inputValue={autocompleteInputValue}
            onInputChange={(event, newInputValue) => {
              setAutocompleteInputValue(newInputValue);
            }}
            onChange={(event, value) => {
              handleAddNewItem(value);
              setAutocompleteInputValue("");
            }}
            options={medicines || []}
            getOptionLabel={(option) =>
              `${option.medicine_display} (${option.category_display})`
            }
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                sx={{
                  padding: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  backgroundColor: "#f9f9f9",
                  borderRadius: 1,
                  "&:hover": {
                    backgroundColor: "#eef2f5",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight={600}
                  sx={{ whiteSpace: "nowrap", color: "text.primary" }}
                >
                  {option.medicine_display}
                </Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: "nowrap",
                    color: "text.secondary",
                  }}
                >
                  <strong>Category:</strong> {option.category_display}
                </Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: "nowrap",
                    color: "text.secondary",
                  }}
                >
                  <strong>Price:</strong> {option.sell_price}
                </Typography>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: "nowrap",
                    color: "text.secondary",
                  }}
                >
                  <strong>Expiry:</strong> {option.expiry_date}
                </Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("pharmacy.searchMedicine")}
                variant="outlined"
                placeholder={t("pharmacy.searchMedicinePlaceholder")}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 2,
                }}
              />
            )}
          />
        </Box>

        {/* Prescription Items Table */}
        <TableContainer component={Paper} elevation={1} sx={{ mb: 4 }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#eef2f5" }}>
              <TableRow>
                <TableCell>{t("pharmacy.medicineName")}</TableCell>
                <TableCell align="center">
                  {t("pharmacy.availableStock")}
                </TableCell>
                <TableCell align="center">{t("pharmacy.dosage")}</TableCell>
                <TableCell align="center" sx={{ fontSize: "0.85rem" }}>
                  {t("pharmacy.unitsPerMeal")}
                </TableCell>
                <TableCell align="center">{t("pharmacy.quantity")}</TableCell>
                <TableCell align="center">{t("pharmacy.price")}</TableCell>
                <TableCell align="center">{t("pharmacy.notation")}</TableCell>
                <TableCell align="center">
                  {t("pharmacy.isBeforeMeal")}
                </TableCell>
                <TableCell align="center">{t("pharmacy.totalPrice")}</TableCell>
                <TableCell align="center">{t("pharmacy.action")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {input.items.map((item, index) => (
                <TableRow key={index} hover>
                  <TableCell>{item.name}</TableCell>
                  <TableCell align="center">{item.available_stock}</TableCell>
                  <TableCell align="center">
                    <TextField
                      inputRef={(el) => (dosageRefs.current[index] = el)}
                      name="dosage"
                      type="number"
                      size="small"
                      variant="outlined"
                      value={item.dosage}
                      onChange={(e) => handleChangeItemField(index, e)}
                      onKeyDown={(e) => handleKeyDown(e, index, "dosage")}
                      sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      inputRef={(el) => (unitsPerMealRefs.current[index] = el)}
                      name="units_per_meal"
                      type="number"
                      size="small"
                      variant="outlined"
                      value={item.units_per_meal}
                      onChange={(e) => handleChangeItemField(index, e)}
                      onKeyDown={(e) =>
                        handleKeyDown(e, index, "units_per_meal")
                      }
                      sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      inputRef={(el) => (quantityRefs.current[index] = el)}
                      name="quantity"
                      type="number"
                      size="small"
                      variant="outlined"
                      value={item.quantity}
                      onChange={(e) => handleChangeItemField(index, e)}
                      onKeyDown={(e) => handleKeyDown(e, index, "quantity")}
                      error={!!formErrors[`quantity_${index}`]}
                      helperText={formErrors[`quantity_${index}`]}
                      sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      inputRef={(el) => (priceRefs.current[index] = el)}
                      name="sell_price"
                      type="number"
                      size="small"
                      variant="outlined"
                      value={item.sell_price}
                      onChange={(e) => handleChangeItemField(index, e)}
                      disabled
                      sx={{ backgroundColor: "#f5f5f5", borderRadius: 2 }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      inputRef={(el) => (notationRefs.current[index] = el)}
                      name="notation"
                      type="text"
                      size="small"
                      variant="outlined"
                      value={item.notation}
                      onChange={(e) => handleChangeItemField(index, e)}
                      onKeyDown={(e) => handleKeyDown(e, index, "notation")}
                      sx={{ backgroundColor: "#fff", borderRadius: 2 }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <FormControl fullWidth size="small">
                      <Select
                        value={item.is_before}
                        onChange={(e) => handleChangeItemField(index, e)}
                        name="is_before"
                        ref={(el) => (isBeforeRefs.current[index] = el)}
                      >
                        <MenuItem value="before">
                          {t("pharmacy.before")}
                        </MenuItem>
                        <MenuItem value="med">{t("pharmacy.med")}</MenuItem>
                        <MenuItem value="after">{t("pharmacy.after")}</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={600}>{item.total_price}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={t("pharmacy.removeItem")} arrow>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveItem(index)}
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Save Button and Total Price Display */}
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <TextField
            size="small"
            label={t("pharmacy.totalPrice")}
            name="total_price"
            type="number"
            value={input.total_price}
            onChange={handleChangePrescriptionField}
            sx={{ width: 200, backgroundColor: "#fff", borderRadius: 2 }}
            disabled
          />
          <Button
            size="small"
            onClick={handleSave}
            variant="contained"
            disabled={loading}
            sx={{
              fontWeight: 700,
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
          >
            {loading ? t("pharmacy.saving") : t("pharmacy.savePrescription")}
          </Button>
        </Stack>
      </CardContent>

      {/* Prescription List */}
      <Box mt={4}>
        <PrescriptionList />
      </Box>
    </Card>
  );
};

export default AddPrescription;
