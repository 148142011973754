import React, { Fragment, useEffect, useState } from "react";
import NavBar from "./pages/Layout/NavBar/NavBar";
import { Outlet } from "react-router-dom";
import { createTheme, Grid, ThemeProvider, CssBaseline } from "@mui/material";

function App() {
  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    const updateDirection = () => {
      const savedDirection = localStorage.getItem("direction");
      setDirection(savedDirection || "ltr");
    };
    updateDirection();
  }, []);

  const theme = createTheme({
    direction: direction,
    typography: {
      fontFamily:
        direction === "rtl"
          ? "'Bahij Naskh'"
          : "'Noto Sans', sans-serif",
    },
  });

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Fragment>
        <NavBar />
        <Grid container margin={"0px auto"} className="App" maxWidth={"xl"}>
          <Outlet />
        </Grid>
      </Fragment>
    </ThemeProvider>
  );
}

export default App;
