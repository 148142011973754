import { Grid } from "@mui/material";
import React from "react";
import ActivityOverView from "./ActivityOverView/ActivityOverView";
import TestsToBeDone from "./TestsTobeDone/TestsToBeDone";

const UltraSoundIndex = () => {
  return (
    <Grid container maxWidth="xl" mb={3}>
      <Grid container gap={1} mt={2} justifyContent={"center"}>
        <Grid item xs={12} md={12} lg={3.2} bgcolor={"white"}>
          <Grid container>
            <ActivityOverView />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8.5}>
          <TestsToBeDone />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UltraSoundIndex