import { Box, Grid, Paper, Tabs, Tab } from "@mui/material";
import React, { useState, useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PharmacyNav = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("/pharmacy");
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    const path = location.pathname;
    const validPaths = [
      "/pharmacy",
      "/pharmacy/pos",
      "/pharmacy/prescription",
      "/pharmacy/surgery-prescription",
      "/pharmacy/new-medicine",
      "/pharmacy/medicine-list",
      "/pharmacy/medicine-company",
      "/pharmacy/purchase-drug",
      "/pharmacy/return-medicine",
      "/pharmacy/medicine-stock",
    ];

    if (validPaths.includes(path)) {
      setValue(path);
    } else {
      setValue("/pharmacy");
    }
  }, [location.pathname]);

  return (
    <Grid container maxWidth="xl">
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Paper sx={{ padding: "10px 0px", boxShadow: "0px 0px 0px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={{
              ml: "5px",
              "& .MuiTabs-indicator": { display: "none" },
            }}
          >
            {[
              { path: "/pharmacy", label: t("pharmacy.dashboard") },
              { path: "/pharmacy/pos", label: t("pharmacy.pos") },
              {
                path: "/pharmacy/prescription",
                label: t("pharmacy.prescription"),
              },
              {
                path: "/pharmacy/surgery-prescription",
                label: t("pharmacy.surgeryPrescription"),
              },
              {
                path: "/pharmacy/new-medicine",
                label: t("pharmacy.new_medicine"),
              },
              {
                path: "/pharmacy/medicine-list",
                label: t("pharmacy.medicine_list"),
              },
              {
                path: "/pharmacy/medicine-company",
                label: t("pharmacy.medicineCompany"),
              },
              {
                path: "/pharmacy/purchase-drug",
                label: t("pharmacy.purchase_drug"),
              },
              {
                path: "/pharmacy/return-medicine",
                label: t("pharmacy.return_medicine"),
              },
              {
                path: "/pharmacy/medicine-stock",
                label: t("pharmacy.medicine_stock"),
              },
            ].map((tab, index) => (
              <Tab
                key={index}
                style={{
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  backgroundColor: value === tab.path ? "#007174" : "white",
                  color: value === tab.path ? "white" : "black",
                }}
                label={tab.label}
                value={tab.path}
                component={NavLink}
                to={tab.path}
              />
            ))}
          </Tabs>
        </Paper>
      </Box>
      <Outlet />
    </Grid>
  );
};

export default PharmacyNav;
